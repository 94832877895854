import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router,
    private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isUserLoggedIn = this.authenticationService.isUserLoggedIn();
    if (isUserLoggedIn) {
      
      
      // authorised so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    // this.authenticationService.redirectUrl = state.url;
    this.authenticationService.logout(true).subscribe(
      (data) => {
        this.router.navigate(['/login']);
      },
      (error) => {
        console.log(error);
      }
    );
    // this.router.navigate(['/login']);
    return false;
  }
}
