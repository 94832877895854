import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SelectItem } from 'primeng/api';
import { Frequencies } from 'src/app/core/models/enum';
import { ExecuteData } from '../execute-data';
import { ScheduleTest } from '../schedule-test';

@Component({
  selector: 'app-schedule-form',
  templateUrl: '../pages/schedule-form.component.html',
  styleUrls: []
})

export class ScheduleFormComponent implements OnInit {
  
  @Input() isEdit!: boolean;
  @Input() executables! : Array<ExecuteData>;
  @Input() updateTest!: ScheduleTest;
  form!: FormGroup;
  showTime: boolean = true;
  frequencies = Frequencies;
  configPopup: boolean = false;    
  frequencyItems!: SelectItem[];
  @Output() formValues: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPopup : EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() removeSelection : EventEmitter<boolean> = new EventEmitter<boolean>();
  minDate!: Date;
  @Input() resetForm : boolean = false;
  // @Input() configPopup

  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.updateTest){
      this.initFrequencies();    
      this.initForm();  
      setTimeout(() => {
      const formData = changes.updateTest.currentValue;
      if(formData){
        const patchFormValues = {name: formData.name, description: formData.description, frequency: formData.frequency, date: formData.formattedDate}
        this.form.patchValue(patchFormValues);
        this.updateFormValues();
      }
      
      }, 0);
    }

    if(changes.resetForm && changes.resetForm.currentValue){
      this.cancel();
    }
    
  }

  ngOnInit(): void {
    this.initFrequencies();    
    this.initForm();  
  }
  
  private initFrequencies() {
    this.frequencyItems = [];
    Object.keys(this.frequencies).forEach(element => {
      this.frequencyItems.push({ label: element, value: element });
    });
  }

  private initForm() {
    this.minDate = new Date();
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      frequency: [this.frequencies['Don\'t Repeat']],
      date: ['', [Validators.required]]
    });
  }

  get getControl(){
    return this.form.controls;
  }
  
  onSubmit(){
    this.formValues.emit(this.form.value);   
    
  }

  showConfig() {
    this.configPopup = true;
    this.openPopup.emit(this.configPopup);    
  }

  updateFormValues(){
    if(this.isEdit){
      this.formValues.emit(this.form.value);
    }    
  }

  cancel(){
    this.form.reset();
    this.removeSelection.emit(true);
  }

}