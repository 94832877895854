import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ExecuteService } from '../services/execute.service';

@Component({
  selector: 'app-execute-data',
  templateUrl: '../pages/execute-data.component.html',
  styles: [
  ]
})
export class ExecuteDataComponent implements OnInit {

  activeTabIndex!:number;
  @ViewChild('executeHistoryTempRef', { read: ViewContainerRef })
  private executeHistorycontainerref!: ViewContainerRef;

  @ViewChild('executeInprogressTempRef', { read: ViewContainerRef })
  private executeInprogresscontainerref!: ViewContainerRef;
  inProgressCount!: number;
  inProgressHeader!: string;
  @Input() testPlanId!: number;

  constructor(private executeService: ExecuteService, private cfr: ComponentFactoryResolver) { }

  ngOnInit(): void {
    this.inProgressHeader = "Inprogress (0)"
    this.tabChange(0)
  }

  async tabChange(event: any){
    setTimeout(async () => {
      if(event === 1){
        this.activeTabIndex = 1;
        this.executeService.ngOnDestroy();
        if(this.executeInprogresscontainerref){
          this.executeHistorycontainerref.clear();
          const { ExecuteHistoryComponent } = await import('../components/execute-history.component');
          let historyComponent = this.executeHistorycontainerref.createComponent(
            this.cfr.resolveComponentFactory(ExecuteHistoryComponent)
          ); 
          historyComponent.instance.activeIndex = event;
          historyComponent.instance.testPlanId = this.testPlanId
        }
          
              
                
    }else{
      this.activeTabIndex = event;
      this.executeService.ngOnDestroy();
      // if(this.executeInprogresscontainerref){
        this.executeInprogresscontainerref.clear();
        const { ExecuteHistoryComponent } = await import('../components/execute-history.component');
        let historyComponent = this.executeInprogresscontainerref.createComponent(
          this.cfr.resolveComponentFactory(ExecuteHistoryComponent)
        );   
        historyComponent.instance.activeIndex = event;
        historyComponent.instance.inprogressCount.subscribe((res : number) => {
          this.inProgressCount = res;
          this.inProgressHeader = "";
          this.inProgressHeader = "Inprogress ("+this.inProgressCount+")"
        })
        historyComponent.instance.testPlanId = this.testPlanId;
      // }
           
     
    }
    }, 0);
    
} 

}
