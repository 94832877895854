import { Component, OnInit } from '@angular/core'
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms'
import { AdminDetail } from '../classes/admin-detail'
import { AdminService } from '../services/admin.service'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '../core/_helpers/_services/auth.service'
import { UserStorageService } from '../core/_helpers/_services/user-storage.service'
import { JwtHelperService } from '@auth0/angular-jwt'
import { HttpParams, HttpResponse } from '@angular/common/http'
import { AuthenticationService } from '../core/_helpers/_services/authentication.service'
import { MessageService } from 'primeng/api'
import { USER_KEY } from '../constants'
import { ChangePassword } from '../changepassword/changepassword'
import { MasterDataService } from '../core/common/services/master-data.service'
import { UserService } from '../modules/manageuser/services/user.service'

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styles: [],
})
export class ResetPasswordComponent implements OnInit {
  public confirmpassword: string = ''
  public newpassword: string = ''
  isScreenLoading: boolean = false
  public params = new ChangePassword()
  public email: any = '';
  isLinkExpired!: boolean;
  constructor(
    private masterDataService: MasterDataService,
    private messageService: MessageService,
    private router: Router,
    private userService: UserService,
    private userStorageService: AuthenticationService
  ) {}

  ngOnInit(): void {
   
    if(this.userStorageService.isUserLoggedIn()){
      this.userStorageService.setAuthentication(false);
      this.userStorageService.logout(false).subscribe(res => {
       
      })
    }
    this.email = window.location.href.split('password/')[1];
    if( window.location.href.includes("create-password")){
      this.isScreenLoading = true;
      this.userService.checkRegistrationLinkExpiry(this.email).subscribe(res => {
        this.isScreenLoading = false;
          
      const response = res.result;
        if(response){
          this.isLinkExpired = true;
          this.router.navigate(['expired']);
        }else{
          this.isLinkExpired = false;
        }
      })
    }
    
  }

  submitHandler() {
    this.isScreenLoading = true
    if (this.confirmpassword !== this.newpassword) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Password doesn't match",
        life: 3000,
      })
      this.isScreenLoading = false
      return
    }
    this.params.confirmPassword = this.confirmpassword
    this.params.email = this.email
    this.params.password = this.newpassword
    this.masterDataService.createPassword(this.params).subscribe((res: any) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: `Password successfully ${window.location.href.includes('create') ? 'created' : 'reset'}, please login to continue`,
        life: 3000,
      })
      this.email = ''
      this.newpassword = ''
      this.confirmpassword = ''
      this.router.navigate(['/login'])
      this.isScreenLoading = false
    })
  }
}
