import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IResponse, IResponsePaginated, IResponsePaginatedTests } from 'src/app/core/generic-operations/operations/responce.interface';
import { environment } from '../../../../environments/environment';
import { CrudService } from '../../../core/generic-operations/services/crud-service';
import { Settings } from '../../execute/schedule-test';


@Injectable({
    providedIn: 'root'
})
export class SettingsService extends CrudService<Settings, number> {


    constructor(protected http: HttpClient) {
        super(http, `${environment.api.baseUrl}/`, 'cicd', 'cicd');
    }

    createSchedule(data: Settings) {
        return this._http.post<IResponse>(`${environment.api.baseUrl}/cicd/create`, data);
    }

    updateService(id: number, data: Settings) {
        return this._http.put<IResponse>(`${environment.api.baseUrl}/cicd/${id}`, data);
    }

    deleteSchedule(id: number) {
        return this._http.delete<IResponse>(`${environment.api.baseUrl}/cicd/${id}`);
    }

    findSettingsTestsByApplicationId(settings: Settings){
        return this._http.post<IResponsePaginatedTests>(`${environment.api.baseUrl}/cicd/jobs`,settings).pipe(
          map((o:IResponsePaginatedTests): IResponsePaginatedTests => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }  


      

      createCicdJob(data: Settings) {
        return this._http.post<IResponse>(`${environment.api.baseUrl}/cicd/create-job`, data);
    }


    // MFA Credentials

    saveCredentials(data: any) {
        return this._http.post<IResponse>(`${environment.api.baseUrl}/mfa`, data);
    }

    updateCredentials(data: any, authId: number) {
        return this._http.put<IResponse>(`${environment.api.baseUrl}/mfa/${authId}`, data);
    }

    generateQRCode(data: any) {
        return this._http.post<IResponse>(`${environment.api.baseUrl}/auth/qr-code`, data);
    }

    getMFAbyApplicationId(id: number) {
        return this._http.get<IResponse>(`${environment.api.baseUrl}/mfa/application/${id}`);
    }

    deleteMFA(id: number) {
        return this._http.delete<IResponse>(`${environment.api.baseUrl}/mfa/${id}`);
    }

}