import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  MessageService,
  ConfirmationService,
  LazyLoadEvent,
  PrimeNGConfig,
  FilterMatchMode,
  FilterMetadata,
} from 'primeng/api';
import { Router } from '@angular/router';
import { UnsubscribeOnDestroyAdapter } from 'src/app/core/unsubscribe-on-destroy-adapter';
import { ProjectService } from 'src/app/modules/projects/services/projects.service';
import { Table } from 'primeng/table';
import { MasterDetailCommands } from 'src/app/core/master-detail-command';
import {
  PAGINATION_SIZE_TEN,
  START_INDEX,
  USER_SELECTED_APPLICATION,
} from 'src/app/constants';
import { UserStorageService } from 'src/app/core/_helpers/_services/user-storage.service';
import { GTPVariables } from '../gtp-variables';
import { User } from 'src/app/modules/manageuser/user';
import { Project } from 'src/app/modules/projects/project';
import { VariablesService } from '../services/variables.service';
import { Tags } from 'src/app/modules/tests/scripts/tags';
import { ScriptService } from 'src/app/modules/tests/scripts/services/script.service';
import { MasterDataService } from 'src/app/core/common/services/master-data.service';

@Component({
  selector: 'app-variables-listing',
  templateUrl: '../pages/variables-listing.component.html',
  styles: [],
})
export class VariablesListingComponent
  extends UnsubscribeOnDestroyAdapter
  implements MasterDetailCommands<GTPVariables>, OnInit, OnChanges
{
  totalRecords: number = 0;

  displayModal!: boolean;

  scriptsDialog!: boolean;

  allVariables: GTPVariables[] = new Array<GTPVariables>();
  applicationVariables: GTPVariables[] = new Array<GTPVariables>();

  script: GTPVariables = new GTPVariables();

  selectedScripts: GTPVariables[] = [];

  submitted: boolean = false;

  searchKey!: string;

  scriptTags!: string[];

  loggedInUser!: User;

  user: User = new User();
  tags: Tags[] = new Array<Tags>();

  application: Project = new Project();

  isScreenLoading: boolean = false;

  isGridLoading: boolean = false;

  updateScript: GTPVariables = new GTPVariables();
  paginationSize = PAGINATION_SIZE_TEN;
  @Input() scriptId!: number;
  @Input() variablesFromScript: GTPVariables[] = [];
  @Input() apiVariable: string = '';
  @Input() apiVariableName: string = '';
  @Input() apiVariableID: number = 0;
  @Output() outputVariableData: EventEmitter<any> = new EventEmitter<any>();
  public heading: string = 'Add';
  public selectedVariable: GTPVariables = new GTPVariables();
  constructor(
    private variablesService: VariablesService,
    private messageService: MessageService,
    private userStorageService: UserStorageService,
    private confirmationService: ConfirmationService,
    private masterDataService: MasterDataService,
    private config: PrimeNGConfig
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      console.log(changes);

      if (changes.scriptId) {
        this.scriptId = changes.scriptId.currentValue;
        this.allVariables = this.variablesFromScript;
      }
    }
  }

  ngOnInit(): void {
    this.getUser();
    console.log('On ngOnInit');
    console.log(this.apiVariable);
    this.selectedVariable.variableValue = this.apiVariable;
    console.log(this.apiVariableName);

    if (this.apiVariableName) {
      this.selectedVariable.variableName = this.apiVariableName;
    }
    this.selectedVariable.variableScope = 'Global';
    this.selectedVariable.applicationVariableId = this.apiVariableID;
    console.log(this.scriptId);
    this.getAllVariables(this.getProject().applicationId);
   
    this.heading = 'Add';
  }

  showScriptDialog() {
    this.displayModal = true;
    this.heading = 'Add';
  }

  openNew() {
    this.submitted = false;
    this.scriptsDialog = true;
  }

  editScript(script: GTPVariables) {
    //this.updateScript = { ...script };
    console.log(script);

    this.displayModal = true;
    this.heading = '';
    this.heading = 'Edit';
    this.selectedVariable = { ...script };
  }

  deleteScript(script: GTPVariables) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + script.variableName + '?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.delete(script);
      },
    });
  }

  hideDialog() {
    this.scriptsDialog = false;
    this.submitted = false;
  }

  save() {}

  getAll(applicationId?: number) {
    this.getProjectScripts(applicationId!);
  }

  getProjectScripts(id: number) {
    this.isScreenLoading = true;
    this.loadPaginated(id, this.paginationSize, START_INDEX);
  }

  getUser() {
    let user: any = this.userStorageService.getUser();
    this.loggedInUser = user.user;
  }

  getProject(): Project {
    return (<any>(
      JSON.parse(window.localStorage.getItem(USER_SELECTED_APPLICATION) || '{}')
    )) as Project;
  }

  delete(script: GTPVariables) {
    this.isGridLoading = true;

    this.subs.sink = this.variablesService
      .delete(script.applicationVariableId)
      .subscribe((res: any) => {
        this.allVariables = this.allVariables.filter(
          (val) => val.applicationVariableId !== script.applicationVariableId
        );

        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: res.message,
          life: 3000,
        });
        this.isGridLoading = false;
      });
  }

  close(event: any) {
    this.displayModal = event;
  }

  select(script: GTPVariables) {}

  update() {
    this.isGridLoading = true;
  }

  nextPage(event: LazyLoadEvent) {
    const first = event.first;

    let page = 0;
    if (first == 0) {
      page = 0;
    } else {
      page = <number>first / <number>event.rows;
    }
    const id = this.getProject().applicationId;

    if (Object.keys(<any>event.filters).length > 0) {
      const rowsPerPage = <number>event.rows;
      const scriptName: FilterMetadata = <any>event.filters?.scriptName;
      if (scriptName instanceof Array) {
        if (scriptName[0].value != null) {
          this.filteredResults(id, scriptName[0].value);
        } else {
          this.loadPaginated(id, rowsPerPage, page);
        }
      } else {
        this.loadPaginated(id, rowsPerPage, page);
      }
    }
  }

  private filteredResults(id: number, value: string) {
    this.subs.sink = this.variablesService.findAll().subscribe((res: any) => {
      console.log(res);
      this.isScreenLoading = false;
    });
  }

  private getAllVariables(appId: number) {
    this.subs.sink = this.variablesService
      .findByApplicationId(appId)
      .subscribe((res: any) => {
        this.allVariables = res.result;
        this.isScreenLoading = false;
        this.masterDataService.setApplicationVariables(res.result);
      });
  }

  private loadPaginated(id: number, rowsPerPage: number, page: number) {}

  clearTable(table: Table) {
    table.clear();
  }

  updateVariable(event: GTPVariables) {
    console.log(event);

    let variableScope: string = '';
    if (this.scriptId) {
      variableScope = 'Local';
    } else {
      variableScope = 'Global';
    }
    this.isScreenLoading = true;
    this.displayModal = false;
    const { email, lastName, name, userId, value, description } =
      this.userStorageService.getUser().user;
    const params: GTPVariables = {
      applicationId:
        this.userStorageService.getUserSelectedProject().applicationId,
      applicationVariableId: 0,
      userEmail: email,
      userLastName: lastName,
      userName: name,
      variableName: event.variableName,
      variableScope: this.selectedVariable.variableScope
        ? this.selectedVariable.variableScope
        : variableScope,
      modifiedBy: userId,
      createdBy: userId,
      variableValue: event.variableValue,
      variableDescription: event.variableDescription,
      scriptId: event.scriptId,
      position: 0,
      variableCurrentValue: event.variableValue,
      startIndex: 0,
      rowsPerPage: 0,
      variablePath: '',
      dataType: ''
    };
    console.log(params);
    if (event.applicationVariableId) {
      this.subs.sink = this.variablesService
        .update(event.applicationVariableId, params)
        .subscribe(
          (res: any) => {
            console.log(res);
            // this.scripts.unshift(res.result);
            this.isScreenLoading = false;
            this.allVariables[this.findIndexById(event.applicationVariableId)] =
              res.result;
            this.masterDataService.getApplicationVariables()[
              this.findIndexById(event.applicationVariableId)
            ] = res.result;
            this.outputVariableData.emit(res.result.variableName);
            this.messageService.add({
              severity: 'success',
              summary: 'Successful',
              detail: res.message,
              life: 3000,
            });
          },
          (error) => {
            this.isScreenLoading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail: error.error.apierror.debugMessage,
              life: 3000,
            });
          }
        );
    } else {
      this.subs.sink = this.variablesService.save(params).subscribe(
        (res: any) => {
          this.allVariables.unshift(res.result);
          this.isScreenLoading = false;
          this.masterDataService.getApplicationVariables().unshift(res.result);
          this.variablesService.setRefreshedVariables(this.allVariables);
          this.outputVariableData.emit(res.result.variableName);
          this.messageService.add({
            severity: 'success',
            summary: 'Successful',
            detail: res.message,
            life: 3000,
          });
        },
        (error) => {
          this.isScreenLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'error',
            detail: error.error.apierror.debugMessage,
            life: 3000,
          });
        }
      );
    }
  }

  findIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.allVariables.length; i++) {
      if (this.allVariables[i].applicationVariableId === id) {
        index = i;
        break;
      }
    }
    return index;
  }
}
