<div class="flex justify-between p-2 pb-0">
    <h3>Dashboard</h3>
    <div class="mb-0 pt-0 ml-auto dashboard-calender-container">
        <p-calendar [(ngModel)]="date" [showIcon]="true" inputId="icon" [showButtonBar]="true" inputId="buttonbar"
            panelStyleClass="p-overwrite mt-1"></p-calendar>
    </div>
</div>

<div class="p-col-12 dashboard" id="dashboard">

    <!-- <div class="p-grid">
            <div class="p-col-4">
                <div class="box">
                    <app-download-extension></app-download-extension>
                </div>
            </div>
            <div class="p-col-4 p-offset-4">
                <div class="box">
                    <app-video-component></app-video-component>
                </div>
            </div>
        </div> -->

    <div class="p-grid p-mb-4 p-mt-4">
        <div class="p-col-12 p-md-6 p-lg-4 bx-1 pr-12">
            <div class="p-card dshbrd-first-row" style="cursor: pointer" [routerLink]="['/tests/listing']"
                routerLinkActive="router-link-active">
                <div class="p-grid pl-8 pt-5 p-0 h-24">
                    <div class="p-col-2 dashboard-bx-icons">
                        <!-- <span class="p-menuitem-icon pi pi-fw pi pi-globe"></span> -->
                        <img src="../../assets/icons/svg-icons/browser.svg" class="h-6 w-6" alt="" />
                    </div>
                    <div class="p-col-7">
                        <div class="p-grid">
                            <div class="p-col-12 rslt-lbls ml-4">
                                <!-- <ng-container *ngIf="showBrowerTestLoading; else elseTemplate">
                              <p-skeleton width="21rem" height="2rem" styleClass="p-mb-2 p-mt-3 p-mr-4 p-f-right" borderRadius="16px"></p-skeleton>      
                                      </ng-container>
                                      <ng-template #elseTemplate> -->
                                <h5 class="dashboard-card-fonts mb-0">Total Browser Tests</h5>
                                <app-total-script [message]="noProjectMsg"
                                    [count]="totalScripts?.browserTests"></app-total-script>
                                <!-- </ng-template> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-4 bx-2 pr-6">
            <div class="p-card dshbrd-first-row" style="cursor: pointer" [routerLink]="['/tests/listing?link=api']"
                routerLinkActive="router-link-active">
                <div class="p-grid pl-8 pt-5 p-0 h-24">
                    <div class="p-col-2 dashboard-bx-icons">
                        <img src="../../assets/icons/svg-icons/api.svg" class="h-6 w-6" alt="" />
                    </div>
                    <div class="p-col-7">
                        <div class="p-grid">
                            <div class="p-col-12 rslt-lbls ml-4">
                                <h5 class="dashboard-card-fonts mb-0">Total API Tests</h5>
                                <app-total-script [message]="noProjectMsg"
                                    [count]="totalScripts?.apiTests"></app-total-script>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-4 pl-6">
            <div class="p-card dshbrd-first-row" style="cursor: pointer" [routerLink]="['/plans']">
                <div class="p-grid pl-8 pt-5 p-0 h-24">
                    <div class="p-col-2 dashboard-bx-icons">
                        <img src="../../assets/icons/svg-icons/celandar.svg" class="h-6 w-6" alt="" />
                    </div>
                    <div class="p-col-9">
                        <div class="p-grid">
                            <div class="p-col-12 rslt-lbls ml-4">
                                <h5 class="dashboard-card-fonts mb-0">Total Test Plans</h5>
                                <app-total-test-plans [message]="noProjectMsg"
                                    [count]="totalScripts?.testPlans"></app-total-test-plans>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12">
            <p-tabView>
                <p-tabPanel header="Executions">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="justify-right px-5">
                                <p-selectButton [options]="stateOptions" [(ngModel)]="value1" optionLabel="label"
                                    optionValue="value"
                                    (ngModelChange)="filterEmitter(value1, executionType.EXECUTENOW)"></p-selectButton>
                            </div>
                            <div class="p-grid p-mt-3" *ngIf="scheduleServerSideData">
                                <div class="p-col-12">
                                    <app-serverside-testtable
                                        (paginationEmitter)="paginationEmitter($event, executionType.EXECUTENOW)"
                                        [data]="scheduleServerSideData"
                                        (filter)="filterEmitter($event, executionType.EXECUTENOW)">
                                    </app-serverside-testtable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-grid progress-btms p-mt-3" *ngIf="scheduleServerSideData">
                        <div class="p-col-12">
                            <hr>
                        </div>
                        <div class="p-col-4 test">
                            <label class="p-mb-2"> Total Server Side Executions
                                <strong>({{scheduleServerSideData.tests.total}})</strong></label>
                            <p-progressBar [showValue]="false"
                                [value]="percentageValueHandler(scheduleServerSideData, 'total')"
                                [style]="{'height': '10px'}"></p-progressBar>
                        </div>
                        <div class="p-col-4 pass">
                            <label class="p-mb-2"> Pass</label>
                            <p-progressBar [value]="percentageValueHandler(scheduleServerSideData, 'pass')"
                                [style]="{'height': '10px'}"></p-progressBar>
                        </div>
                        <div class="p-col-4 fail">
                            <label class="p-mb-2"> Fail</label>
                            <p-progressBar [value]="percentageValueHandler(scheduleServerSideData, 'fail')"
                                [style]="{'height': '10px'}"></p-progressBar>
                            <!--<p-progressBar mode="determinate" [value]="50"[style]="{'height': '10px'}"></p-progressBar>-->
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Scheduled Executions">
                    <div class="p-grid">
                        <div class="p-col-12">

                            <div class="justify-right">
                                <p-selectButton [options]="stateOptions" [(ngModel)]="value1" optionLabel="label"
                                    optionValue="value"
                                    (ngModelChange)="filterEmitter(value1, executionType.SCHEDULED)"></p-selectButton>
                            </div>
                            <!--<p-selectbutton multiple="multiple" optionlabel="name" optionvalue="value" class="p-element ng-valid ng-dirty ng-touched">
                            <div role="group" class="p-selectbutton p-buttonset p-component">
                                <div role="button" pripple="" class="p-ripple p-element p-button p-component ng-star-inserted" tabindex="0" aria-labelledby="Option 1">
                                    <span class="p-button-label ng-star-inserted"> Weekly </span>
                                </div>
                                <div role="button" pripple="" class="p-ripple p-element p-button p-component ng-star-inserted" tabindex="0" aria-labelledby="Option 2">
                                    <span class="p-button-label ng-star-inserted"> Monthly </span>
                                </div>
                                <div role="button" pripple="" class="p-ripple p-element p-button p-component ng-star-inserted" tabindex="0" aria-labelledby="Option 3">
                                    <span class="p-button-label ng-star-inserted"> Quarterly </span>
                                </div>
                            </div>
                        </p-selectbutton>-->

                            <!--<p-selectbutton optionlabel="label" optionvalue="value" class="p-element ng-valid ng-dirty ng-touched">
                            <div role="group" class="p-selectbutton p-buttonset p-component">
                            <div role="button" pripple="" class="p-ripple p-element p-button p-component ng-star-inserted" aria-pressed="false" aria-label="Off" tabindex="0" aria-labelledby="Off">
                                <span class="p-button-label ng-star-inserted">Off</span>
                                <span class="p-ink" style="height: 57px; width: 57px; top: -7.75px; left: 15.5px;"></span></div>
                                <div role="button" pripple="" class="p-ripple p-element p-button p-component ng-star-inserted p-highlight" aria-pressed="true" aria-label="On" tabindex="0" aria-labelledby="On">
                                    <span class="p-button-label ng-star-inserted">On</span>
                                    <span class="p-ink" style="height: 56px; width: 56px; top: 4.75px; left: -13.15px;"></span>
                                </div></div>
                            </p-selectbutton>-->

                            <div class="p-grid p-mt-3 inline-spinner" *ngIf="scheduleTestData">
                                <div class="p-col-12">
                                    <app-schedule-testtable
                                        (paginationEmitter)="paginationEmitter($event, executionType.SCHEDULED)"
                                        [data]="scheduleTestData"
                                        (filter)="filterEmitter($event, executionType.SCHEDULED)">
                                    </app-schedule-testtable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid progress-btms p-mt-3" *ngIf="scheduleTestData">
                        <div class="p-col-12">
                            <hr>
                        </div>
                        <div class="p-col-4 test">
                            <label class="p-mb-2"> Total Schedule Data Executions
                                <strong>({{scheduleTestData.tests.total}})</strong></label>
                            <p-progressBar [showValue]="false"
                                [value]="percentageValueHandler(scheduleTestData, 'total')"
                                [style]="{'height': '10px'}"></p-progressBar>
                        </div>
                        <div class="p-col-4 pass">
                            <label class="p-mb-2"> Pass</label>
                            <p-progressBar [value]="percentageValueHandler(scheduleTestData, 'pass')"
                                [style]="{'height': '10px'}"></p-progressBar>
                        </div>
                        <div class="p-col-4 fail">
                            <label class="p-mb-2"> Fail</label>
                            <p-progressBar [value]="percentageValueHandler(scheduleTestData, 'fail')"
                                [style]="{'height': '10px'}"></p-progressBar>
                            <!--<p-progressBar mode="determinate" [value]="50"[style]="{'height': '10px'}"></p-progressBar>-->
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Devops Pipeline Executions">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="justify-right">
                                <p-selectButton [options]="stateOptions" [(ngModel)]="value1" optionLabel="label"
                                    optionValue="value" (ngModelChange)="filterEmitter(value1, executionType.CICD)">
                                </p-selectButton>
                            </div>
                            <div class="p-grid p-mt-3" *ngIf="scheduleDevOpsData">
                                <div class="p-col-12">
                                    <app-pipeline-testtable
                                        (paginationEmitter)="paginationEmitter($event, executionType.CICD)"
                                        [data]="scheduleDevOpsData"
                                        (filter)="filterEmitter($event, executionType.CICD)"></app-pipeline-testtable>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="p-grid progress-btms p-mt-3" *ngIf="scheduleDevOpsData">
                        <div class="p-col-12">
                            <hr>
                        </div>
                        <div class="p-col-4 test">
                            <label class="p-mb-2"> Total DevOps Pipeline Executions
                                <strong>({{scheduleDevOpsData.tests.total}})</strong></label>
                            <p-progressBar [showValue]="false"
                                [value]="percentageValueHandler(scheduleDevOpsData, 'total')"
                                [style]="{'height': '10px'}"></p-progressBar>
                        </div>
                        <div class="p-col-4 pass">
                            <label class="p-mb-2"> Pass</label>
                            <p-progressBar [value]="percentageValueHandler(scheduleDevOpsData, 'pass')"
                                [style]="{'height': '10px'}"></p-progressBar>
                        </div>
                        <div class="p-col-4 fail">
                            <label class="p-mb-2"> Fail</label>
                            <p-progressBar [value]="percentageValueHandler(scheduleDevOpsData, 'fail')"
                                [style]="{'height': '10px'}"></p-progressBar>
                            <!--<p-progressBar mode="determinate" [value]="50"[style]="{'height': '10px'}"></p-progressBar>-->
                        </div>
                    </div>

                </p-tabPanel>

            </p-tabView>




        </div>

    </div>



</div>