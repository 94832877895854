import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Role } from 'src/app/modules/manageuser/role';
import { AuthenticationService } from './authentication.service';
import { UserStorageService } from './user-storage.service';

@Injectable({
    providedIn: "root"
})
export class RoleGuardService implements CanActivate {
    constructor(private router: Router,
        private authenticationService: AuthenticationService, private userStorage: UserStorageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log("Role Guard");
        
        const isUserLoggedIn = this.authenticationService.isUserLoggedIn();
        const allowedRole = this.userStorage.getRole().roleId;
        if (isUserLoggedIn && (allowedRole === 2 || allowedRole === 1)) {
            // authorised so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.authenticationService.redirectUrl = state.url;
        this.router.navigate(['/login']);
        return false;
    }
}
