<div id="execute-data">
  <p-card>
      <p-tabView (activeIndexChange)="tabChange($event)" [(activeIndex)]="activeTabIndex">
          <p-tabPanel [header]="inProgressHeader">           
            
            <ng-template #executeInprogressTempRef></ng-template>            
            <!-- <app-execute-history [runHistories]="runHistories" [totalRecords]="totalRecords"></app-execute-history>          -->
          </p-tabPanel>
          <p-tabPanel header="Execute History">
            <ng-template #executeHistoryTempRef></ng-template>            <!-- <app-execute-history [runHistories]="runHistories" [totalRecords]="totalRecords"></app-execute-history> -->
          </p-tabPanel>
      </p-tabView>
  </p-card>
  
</div>