
<!-- <p-toast></p-toast> -->
<a onclick="staticlayout()" id="menu-button" class=""><i class="pi pi-angle-left"></i></a>
<div class="topbar-left">			
    <a routerlink="/" href="#/" class="logo-header">
        <img src="assets/gtp-icon.svg" alt="GoTestPro" />
        <img src="assets/rc-teaf-logo-w.svg" alt="GoTestPro" />
    </a>			
</div>

<p-menu [model]="items" class="layout-menu layout-main-menu clearfix"></p-menu>
<p-dialog [(visible)]="displayModal" [style]="{width: '450px'}" header="Select Test Type" [modal]="true" styleClass="p-fluid">
	<ng-template pTemplate="content">			
		<div class="p-d-flex p-text-center">
			<a href="javascript:;" class="testType" [routerLink]="'/tests/listing'" (click)="displayModal = false">Webapp
				<div>End-to-end UI validation across browsers</div>
			</a>
			<a class="testType" [routerLink]="'/apis/listing'" (click)="displayModal = false">Api
				<div>End-to-end API validation outside the browser</div>
			</a>
		</div>
	</ng-template>		
	<ng-template pTemplate="footer">
		<!-- <button pButton pRipple label="Cancel" icon="pi pi-times" class="cancel-btn" (click)="hideDialog()"></button> -->
		<!-- <button pButton pRipple label="Ok" icon="pi pi-check"></button> -->
	</ng-template>
</p-dialog>