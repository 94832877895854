import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IResponse } from "src/app/core/generic-operations/operations/responce.interface";
import { CrudService } from "src/app/core/generic-operations/services/crud-service";
import { environment } from "src/environments/environment";

import { Step } from "../step";

@Injectable({
  providedIn: "root",
})
export class StepService extends CrudService<Step, number> {
  constructor(protected http: HttpClient) {
    super(http, `${environment.api.baseUrl}/`, "step", "steps");
  }

  deleteScenarioStep(id: number, scenarioId: number) {
    return this._http
      .delete<IResponse>(
        `${environment.api.baseUrl}/` + "step/" + scenarioId + "/" + id
      )
      .pipe(
        map(
          (o: IResponse): IResponse => ({
            code: o.code,
            message: o.message,
            result: o.result,
          })
        )
      );
  }

  saveStep(
    scenarioId: number,
    t: Array<Step>,
    type: string
  ): Observable<IResponse> {
    return this._http
      .post<IResponse>(
        `${environment.api.baseUrl}/` +
          "step/scenario/" +
          scenarioId +
          "?action=" +
          type,
        t
      )
      .pipe(
        map(
          (o: IResponse): IResponse => ({
            code: o.code,
            message: o.message,
            result: o.result,
          })
        )
      );
  }

  getConditions(): Observable<IResponse> {
    return this._http
      .get<IResponse>(`${environment.api.baseUrl}/conditions`)
      .pipe(
        map(
          (o: IResponse): IResponse => ({
            code: o.code,
            message: o.message,
            result: o.result,
          })
        )
      );
  }
}
