import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ManageUser } from "src/app/modules/manageuser/manage-user";
import { Organization } from "src/app/modules/organizations/organization";
import { ProjectService } from "src/app/modules/projects/services/projects.service";
import { environment } from "src/environments/environment";
import { RequestCacheService } from "./request-cache.service";
import { UserStorageService } from "./user-storage.service";
import { WebSocketService } from "./web-socket.service";
import { LoginSessionHistoryService } from "../../common/services/login-session-history.service";

@Injectable({
    providedIn: "root"
})
export class AuthenticationService {
  private currentError$: BehaviorSubject<string> = new BehaviorSubject("");
 
  USER_NAME = 'user';
  TOKEN = 'token';
  redirectUrl!: string;
  public authenticationResult = new Subject();

  constructor(private router: Router, private httpClient: HttpClient, 
    private userStorageService: UserStorageService,
     private cache: RequestCacheService, private projectService: ProjectService,
     private socketService: WebSocketService, private loginSessionHistory: LoginSessionHistoryService) {
    
   }

  // Store JWT token in session once authentication is successful
  authenticate(email : string, password : string) {
    const body = new HttpParams()
      .set('username', email)
      .set('password', password);
    console.log(`${environment.api.baseUrl}` + '/token');
    
     return this.httpClient
      .post<any>(`${environment.api.baseUrl}` + '/token', {email: email, password: password})
      .pipe(map((data) => {
        this.registerData(data.access_token, email);
      }));
  }

  logout(navigate: boolean): Observable<any> {
    return this.httpClient.post(`${environment.api.baseUrl}/logout`, {}).pipe(
      tap(() => {
        console.log("Logout Successfully");
        // Optionally clear other stored user information if necessary
        sessionStorage.clear();
        this.clearContext(navigate)
        // window.location.reload();
      }),
      catchError((error: any) => {
        console.error("Logout Failed", error);
        return of(error); // or throw an error if you want to handle it differently
      })
    );
  }

  // Store JWT token in session once authentication is successful
  userDetails(email : string, password : string) {
    const payload = {email: email, password: password};
     return this.httpClient
      .post<any>(`${environment.api.baseUrl}` + '/user/details/', payload)
      
  }

  registerData(token: string, uName: string) {
    window.localStorage.setItem(this.USER_NAME, uName);
    window.localStorage.setItem(this.TOKEN, token);
  }

  getToken(): string {
    return window.localStorage['token'];
  }

  clearContext(navigate : boolean) {
    if(!this.isEmptyObject(this.userStorageService.getUser())){
      console.log(this.userStorageService.getUser());
      
      
      this.projectService.deSelectAll(this.userStorageService.getUser().user.userId, this.userStorageService.getSUAPPID(), this.userStorageService.getUserSelectedProject().applicationId, this.userStorageService.getUserSelectedProject()).subscribe(res => {
        console.log("Deselected");      
      });
    }
    this.loginSessionHistory.clearSessionData(this.userStorageService.getUserSelectedProject().applicationId+"");
    window.localStorage.removeItem(this.USER_NAME);
    window.localStorage.removeItem(this.TOKEN);
    window.localStorage.clear();
    this.setAuthentication(false);
    console.log(navigate);
    if(navigate){
      this.router.navigate(['/login']);
    }
    this.cache.clear();
    this.socketService.disconnect();

  }

  isUserLoggedIn(): boolean {
    let token = window.localStorage.getItem(this.TOKEN)
    if (token === null) return false
    return true
  }

  getLoggedInUserName() {
    let user = window.localStorage.getItem(this.USER_NAME)
    if (user === null) return ''
    return user
  }

  setAuthentication(value: boolean) {
    this.authenticationResult.next(value);
  }

  public hasRole(val : string) : boolean {
    return this.isUserLoggedIn() && this.userStorageService.getRole().roleName === val;
  }

  getCurrentError(): Observable<string> {
    return this.currentError$.asObservable();
  }

  setCurrentError(error: string) {
      this.currentError$.next(error);
  }

  isEmptyObject(obj : any) {
    return (obj && (Object.keys(obj).length === 0));
  }

  isFreeTrailOrganizaiton() : boolean {
    let isFree: boolean = false;
    const loggedInUser: ManageUser = this.userStorageService.getUser();
    if(loggedInUser.organization.subscriptionEndDate != null){
      console.log(this.calculateDiff(new Date(loggedInUser.organization.subscriptionEndDate)));
    }
    
    
    if(loggedInUser.organization.trialEndDate != null && loggedInUser.organization.subscriptionEndDate === null){
      isFree = true;
    }else if( loggedInUser.organization.subscriptionEndDate !== null && this.calculateDiff(new Date(loggedInUser.organization.subscriptionEndDate || new Date())) < 1){
      isFree = true;
    }
    return isFree;
  }

  calculateDiff(dateSent : Date){
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    return Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) ) /(1000 * 60 * 60 * 24));
}
}
