import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/core/generic-operations/operations/responce.interface';
import { environment } from 'src/environments/environment';
import { DataDriven } from '../dataDriven';
import { Scenario } from '../scenario';

@Injectable({ providedIn: 'root' })
export class DataDrivenScenarioService {


  constructor(private _http: HttpClient) {

  }


  getDataDrivenScenariosDetails(scenarioIds: Array<Scenario>): Observable<IResponse> {
    return this._http.post<IResponse>(`${environment.api.baseUrl}/` + 'data-driven-scenarios/', scenarioIds).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  postDataDrivenScenarioFile(applicationId: number, scenarioId: number, userId: number, file: any): Observable<IResponse> {
    return this._http.post<IResponse>(`${environment.api.baseUrl}/data-driven-scenario/import/excel/${applicationId}/${scenarioId}/${userId}`, file).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  updateDataDrivenScenario(dataDrivenScenarioId: number, data: DataDriven): Observable<IResponse> {
    return this._http.put<IResponse>(`${environment.api.baseUrl}/data-driven-scenario/${dataDrivenScenarioId}`, data).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  getDataDrivenScenario(scenarioId: number): Observable<IResponse> {
    return this._http.get<IResponse>(`${environment.api.baseUrl}/data-driven-scenario/scenario/${scenarioId}`).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }


}