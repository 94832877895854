import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { START_INDEX, USER_SELECTED_APPLICATION } from '../constants';
import { ExecutionTypes } from '../core/models/enum';
import { UserStorageService } from '../core/_helpers/_services/user-storage.service';
import { Project } from '../modules/projects/project';
import { ProjectService } from '../modules/projects/services/projects.service';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit {
  date : Date = new Date();
  public commonScenarios: number = 0
  public totalScripts: any = null
  public totalExecutions: number = 0
  public scheduleTestData: any = null
  public scheduleDevOpsData: any = null
  public scheduleServerSideData: any = null
  public executionType = ExecutionTypes
  public paginationSize: number = 5
  public noProjectMsg!: string;
  showBrowerTestLoading!: boolean;
  currentProject!: number;
  constructor(public dashboardService: DashboardService,
    private userStorageService: UserStorageService,
    private projectService: ProjectService) {
      this.currentProject = userStorageService.getUserSelectedProject().applicationId;
      console.log("Current Project"+ this.currentProject);
  }
  stateOptions!: any[];
  value1: string = ''
  ngOnInit(): void {
    let self = this;
    this.projectService.getCurrentProject().subscribe({
      next(project) {
        if (!project) {
          self.noProjectMsg = "Select Project to view data!";
          return;
        }
        self.noProjectMsg = "";
        self.getCountData(project.applicationId)
        self.getTablesData(project.applicationId)
        self.currentProject = project.applicationId;
        // self.currentProject = project.applicationId;
      },
      error(msg) {
        console.log("Error Getting Project: ", msg);
      },
    });
    this.stateOptions = [
      { label: 'Weekly', value: '7-Days' },
      { label: 'Monthly', value: '4-Weeks' },
      { label: 'Quarterly', value: '3-Months' },
    ];
    this.value1 = this.stateOptions[0].value;
  }

  getCountData(appId: number): void {
    // this.showBrowerTestLoading = true;
    let orgId: number = this.userStorageService.getUser().organization.organizationId
    const userId = this.userStorageService.getUser().user.userId
    forkJoin([
      this.dashboardService.getCommonScenarios(orgId, userId, appId),
      this.dashboardService.getTotalScripts(orgId, userId, appId),
      this.dashboardService.getTotalExecutions(orgId, userId, appId),
    ]).subscribe((res: any) => {
      // this.showBrowerTestLoading = false;
      this.commonScenarios = res[0].result
      this.totalScripts = res[1].result
      this.totalExecutions = res[2].result
    })
  }

  public isScreenLoading: boolean = false
  getTablesData(appId: number): void {
    this.isScreenLoading = true
    let orgId: number = this.userStorageService.getUser().organization.organizationId
    const { user: { email } } = this.userStorageService.getUser()
    forkJoin([
      this.dashboardService.findAllPaginated(orgId, ExecutionTypes.SCHEDULED, "7-Days", email, this.paginationSize, START_INDEX, appId),
      this.dashboardService.findAllPaginated(orgId, "CI-CD", "7-Days", email, this.paginationSize, START_INDEX, appId),
      this.dashboardService.findAllPaginated(orgId, ExecutionTypes.EXECUTENOW, "7-Days", email, this.paginationSize, START_INDEX, appId),
    ]).subscribe((res: any) => {
      this.scheduleTestData = res[0].result
      this.scheduleDevOpsData = res[1].result
      this.scheduleServerSideData = res[2].result
      this.isScreenLoading = false
    })
  }

  public filterEmitter(event?: any, type?: string) {
    this.isScreenLoading = true
    const { user: { email } } = this.userStorageService.getUser()
    const userEmail = this.userStorageService.getUser().user.userId
    let orgId: number = this.userStorageService.getUser().organization.organizationId
    if (type === ExecutionTypes.SCHEDULED) {
      this.dashboardService.findAllPaginated(orgId, ExecutionTypes.SCHEDULED, event, email, this.paginationSize, START_INDEX, this.currentProject).subscribe((res: any) => {
        this.scheduleTestData = res.result
        this.isScreenLoading = false
      })
    } else if (type === ExecutionTypes.CICD) {
      this.dashboardService.findAllPaginated(orgId, 'CI-CD', event, email, this.paginationSize, START_INDEX, this.currentProject).subscribe((res: any) => {
        this.scheduleDevOpsData = res.result
        this.isScreenLoading = false
      })
    } else if (type === ExecutionTypes.EXECUTENOW) {
      this.dashboardService.findAllPaginated(orgId, ExecutionTypes.EXECUTENOW, event, email, this.paginationSize, START_INDEX, this.currentProject).subscribe((res: any) => {
        this.scheduleServerSideData = res.result
        this.isScreenLoading = false
      })
    }
  }

  getProject(): Project {
    return <any>JSON.parse(window.localStorage.getItem(USER_SELECTED_APPLICATION) || '{}') as Project;
  }

  percentageValueHandler(data: any, testType: string): any {
    if (testType === 'total') {
      return data.tests.total > 0 ? (Math.round((100 * data.tests.total) / data.tests.total)) : 0;
    } else if (testType === 'pass') {
      return data.tests.total > 0 ? (Math.round((100 * data.totalPassed) / data.tests.total)) : 0;
    } else if (testType === 'fail') {
      return data.tests.total > 0 ? (Math.round((100 * data.totalFailed) / data.tests.total)) : 0;
    }
  }

  paginationEmitter(event: any, type: string) {
    const first = event.first;
    console.log(event);
    
    let page = 0;
    if (first == 0) {
      page = 0;
    } else {
      page = <number>first / <number>event.rows;
    }
    let orgId: number = this.userStorageService.getUser().organization.organizationId
    const { user: { email } } = this.userStorageService.getUser()

    if (type === ExecutionTypes.SCHEDULED) {
      this.dashboardService.findAllPaginated(orgId, ExecutionTypes.SCHEDULED, this.value1, email, this.paginationSize, page, this.currentProject).subscribe((res: any) => {
        this.scheduleTestData = res.result
        this.isScreenLoading = false
      })
    } else if (type === ExecutionTypes.CICD) {
      this.dashboardService.findAllPaginated(orgId, 'CI-CD', this.value1, email, this.paginationSize, page, this.currentProject).subscribe((res: any) => {
        this.scheduleDevOpsData = res.result
        this.isScreenLoading = false
      })
    } else if (type === ExecutionTypes.EXECUTENOW) {
      this.dashboardService.findAllPaginated(orgId, ExecutionTypes.EXECUTENOW, this.value1, email, this.paginationSize, page, this.currentProject).subscribe((res: any) => {
        this.scheduleServerSideData = res.result
        this.isScreenLoading = false
      })
    }
  }

}
