import { HTTP_INTERCEPTORS, HttpEvent, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { UserStorageService } from './_services/user-storage.service'
import { AuthenticationService } from './_services/authentication.service'
import { AI_TOKEN, APP_OAUTH_DATA, JIRA_URL, LAMBDA_TEST_CREDENTIALS, SUAPP_ID } from 'src/app/constants'
import { environment } from 'src/environments/environment'

const TOKEN_HEADER_KEY = 'Authorization' // for Spring Boot back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private userStorageService: UserStorageService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = this.userStorageService.getUser();        
    let headersConfig: any = {}    
    const UN_SECURE_URLS =
      req.url.includes('deselect') ||
      req.url.includes('request-forgot-password') ||
      req.url.includes('request-create-password'); 
    const OKTA_URL = req.url.includes('/okta-login')
    const TOKEN_URL = req.url.includes('/token')
    const OPEN_AI = req.url.includes('https://api.openai.com/')
    const CREATE_JIRA_URL = req.url.includes('atlassian.net')
    const FILE_UPLOAD_URL = req.url.includes('import')
    const JIRA_ATTACHMENT_URL = req.url.includes('/connect/jira-issue-attachment/');
    const AZURE_ATTACHMENT_URL = req.url.includes('/azure/azure-issue-attachment/');
    const STACK_AI_URL = req.url.includes('www.stack-inference.com');
    const LAMBDA_URLS =
      req.url.includes('platforms') || req.url.includes('video') || req.url.includes('mobile-api.lambdatest.com') || req.url.includes('resolutions') || req.url.includes('org/concurrency')  || req.url.includes('/sessions')
    if (TOKEN_URL) {
      headersConfig = {
       // Authorization: 'Basic ' + btoa(APP_OAUTH_DATA),
        'Content-type': 'application/json',
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'suappId': localStorage.getItem(SUAPP_ID)
      }
    } else if (LAMBDA_URLS) {
      headersConfig = {
        Authorization:
          'Basic ' +
          btoa(
            `${LAMBDA_TEST_CREDENTIALS.username}:${LAMBDA_TEST_CREDENTIALS.accessKey}`
          ),
        'Content-type': 'application/x-www-form-urlencoded',
      }
    } else if (UN_SECURE_URLS) {
      headersConfig = {
        'Content-type': 'application/json',
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'suappId': localStorage.getItem(SUAPP_ID)
      }
    } else if (FILE_UPLOAD_URL) {
      const token = this.authenticationService.getToken()
      if (token) {
        headersConfig['Authorization'] = `Bearer ${token}`
        headersConfig['Content-type'] = undefined
        headersConfig['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone,
        headersConfig['suappId'] = localStorage.getItem(SUAPP_ID)
      }
    } 
    else if (OPEN_AI) {
      headersConfig = {
        Authorization:
        `Bearer ${localStorage.getItem(AI_TOKEN)}`,
        'Content-type': 'application/json',
      }
    }else if (JIRA_ATTACHMENT_URL) {
      const token = this.authenticationService.getToken()
      if (token) {
        headersConfig['Authorization'] = `Bearer ${token}`
        headersConfig['Content-type'] = undefined
        headersConfig['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone,
        headersConfig['suappId'] = localStorage.getItem(SUAPP_ID)
      }
    }else if (AZURE_ATTACHMENT_URL) {
      const token = this.authenticationService.getToken()
      if (token) {
        headersConfig['Authorization'] = `Bearer ${token}`
        headersConfig['Content-type'] = undefined
        headersConfig['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone,
        headersConfig['suappId'] = localStorage.getItem(SUAPP_ID)
      }
    } else if(STACK_AI_URL){
      headersConfig = {
        Authorization:
        `Bearer e9b3e458-fe15-4cb9-8a81-649be95b8f32`,
        'Content-type': 'application/json',
      }
    }else if (OKTA_URL) {
      headersConfig = {
        'Content-type': undefined,
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'suappId': localStorage.getItem(SUAPP_ID),
        'X-Organization-Id': req.headers.get('X-Organization-Id'),
        withCredentials: true
      }
    } else {
      const token = this.authenticationService.getToken();
      if (token) {
        headersConfig['Authorization'] = `Bearer ${token}`;
        headersConfig['Content-type'] = 'application/json';
        headersConfig['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        headersConfig['suappId'] = localStorage.getItem(SUAPP_ID)
        if(user && user.user && user.user.userId){
          headersConfig['userId'] = user.user.userId+'';
          headersConfig['userEmail'] = user.user.email;
          headersConfig['userRole'] = user.user.rcTeafRole.roleId+'';
          headersConfig['X-Organization-Id'] = 117816+'';
        }
      }
    }
    
    const request = req.clone({ setHeaders: headersConfig })
    return next.handle(request)
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
]
