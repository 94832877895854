
        <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate >
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="name" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Name</label>
                <div class="p-col-12 p-md-9">
                    <input id="name" type="text" pInputText formControlName="name" (blur)="updateFormValues()"> 
                    <div *ngIf="getControl.name.touched && getControl.name.invalid" class="errorMsg">
                        <div *ngIf="getControl.name.errors!.required">Name is required.</div>
                    </div>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="description" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Description</label>
                <div class="p-col-12 p-md-9">
                    <input id="description" type="text" pInputText formControlName="description" (blur)="updateFormValues()"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="frequency" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Frequency</label>
                <div class="p-col-12 p-md-9">
                    <p-dropdown [options]="frequencyItems" appendTo="body" formControlName="frequency" placeholder="Select frequency" (onChange)="updateFormValues()"></p-dropdown> 
                    <!-- <div *ngIf="getControl.frequency.touched && getControl.frequency.invalid" class="errorMsg">
                        <div *ngIf="getControl.frequency.errors!.required">frequency is required.</div>
                    </div> -->
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="date" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Date</label>
                <div class="p-col-12 p-md-9">
                    <p-calendar formControlName="date" appendTo="body" [minDate]="minDate" [showTime]="showTime" hourFormat="24" [showIcon]="true" (onBlur)="updateFormValues()" inputId="icon"></p-calendar>                                    
                    <div *ngIf="getControl.date.touched && getControl.date.invalid" class="errorMsg">
                        <div *ngIf="getControl.date.errors!.required">date & time is required.</div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!isEdit; else elseTemplate">
            <div class="p-col-12">
                <button pButton pRipple type="submit" label="Schedule" [disabled]="!form.valid" class="p-ml-1 p-f-right p-ripple p-button p-component" ></button>
                <button pButton pRipple type="button" label="Cancel" (click)="cancel()" class="p-mr-1 p-f-right p-button-outlined p-button p-component"></button>        
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            
        </ng-template>
        
        
</form>
