import { Base } from 'src/app/core/models/base.model'
import { Project } from '../projects/project'
import { ProjectExecEnvs } from '../projects/project-exec-env'
import { ProjectType } from '../projects/project-type'
import { TestConfigEnvironments } from './test-config-environments'

export class TestConfiguration extends Base {
  testConfigId!: number
  appActivity!: string
  appPackage!: string
  bsAppAppActivity!: string
  bsAppAppPackage!: string
  bsAppOsChoice!: string
  bsAppOsVersion!: number
  bsAppUrl!: string
  bsAutomateKey!: string
  bsLocalIndentifier!: string
  bsTestName!: string
  bsUsername!: string
  bsWebBrowerVersion!: number
  bsWebBrowserChoice!: string
  bsWebBrowserDimensionX!: number
  bsWebBrowserDimensionY!: number
  bsWebDeviceName!: string
  bsWebOsChoice!: string
  bsWebOsVersion!: number
  defaultWait!: number
  emailReporting!: number
  enableBsDebug!: number
  enableBsLocal!: number
  pageLoadWait!: number
  autoHealingWaitTime!: number
  platformChoice!: string
  smtpServer!: string
  emailAddress!: string
  emailPassword!: string
  serverPortNumber!: number
  serverSocketPortNumber!: number
  emailRecipients!: string[]
  testRunEnvironment!: string
  configEnvironments!: TestConfigEnvironments
  build!: string
  rcTeafApplication!: Project
  rcTeafApplicationType!: ProjectType
  rcTeafAppExecEnv!: ProjectExecEnvs
  configType!: string 
  concurrency!: string
  applitoolsapikey!: string
  preferredGeoLocation!: string
  tunnel!: string
  constructor(
    testConfigId?: number,
    appActivity?: string,
    appPackage?: string,
    bsAppAppActivity?: string,
    bsAppAppPackage?: string,
    bsAppOsChoice?: string,
    bsAppOsVersion?: number,
    bsAppUrl?: string,
    bsAutomateKey?: string,
    bsLocalIndentifier?: string,
    bsTestName?: string,
    bsUsername?: string,
    bsWebBrowerVersion?: number,
    bsWebBrowserChoice?: string,
    bsWebBrowserDimensionX?: number,
    bsWebBrowserDimensionY?: number,
    bsWebDeviceName?: string,
    bsWebOsChoice?: string,
    bsWebOsVersion?: number,
    defaultWait?: number,
    emailReporting?: number,
    enableBsDebug?: number,
    enableBsLocal?: number,
    pageLoadWait?: number,
    autoHealingWaitTime?: number,
    platformChoice?: string,
    smtpServer?: string,
    emailAddress?: string,
    emailPassword?: string,
    serverPortNumber?: number,
    serverSocketPortNumber?: number,
    emailRecipients?: any[],
    testRunEnvironment?: string,
    configEnvironments?: TestConfigEnvironments,
    build?: string,
    rcTeafApplication?: Project,
    rcTeafApplicationType?: ProjectType,
    rcTeafAppExecEnv?: ProjectExecEnvs,
    concurrency?: string,
    applitoolsapikey?: string,
    preferredGeoLocation?:string,
    tunnel?:string,
  )
  constructor(
    testConfigId?: number,
    appActivity?: string,
    appPackage?: string,
    bsAppAppActivity?: string,
    bsAppAppPackage?: string,
    bsAppOsChoice?: string,
    bsAppOsVersion?: number,
    bsAppUrl?: string,
    bsAutomateKey?: string,
    bsLocalIndentifier?: string,
    bsTestName?: string,
    bsUsername?: string,
    bsWebBrowerVersion?: number,
    bsWebBrowserChoice?: string,
    bsWebBrowserDimensionX?: number,
    bsWebBrowserDimensionY?: number,
    bsWebDeviceName?: string,
    bsWebOsChoice?: string,
    bsWebOsVersion?: number,
    defaultWait?: number,
    emailReporting?: number,
    enableBsDebug?: number,
    enableBsLocal?: number,
    pageLoadWait?: number,
    autoHealingWaitTime?: number,
    platformChoice?: string,
    smtpServer?: string,
    emailAddress?: string,
    emailPassword?: string,
    serverPortNumber?: number,
    serverSocketPortNumber?: number,
    emailRecipients?: any[],
    testRunEnvironment?: string,
    configEnvironments?: TestConfigEnvironments,
    build?: string,
    rcTeafApplication?: Project,
    rcTeafApplicationType?: ProjectType,
    rcTeafAppExecEnv?: ProjectExecEnvs,
    concurrency?: string,
    applitoolsapikey?: string,
    preferredGeoLocation?: string,
    tunnel?: string,
  ) {
    super()
    this.testConfigId = testConfigId || 0
    this.appActivity = appActivity || ''
    this.appPackage = appPackage || ''
    this.bsAppAppActivity = bsAppAppActivity || ''
    this.bsAppAppPackage = bsAppAppPackage || ''
    this.bsAppOsChoice = bsAppOsChoice || ''
    this.bsAppOsVersion = bsAppOsVersion || 0
    this.bsAppUrl = bsAppUrl || ''
    this.bsAutomateKey = bsAutomateKey || ''
    this.bsLocalIndentifier = bsLocalIndentifier || ''
    this.bsTestName = bsTestName || 'RC Test'
    this.bsUsername = bsUsername || ''
    this.bsWebBrowerVersion = bsWebBrowerVersion || 0
    this.bsWebBrowserChoice = bsWebBrowserChoice || ''
    this.bsWebBrowserDimensionX = bsWebBrowserDimensionX || 0
    this.bsWebBrowserDimensionY = bsWebBrowserDimensionY || 0
    this.bsWebDeviceName = bsWebDeviceName || ''
    this.bsWebOsChoice = bsWebOsChoice || ''
    this.bsWebOsVersion = bsWebOsVersion || 0
    this.defaultWait = defaultWait || 0
    this.emailReporting = emailReporting || 0
    this.enableBsDebug = enableBsDebug || 0
    this.enableBsLocal = enableBsLocal || 0
    this.pageLoadWait = pageLoadWait || 0
    this.autoHealingWaitTime = autoHealingWaitTime || 0
    this.platformChoice = platformChoice || ''
    this.smtpServer = smtpServer || ''
    this.emailAddress = emailAddress || ''
    this.emailPassword = emailPassword || ''
    this.serverPortNumber = serverPortNumber || 0
    this.serverSocketPortNumber = serverSocketPortNumber || 0
    this.testRunEnvironment = testRunEnvironment || ''
    this.configEnvironments = this.configEnvironments || []
    this.build = build || 'First Test',
    this.emailRecipients = emailRecipients || [],
    this.concurrency = concurrency || '',
    this.applitoolsapikey = applitoolsapikey || ''
    this.preferredGeoLocation = preferredGeoLocation || ''
    this.tunnel = tunnel || ''
  }
}
