import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthenticationService } from './_services/authentication.service';
import {DialogService} from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    
    constructor(private authenticationService: AuthenticationService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(retry(1),catchError((err: HttpErrorResponse) => {
          const OPEN_AI = request.url.includes('https://api.openai.com/')
          const LAMBDA_URLS =
          request.url.includes('platforms') 
          || request.url.includes('video') 
          || request.url.includes('mobile-api.lambdatest.com') 
          || request.url.includes('resolutions') || request.url.includes('org/concurrency')  || request.url.includes('/sessions')
          console.log('ErrorInterceptor', err);
            if(err.error && err.error.apierror && err.error.apierror.debugMessage && err.error.apierror.debugMessage.includes('JWT expired at')) {
              this.authenticationService.logout(true).subscribe(
                (data) => {
                  window.location.reload();
                },
                (error) => {
                    console.log(error);
                }
            );
            }
            if ((!OPEN_AI && !LAMBDA_URLS) && [401, 403].indexOf(err.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                this.authenticationService.logout(true).subscribe(
                    (data) => {
                      window.location.reload();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
               
            }
            /*
            if(err.status === 0){
                this.authenticationService.logout();
                window.location.reload();
            }*/

            let errorMessage = '';
            if (err.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${err.error.message}`;
            } else {
              // server-side error
              if(err.error.apierror){
                errorMessage = `Message: ${err.error.apierror.debugMessage}`;
              }              
            }            
            return throwError(err);
        }))
    }



    
}