import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem, MessageService } from "primeng/api";
import {
  CONFIG_ROUTE,
  EXECUTE_ROUTE,
  PAGE_ELEMENTS_ROUTE,
  RESULTS_ROUTE,
  SCRIPT_LISTING_ROUTE,
  SETTINGS_ROUTE,
} from "src/app/constants";
import { MasterDataService } from "src/app/core/common/services/master-data.service";
import { RoleEnum } from "src/app/core/models/enum";
import { AuthenticationService } from "src/app/core/_helpers/_services/authentication.service";
import { UserStorageService } from "src/app/core/_helpers/_services/user-storage.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styles: [],
})
export class SidebarComponent implements OnInit {
  public displayModal: boolean = false;
  constructor(
    private authService: AuthenticationService,
    private userStorageService: UserStorageService,
    private messageService: MessageService,
    private router: Router,
    private masterDataService: MasterDataService
  ) {}

  items!: MenuItem[];

  ngOnInit() {

    this.items = [

      {

        label: "Organizations",

        icon: "pi pi-fw pi-globe",

        routerLink: "/organizations",

        visible: this.isAdmin,

      },

      {

        label: "Projects",

        icon: "pi pi-fw pi-folder",

        routerLink: "/projects/listing",

      },

      {

        label: "Tests",

        icon: "pi pi-fw pi-file",

        routerLink: "/tests",

        command: (event) => {

          event.click = this.checkProject(SCRIPT_LISTING_ROUTE, true);

         

        },

      },

      {

        label: "Test Plans",

        icon: "pi pi-fw pi-folder",

        routerLink: "/plans",

      },

      {

        label: "Executions",

        icon: "pi pi-fw pi-play",

        routerLink: "/execute",

        command: (event) => {

          event.click = this.checkProject(EXECUTE_ROUTE, true);

        },

      },

      {

        label: "Results",

        icon: "pi pi-fw pi-check-square",

        routerLink: "/results/"+this.userStorageService.getUserSelectedProject().applicationId,

        command: (event) => {

          event.click = this.checkProject(RESULTS_ROUTE+'/'+this.userStorageService.getUserSelectedProject().applicationId, true);

        },

      },

      {

        label: "Dashboard",

        icon: "pi pi-fw pi-chart-bar",

        routerLink: "/dashboard",

      },

      {

        label: "Users",

        icon: "pi pi-fw pi-user",

        routerLink: "/manageuser",

        visible: !this.isTestEngineer,

      },

      // {label: 'AI Report (Beta)', icon: 'pi pi-fw pi-book', routerLink: "/gtp-sense",},
      {label: 'AI Assistant (Beta)', icon: 'pi pi-fw pi-book', routerLink: "/ai-assistant",},

      {

        label: "Project Settings",

        icon: "pi pi-fw pi-clone",

        routerLink: "/settings",

        command: (event) => {

          event.click = this.checkProject(SETTINGS_ROUTE, true);

        },

      },

      {

        label: "Page Elements",

        icon: "pi pi-fw pi-th-large",

        routerLink: "/page-elements",

        command: (event) => {

          event.click = this.checkProject(PAGE_ELEMENTS_ROUTE, true);

        },

      },

      {

        label: "Configuration",

        icon: "pi pi-fw pi-cog",

        routerLink: "/config",

        command: (event) => {

          event.click = this.checkProject(CONFIG_ROUTE, true);

        },

      },

      //{label: 'Help Center', icon: 'pi pi-fw pi-question-circle'}

    ];

  }

  get isAdmin(): boolean {
    return this.authService.hasRole(RoleEnum.SuperAdmin);
  }

  get isTestManager(): boolean {
    return this.authService.hasRole(RoleEnum.TestManager);
  }

  get isTestEngineer(): boolean {
    return this.authService.hasRole(RoleEnum.TestEngineer);
  }

  checkProject(route: string, clear?: boolean) {
    if (this.userStorageService.getUserSelectedProject().applicationId === 0) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please Select Project from Project Dropdown.",
        life: 9000,
        closable: true,
      });
      return;
    } else {
      this.router.navigate([route]);
    }
  }
}
