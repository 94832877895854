import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuardService } from './core/_helpers/_services/auth.guard.service'

import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { ChangePasswordComponent } from './changepassword/changepassword.component';
import { ExecuteResolverService } from './modules/execute/services/execute.resolver'
import { RoleGuardService } from './core/_helpers/_services/role.guard.service';
import { RouteLinkGuard } from './core/_helpers/_services/route-link-guard';
import { RegistrationExpiredComponent } from './registration-expired/registration-expired.component';
import { ErrorComponent } from './error/error.component';
import { SignupComponent } from './signup/signup.component';
import { FreeTrialGuardService } from './core/_helpers/_services/free-trial.guard.service';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'forgot-password/:email', component: ResetPasswordComponent },
  { path: 'changepassword', component: ChangePasswordComponent },
  { path: 'create-password/:email', component: ResetPasswordComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'expired', component: RegistrationExpiredComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'signup', component: SignupComponent },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(
        (module) => module.DashboardModule
      ),
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'organizations',
    loadChildren: () =>
      import('./modules/organizations/organizations.module').then(
        (module) => module.OrganizationsModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./modules/projects/projects.module').then(
        (module) => module.ProjectsModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'manageuser',
    loadChildren: () =>
      import('./modules/manageuser/manageuser.module').then(
        (module) => module.ManageuserModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'tests',
    loadChildren: () =>
      import('./modules/tests/scripts/scripts.module').then(
        (module) => module.ScriptsModule
      ),
    canActivate: [AuthGuardService, RouteLinkGuard],
  },
  {
    path: 'plans',
    loadChildren: () =>
      import('./modules/plans/plans.module').then(
        (module) => module.PlansModule
      ),
    canActivate: [AuthGuardService, RouteLinkGuard],
  },
  {
    path: 'apis',
    loadChildren: () =>
      import('./modules/tests/apis/scripts.module').then(
        (module) => module.ScriptsModule
      ),
    canActivate: [AuthGuardService, RouteLinkGuard],
  },
  {
    path: 'mobile-test',
    loadChildren: () =>
      import('./modules/tests/mobile-tests/mobile-tests.module').then(
        (module) => module.MobileTestsModule
      ),
    canActivate: [AuthGuardService, RouteLinkGuard],
  },
  {
    path: 'page-elements',
    loadChildren: () =>
      import('./modules/page-elements/page-elements.module').then(
        (module) => module.PageElementsModule
      ),
    canActivate: [AuthGuardService, RouteLinkGuard],
  },
  {
    path: 'execute',
    //resolve: { data: ExecuteResolverService },
    loadChildren: () =>
      import('./modules/execute/execute.module').then(
        (module) => module.ExecuteModule
      ),
    canActivate: [AuthGuardService, RouteLinkGuard],
  },
  {
    path: 'results/:applicationId',
    loadChildren: () =>
      import('./modules/results/result.module').then(
        (module) => module.ResultModule
      ),
  },
  {
    path: 'config',
    loadChildren: () =>
      import('./modules/config/config.module').then((m) => m.ConfigModule)
  },
  { path: 'settings', loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuardService, RouteLinkGuard] },
  {
    path: 'gtp-sense',
    loadChildren: () =>
      import('./modules/gtp-sense/gtp-sense.module').then(
        (module) => module.GTPSenseModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'ai-assistant',
    loadChildren: () =>
      import('./modules/ai-assistant/ai-assistant.module').then(
        (module) => module.AIAssistantModule
      ),
    canActivate: [AuthGuardService],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
