<!-- <p-toast></p-toast> -->
<div class="p-d-flex p-jc-center">
  <div class="p-col-12 p-d-flex p-jc-center main-box">
    <div class="p-col-5 login-bg"></div>
    <div class="p-col-12 p-sm-12 p-md-5 p-lg-3" style="padding-left: 0rem; padding-bottom: 0rem">
      <div class="p-m-6">
        <div class="p-d-flex p-jc-center">
          <div class="logo-login">
            <img src="assets/gtp-icon.svg" alt="GoTestPro" />
            <img src="assets/rc-teaf-logo.svg" alt="GoTestPro" />
          </div>
        </div>
        <form name="form" [formGroup]="form" novalidate>
          <div class="p-fluid">
            <div class="p-field">
              <label>First Name</label>
              <input name="name" required formControlName="name" pInputText placeholder="First Name" />
            </div>

            <div class="p-field">
              <label>Last Name</label>
              <input name="lastName" required formControlName="lastName" pInputText placeholder="Last Name" />
            </div>

            <div class="p-field">
              <label>Email</label>
              <input name="email" required formControlName="email" pInputText placeholder="Email" type="email" />
              <small class=" text-red-600" *ngIf="

                            !form.controls['email'].valid &&
                            form.controls['email'].touched
                          ">
                Only organization emails are allowed for signup
              </small>
            </div>

            <!-- <div class="p-field">
              <label>Organization</label>
              <input name="organization" required formControlName="organization" pInputText
                placeholder="Organization" />
            </div> -->


          </div>

          <div class="btns">
            <button *ngIf="!isSubmit" pButton class="p-fluid p-mb-2 sign-up-btn p-button p-component" type="button"
              label="Sign Up" [disabled]="!form.valid" (click)="onSubmit()"></button>

            <div class="p-text-center">
              <a routerLink="/login">Already have a account, Sign In</a>
            </div>

            <button *ngIf="isSubmit" pButton icon="pi pi-spin pi-spinner" class="p-fluid p-mb-2 sign-up-btn p-button p-component"
              type="button" label="Sign Up" disabled="disabled"></button>

          </div>



        </form>

      </div>
    </div>
  </div>
</div>