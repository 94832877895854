import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { mapValues } from "lodash";
import {
  ConfirmationService,
  FilterMetadata,
  LazyLoadEvent,
  MessageService,
} from "primeng/api";
import { PAGINATION_SIZE_FIVE, START_INDEX } from "src/app/constants";
import { MasterDetailCommands } from "src/app/core/master-detail-command";
import { Frequencies } from "src/app/core/models/enum";
import { UnsubscribeOnDestroyAdapter } from "src/app/core/unsubscribe-on-destroy-adapter";
import { UserStorageService } from "src/app/core/_helpers/_services/user-storage.service";
import { ManageUser } from "../../manageuser/manage-user";
import { Project } from "../../projects/project";
import { ScheduleTest } from "../schedule-test";
import { ScheduleTestService } from "../services/schedule-test.service";
import * as moment from "moment";
@Component({
  selector: "app-schedule-list",
  templateUrl: "../pages/schedule-list.component.html",
  styleUrls: [],
})
export class ScheduleListComponent
  extends UnsubscribeOnDestroyAdapter
  implements MasterDetailCommands<ScheduleTest>, OnInit, OnChanges
{
  @Input() refresh!: boolean;
  @Input() tagId?: any;
  scheduleList!: Array<ScheduleTest>;
  totalRecords!: number;
  isScreenLoading!: boolean;
  displayDialog!: boolean;
  @Output() formValues: EventEmitter<any> = new EventEmitter<any>();
  scheduleInfo!: ScheduleTest;
  isGridLoading!: boolean;
  selectedTest!: ScheduleTest;
  selectedId!: number;
  paginationSize = PAGINATION_SIZE_FIVE;

  constructor(
    private scheduleTestService: ScheduleTestService,
    private userStorage: UserStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refresh.currentValue) {
      this.getAll();
    }
  }

  ngOnInit() {
    this.getAll();
  }

  save() {}

  getAll() {
    this.loadPaginated(
      this.userStorage.getUserSelectedProject().applicationId,
      this.paginationSize,
      START_INDEX
    );
  }

  close() {}

  delete(testInfo: ScheduleTest) {
    this.isGridLoading = true;
    this.subs.sink = this.scheduleTestService
      .delete(testInfo.testSchedularId)
      .subscribe((res) => {
        this.scheduleList = this.scheduleList.filter(
          (val) => val.testSchedularId !== testInfo.testSchedularId
        );
        this.messageService.add({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
        this.isGridLoading = false;
      });
  }

  select() {}

  update(test?: ScheduleTest) {
    let scheduleInfo: ScheduleTest = new ScheduleTest();
    let scheduleId: number = 0;
    if (test) {
      scheduleId = test.testSchedularId;
      scheduleInfo = mapValues(test);
      // scheduleInfo = scheduleInfo.isActive ?
      if (scheduleInfo.isActive === "true") {
        scheduleInfo.isActive = "false";
      } else {
        scheduleInfo.isActive = "true";
      }
    } else {
      scheduleInfo = mapValues(this.scheduleInfo);
      scheduleInfo.isActive = this.selectedTest.isActive;
      scheduleInfo.orgId = this.selectedTest.orgId;
      scheduleInfo.applicationId = this.selectedTest.applicationId;
      scheduleInfo.testConfigId = this.selectedTest.testConfigId;
      scheduleId = this.selectedId;
    }
    scheduleInfo.modifiedBy = this.getUser().user.userId;
    let testDate: moment.Moment = moment.utc(scheduleInfo.date);
    scheduleInfo.date = new Date(scheduleInfo.date);
    if (scheduleInfo.runDate) {
      scheduleInfo.runDate = new Date(scheduleInfo.runDate);
    }
    if (scheduleInfo.nextRunDate) {
      scheduleInfo.nextRunDate = new Date(scheduleInfo.nextRunDate);
    }
    scheduleInfo.dateLong = scheduleInfo.date.getTime();

    this.scheduleTestService
      .update(scheduleId, scheduleInfo)
      .subscribe((res) => {
        this.messageService.add({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
        this.scheduleList[this.findIndexById(res.result.testSchedularId)] =
          res.result;
        const test = res.result;
        const nextRunDate = test.formattedDate.split(" ");
        test.nextRun = "At " + nextRunDate[1] + " " + nextRunDate[0];
        this.isGridLoading = false;
      });
    this.displayDialog = false;
  }

  findIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.scheduleList.length; i++) {
      if (this.scheduleList[i].testSchedularId === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  getUser(): ManageUser {
    return this.userStorage.getUser();
  }

  private filteredResults(id: number, value: string) {
    this.subs.sink = this.scheduleTestService
      .findFiltered(id, value)
      .subscribe((res) => {
        this.scheduleList = res.result;
        this.isScreenLoading = false;
      });
  }

  private loadPaginated(id: number, rowsPerPage: number, page: number) {
    this.isScreenLoading = true;
    const params = {applicationId: id, tagId: this.tagId, rowsPerPage: rowsPerPage, startIndex: page}
    this.subs.sink = this.scheduleTestService
      .findScheduleTestsByApplicationId(params)
      .subscribe((res) => {
        this.scheduleList = res.result.content;
        this.totalRecords = res.result.totalElements;
        this.scheduleList.forEach((test) => {
          const nextRunDate = test.formattedDate.split(" ");
          test.nextRun = "At " + nextRunDate[1] + " " + nextRunDate[0];
        });
        if (this.tagId) {
          this.scheduleList = this.scheduleList.filter(
            (e: any) => e.tagId === parseInt(this.tagId)
          );
        }
        this.isScreenLoading = false;
      });
  }

  nextPage(event: LazyLoadEvent) {
    const first = event.first;

    let page = 0;
    if (first == 0) {
      page = 0;
    } else {
      page = <number>first / <number>event.rows;
    }
    const id = this.getProject().applicationId;

    if (Object.keys(<any>event.filters).length > 0) {
      const rowsPerPage = <number>event.rows;
      const name: FilterMetadata = <any>event.filters?.name;
      if (name instanceof Array) {
        if (name[0].value != null) {
          this.filteredResults(id, name[0].value);
        } else {
          this.loadPaginated(id, rowsPerPage, page);
        }
      } else {
        this.loadPaginated(id, rowsPerPage, page);
      }
    } else {
      this.loadPaginated(id, <number>event.rows, page);
    }
  }
  getProject(): Project {
    return this.userStorage.getUserSelectedProject();
  }

  deleteScheduled(testInfo: ScheduleTest) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + testInfo.name + "?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.delete(testInfo);
      },
    });
  }

  showDialog(test: ScheduleTest) {
    this.displayDialog = true;
    this.selectedTest = test;
    this.selectedId = test.testSchedularId;
  }

  getSchedularInfo(event: any) {
    // this.formValues.emit(event);
    this.scheduleInfo = event;
  }

  parse(value: any): Date | null {
    if (typeof value === "string" && value.includes("/")) {
      const dateTime = value.split(" ");
      const dateStr = dateTime[0].split("/");
      const timeStr = dateTime[1].split(":");
      const hr = Number(timeStr[0]);
      const minute = Number(timeStr[1]);

      const year = Number(dateStr[2]);
      const month = Number(dateStr[1]) - 1;
      const date = Number(dateStr[0]);

      return new Date(year, month, date, hr, minute);
    } else if (typeof value === "string" && value === "") {
      return new Date();
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}
