import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-error',
  templateUrl: './registration-expired.component.html',
  styles: [],
})
export class RegistrationExpiredComponent implements OnInit {
  
  constructor(
    
  ) {}

  ngOnInit(): void {}

  
}
