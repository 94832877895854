import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, SelectItem } from "primeng/api";
import { UserStorageService } from "src/app/core/_helpers/_services/user-storage.service";
import {
  Browsers,
  Concurrency,
  ExecutionTypes,
  Platforms,
  TestRunEnvironment,
} from "src/app/core/models/enum";
import { MasterDataService } from "src/app/core/common/services/master-data.service";
import { UnsubscribeOnDestroyAdapter } from "src/app/core/unsubscribe-on-destroy-adapter";
import { TestEvironmentBrowerDetails } from "../test-environment-browser-details";
import { TestConfigEnvironments } from "../test-config-environments";
import { ConfigService } from "../services/config.service";
import { TestConfiguration } from "../test-configuration";
import { COMMON_RESOLUTIONS, DESKTOP_PLATFORMS } from "src/app/constants";
import { OrganizationService } from "../../organizations/services/organization.service";
import { OrganizationConcurrency } from "src/app/core/models/organization-concurrency";
import { EnvironmentGroups } from "../environment-groups";
import { AuthenticationService } from "src/app/core/_helpers/_services/authentication.service";
import { environment } from "src/environments/environment";
import { ManageUser } from "../../manageuser/manage-user";

@Component({
  selector: "app-config-form",
  templateUrl: "../pages/config-form.component.html",
  styles: [],
})
export class ConfigFormComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, OnChanges
{
  @Output() removeSelection: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  appUrl: any;
  public configForm: FormGroup;
  public appEnvs: any[] = [];
  scriptExecute: any[] = [];
  @Input() testPlanId!: any;
  implicit: any[] = [
    { label: 10, value: 10 },
    { label: 15, value: 15 },
    { label: 20, value: 20 },
    { label: 25, value: 25 },
    { label: 30, value: 30 },
    { label: 60, value: 60 },
    { label: 90, value: 90 },
    { label: 120, value: 120 },
  ];

  autoHealingWaitTimeOpt: any[] = [
    { label: 10, value: 10 },
    { label: 15, value: 15 },
    { label: 20, value: 20 },
    { label: 25, value: 25 },
    { label: 30, value: 30 },
    { label: 60, value: 60 },
    { label: 90, value: 90 },
    { label: 120, value: 120 },
  ];

  browsers = Browsers;
  browserItems!: SelectItem[];
  enumPlatforms = Platforms;
  platformItems!: SelectItem[];
  testRunEnvironments = TestRunEnvironment;
  testRunEnvironmentItems!: SelectItem[];
  applicationTypeItems: any[] = [];
  applicationType: any;
  public platforms: any = null;
  public configEnvironments: any = [];
  public testConfiguration: TestConfiguration = new TestConfiguration();
  public displayModal = false;
  public heading = "";
  public edit = false;
  public os: string = "";
  public isScreenLoading = false;
  public resolution: string = "";
  @Output() config = new EventEmitter<TestConfiguration>();
  @Input() refreshForm?: boolean = false;
  public concurencyObj = new OrganizationConcurrency();

  public selectedPlatform: any = null;
  public dekstopPlatforms: any[] = [];
  public selectedOS: any = null;
  public selectedBrowserName: string = "";
  public selectedBrowserVersion: string = "";
  public selectedResolution: string = "";
  public lambdaMobileDevices: any[] = [];
  tunnelOptions: any[] = [];
  isFreeTrail: boolean = true;
  geoLocations!: any;
  public environment = environment

  public multiGrids: any[] = [];
  constructor(
    private userStorageService: UserStorageService,
    private formBuilder: FormBuilder,
    private masterDataService: MasterDataService,
    private configService: ConfigService,
    private messageService: MessageService,
    private organizationService: OrganizationService,
    private authService: AuthenticationService
  ) {
    super();

    this.applicationType =
      this.userStorageService.getDefaultProject().rcTeafApplicationType;
    this.appEnvs =
      this.userStorageService.getUserSelectedProject().rcTeafAppExecEnvs;
    // this.scriptExecute =
    //   this.userStorageService.getUserSelectedProject().rcTeafScripts
    this.appEnvs.forEach((e) => {
      e.envKeyUrl = `${e.envKey} - ${e.envUrl}`;
    });
    this.browserItems = [];
    this.platformItems = [];

    this.testRunEnvironmentItems = [];
    this.applicationTypeItems = [
      {
        applicationType: this.applicationType.applicationType,
        applicationTypeId: this.applicationType.applicationTypeId,
      },
    ];
    for (const [key, value] of Object.entries(this.browsers)) {
      this.browserItems.push({ label: value, value: value });
    }
    for (const [key, value] of Object.entries(this.enumPlatforms)) {
      this.platformItems.push({ label: value, value: value });
    }
    for (const [key, value] of Object.entries(this.testRunEnvironments)) {
      this.testRunEnvironmentItems.push({
        label: key.replace("_", " "),
        value: value,
      });
    }
    const defaultEnv = this.appEnvs.find(env => env.isDefault === 'true');
    let selectedEnv = {};
    if(defaultEnv){
      selectedEnv = defaultEnv;
    }else{
      selectedEnv = this.appEnvs[0];
    }
    this.configForm = this.formBuilder.group({
      rcTeafAppExecEnv: [selectedEnv, [Validators.required]],
      platformChoice: [""],
      rcTeafApplicationType: [null],
      defaultWait: [30, [Validators.required]],
      pageLoadWait: [30, [Validators.required]],
      autoHealingWaitTime: [
        this.autoHealingWaitTimeOpt[0].value,
        [Validators.required],
      ],
      emailReporting: [false],
      testRunEnvironment: [
        this.testRunEnvironmentItems[0].value,
        [Validators.required],
      ],
      configEnvironments: [null],
      emailRecipients: [null],
      concurrency: [Concurrency.SEQUENTIAL, [Validators.required]],
      visualValidation: [false],
      preferredGeoLocation: [null],
      gridId: [1],
      tunnel: ['false']
    });
  }

  ngOnInit(): void {
    this.getTunnelOptions();
    this.getGeoLocations();
    this.getPlatforms();
    this.getConfig();
    this.getOrganizationConcurrency();
    this.getMobilePlatforms();
    this.getExecutionGrid()
    
    this.isFreeTrail = this.authService.isFreeTrailOrganizaiton();
  }
  getTunnelOptions() {
    this.tunnelOptions.push({'label': 'true', 'value' : 'true'}, {'label': 'false', 'value' : 'false'})
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refreshForm && changes.refreshForm.currentValue) {
      this.refreshForm = changes.refreshForm.currentValue;
      this.getPlatforms();
      this.getConfig();
    } else {
      this.refreshForm = false;
    }
  }

  save(): void {
    this.configForm.patchValue({
      configEnvironments: this.configEnvironments,
    });

    this.isScreenLoading = true;    
    const {
      rcTeafAppExecEnv,
      defaultWait,
      emailReporting,
      pageLoadWait,
      autoHealingWaitTime,
      platformChoice,
      rcTeafApplicationType,
      testRunEnvironment,
      configEnvironments,
      emailRecipients,
      concurrency,
      preferredGeoLocation,
      tunnel,
    } = this.configForm.value;
    if (emailReporting && !emailRecipients) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please provide email recipients or disable email reporting.",
        life: 4000,
      });
      this.isScreenLoading = false;
      return;
    }
    this.testConfiguration.rcTeafAppExecEnv = rcTeafAppExecEnv;
    this.testConfiguration.defaultWait = defaultWait;
    this.testConfiguration.emailReporting = emailReporting ? 1 : 0;
    this.testConfiguration.pageLoadWait = pageLoadWait;
    this.testConfiguration.platformChoice = platformChoice;
    this.testConfiguration.autoHealingWaitTime = autoHealingWaitTime;
    this.testConfiguration.rcTeafApplicationType = rcTeafApplicationType;
    this.testConfiguration.configEnvironments = configEnvironments;
    this.testConfiguration.testRunEnvironment = testRunEnvironment;
    this.testConfiguration.emailRecipients = [emailRecipients];
    this.testConfiguration.concurrency = concurrency;
    this.testConfiguration.preferredGeoLocation= preferredGeoLocation;
    this.testConfiguration.tunnel = tunnel;
    this.testConfiguration.rcTeafApplication =
      this.userStorageService.getUserSelectedProject();
      if (
        window.location.href.includes("config")
      ) {
        this.testConfiguration.configType = ExecutionTypes.EXECUTENOW;
      }
    if (
      window.location.href.includes("execute") ||
      window.location.href.includes("settings") ||
      window.location.href.includes("plans") ||
      window.location.href.includes("worksheet")
    ) {
      this.config.emit(this.testConfiguration);
      this.isScreenLoading = false;
      this.removeSelection.emit(true);
    } else {
      this.subs.sink = this.configService
        .save(this.testConfiguration)
        .subscribe((res) => {
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: res.message,
            life: 3000,
          });
          this.isScreenLoading = false;
        });
    }
  }

  update = () => {
    this.isScreenLoading = true;
    this.configForm.patchValue({
      configEnvironments: this.configEnvironments,
    });

    this.isScreenLoading = true;
    const {
      rcTeafAppExecEnv,
      defaultWait,
      emailReporting,
      pageLoadWait,
      autoHealingWaitTime,
      platformChoice,
      rcTeafApplicationType,
      testRunEnvironment,
      configEnvironments,
      emailRecipients,
      concurrency,
      preferredGeoLocation,
      tunnel,
    } = this.configForm.value;
    let recipients: string[] = [];
    if (emailReporting && !emailRecipients) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please provide email recipients or disable email reporting.",
        life: 4000,
      });
      this.isScreenLoading = false;
      return;
    }
    if (emailReporting) {
      if (emailRecipients instanceof Array) {
        recipients = emailRecipients;
      } else if (emailRecipients.includes(",")) {
        emailRecipients.split(",").forEach((element: string) => {
          recipients.push(element);
        });
      } else {
        recipients.push(emailRecipients);
      }
    }

    this.testConfiguration.rcTeafAppExecEnv = rcTeafAppExecEnv;
    this.testConfiguration.defaultWait = defaultWait;
    this.testConfiguration.emailReporting = emailReporting ? 1 : 0;
    this.testConfiguration.pageLoadWait = pageLoadWait;
    this.testConfiguration.platformChoice = platformChoice;
    this.testConfiguration.autoHealingWaitTime = autoHealingWaitTime;
    this.testConfiguration.rcTeafApplicationType = rcTeafApplicationType;
    this.testConfiguration.configEnvironments = configEnvironments;
    this.testConfiguration.testRunEnvironment = testRunEnvironment;
    this.testConfiguration.emailRecipients = recipients;
    this.testConfiguration.concurrency = concurrency;
    this.testConfiguration.preferredGeoLocation= preferredGeoLocation;
    this.testConfiguration.tunnel = tunnel;
    this.testConfiguration.rcTeafApplication =
      this.userStorageService.getUserSelectedProject();
      if (
        window.location.href.includes("config")
      ) {
        this.testConfiguration.configType = ExecutionTypes.EXECUTENOW;
      }
    // this.testConfiguration.configType = ExecutionTypes.EXECUTENOW;
    if (
      window.location.href.includes("execute") ||
      window.location.href.includes("settings") ||
      window.location.href.includes("plans") ||
      window.location.href.includes("worksheet")
    ) {
      this.config.emit(this.testConfiguration);

      this.isScreenLoading = false;
    } else {
      this.subs.sink = this.configService
        .update(this.testConfiguration.testConfigId, this.testConfiguration)
        .subscribe((res) => {
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: res.message,
            life: 3000,
          });
          this.isScreenLoading = false;
        });
    }
  };

  cancelHandler(): void {
    this.configForm.reset();
  }

  getPlatforms(): void {
    const executionGrid = this.masterDataService.getOrgGridDetails(this.getUser());
    const apis = JSON.parse(executionGrid.apiData);
    this.subs.sink = this.masterDataService.getPlatforms(apis.platforms_list).subscribe((res) => {
      this.platforms = res.platforms;
      const expectedPlatforms = DESKTOP_PLATFORMS.map((pl) => pl.toLowerCase());
      let expectedMobileGroupItems = new EnvironmentGroups();
      // this.platforms = res.platforms.Desktop.filter((platfrom: any) => expectedPlatforms.includes(platfrom.platform.toLowerCase()));

      // this.platforms = res.platform
      // this.subs.sink = this.masterDataService
      //   .getOsResolutions()
      //   .subscribe((res) => {
      this.platforms.Desktop.forEach((os: any) => {
        const isMac: boolean =
          os.platform.startsWith("OS ") === 0 ||
          os.platform.startsWith("macOS ") === 0 ||
          os.platform.startsWith("MacOS ") === 0;
        const isWin: boolean = os.platform.startsWith("Windows ") === 0;
        if (isMac) {
          os["resolution"] = COMMON_RESOLUTIONS;
          // res.mac_res.map((e: any) => ({
          //   label: e,
          //   value: e,
          // }));
        } else if (isWin) {
          os["resolution"] = COMMON_RESOLUTIONS;
          // res.win_res.map((e: any) => ({
          //   label: e,
          //   value: e,
          // }));
        } else {
          os["resolution"] = COMMON_RESOLUTIONS;
          // = res.win_res.map((e: any) => ({
          //   label: e,
          //   value: e,
          // }));
        }
      });
      if (!this.configEnvironments.length) {
        const selectedObj = this.platforms.Desktop.filter(
          (e: any) => e.platform === "Windows 11"
        );
        const { platform, resolution, browsers } = selectedObj[0];
        const obj = {
          browserDetails: [
            {
              browserDetailsId: 0,
              browserName: browsers[0].browser_name,
              browserVersion: 111.0,
              testConfigEnvironmentsId: 0,
              id: Math.random().toString(),
            },
          ],
          id: Math.random(),
          os: platform,
          resolution: resolution[0].value,
          testConfigEnvironmentsId: 0,
          testConfigId: "",
        };
        this.configEnvironments.push(obj);
      }
    });
  }

  getConfig(): void {
    // this.isScreenLoading = true;
    const applicationId =
      this.userStorageService.getUserSelectedProject().applicationId;
    let subscriptions;
    if(this.testPlanId && this.testPlanId > 0){
      const params = {applicationId: applicationId, tagId: <number>this.testPlanId};
      subscriptions = this.configService.findConfigByApplicationIdAndTestPlanId(params);
    }else{
      subscriptions = this.configService.findConfigByApplicationId(applicationId);
    }
    this.subs.sink = subscriptions.subscribe(
        (res) => {
          if (res.result) {
            this.isScreenLoading = true;
            this.testConfiguration = res.result;
            const rcTeafAppExecEnv = this.appEnvs.find(env => env.isDefault === 'true');
            let selectedEnv = {};
            if(rcTeafAppExecEnv){
              selectedEnv = rcTeafAppExecEnv;
            }else{
              selectedEnv = this.appEnvs.find(
                (e) =>
                  e.appExecEnvId ===
                  this.testConfiguration.rcTeafAppExecEnv.appExecEnvId
              );
            }
            // const rcTeafAppExecEnv = this.appEnvs.filter(
            //   (e) =>
            //     e.appExecEnvId ===
            //     this.testConfiguration.rcTeafAppExecEnv.appExecEnvId
            // );
            this.configEnvironments = this.testConfiguration.configEnvironments;

            this.configForm.patchValue({
              rcTeafAppExecEnv: selectedEnv,
              platformChoice: this.testConfiguration.platformChoice,
              rcTeafApplicationType:
                this.testConfiguration.rcTeafApplicationType,
              defaultWait: this.testConfiguration.defaultWait,
              pageLoadWait: this.testConfiguration.pageLoadWait,
              autoHealingWaitTime: this.testConfiguration.autoHealingWaitTime,
              emailReporting: this.testConfiguration.emailReporting,
              emailRecipients:
                this.testConfiguration.emailReporting === 1
                  ? this.testConfiguration.emailRecipients
                  : null,
              testRunEnvironment:
                this.testConfiguration.testRunEnvironment ||
                TestRunEnvironment.Server_Side,
              configEnvironments: this.testConfiguration.configEnvironments,
              concurrrency: this.testConfiguration.concurrency,
              preferredGeoLocation: this.testConfiguration.preferredGeoLocation,
              tunnel: this.testConfiguration.tunnel,
            });
            this.isScreenLoading = false;
          }
        },
        (err: any) => {
          this.isScreenLoading = false;
        }
      );
  }

  showLocatorDialog(heading: string, edit: boolean) {
    this.heading = heading;
    this.edit = edit;
    this.displayModal = true;
  }

  public environmentType: string = "";
  public platformName: string = "";
  close(event: any) {
    this.displayModal = false;
    const { platform, resolution, environmentType } = event;
    this.os = platform;
    this.resolution = resolution;
    this.environmentType = environmentType;
    this.platformName = event.deviceName ? event.deviceName : "";
  }

  browserDetailsEmitter(event: TestEvironmentBrowerDetails): void {
    let environment = new TestConfigEnvironments();
    environment.id = Math.random();
    environment.os = this.os;
    environment.resolution = this.resolution;
    environment.environmentType = this.environmentType;
    environment.platformName = this.platformName;
    environment.browserDetails.push(event);
    this.configEnvironments.push(environment);
  }

  removeEnvironmentHandler(env: TestConfigEnvironments): void {
    let id: number = 0;
    if (env.testConfigEnvironmentsId !== 0) {
      this.configEnvironments = this.configEnvironments.filter(
        (e: any) => e.testConfigEnvironmentsId !== env.testConfigEnvironmentsId
      );
    } else {
      id = env.id;
      this.configEnvironments = this.configEnvironments.filter(
        (e: any) => e.id !== id
      );
    }
  }

  getOrganizationConcurrency() {
    const executionGrid = this.masterDataService.getOrgGridDetails(this.getUser());
    const apis = JSON.parse(executionGrid.apiData);
    this.organizationService.getConcurrency(apis.concurrency).subscribe((res: any) => {
      this.concurencyObj = res;
    });
  }

  getUser() : ManageUser {
    return this.userStorageService.getUser();
  }

  public allDevices: any[] = [];
  extractBrowsers(): void {
    this.browserNamesItems = [];
    this.browserVersionsItems = [];
    this.selectedBrowserName = "";
    if (this.selectedPlatform.value === "mobile") {
      const osVersion = this.lambdaMobileDevices.filter(
        (platform: any) => platform.deviceName === this.selectedPlatform.label
      );
      this.selectedOS = osVersion[0];
      const androidBrowsers = [
        {
          browser_name: "Firefox",
          version: "97.0",
        },
        {
          browser_name: "Chrome",
          version: "99.0",
        },
      ];
      const iosBrowsers = [
        {
          browser_name: "Firefox",
          version: "97.0",
        },
        {
          browser_name: "Safari",
          version: "15.0",
        },
      ];

      this.selectedOS.resolution = osVersion.map((e: any) => ({
        label: e.platformVersion,
        value: e.platformVersion,
      }));
      this.selectedResolution = this.selectedOS.resolution[0].value;
      this.selectedOS.browsers =
        this.selectedOS.deviceName.includes("iPhone") ||
        this.selectedOS.deviceName.includes("ios") ||
        this.selectedOS.deviceName.includes("iPad") ||
        this.selectedOS.deviceName.includes("iPod")
          ? [...iosBrowsers]
          : [...androidBrowsers];
      this.selectedOS.deviceType = "mobile";
      this.selectedBrowserName = this.selectedOS.browsers[1].browser_name;
      this.selectedBrowserVersion = this.selectedOS.browsers[1].version;
    } else {
      this.selectedOS = this.platforms.Desktop.filter(
        (platform: any) => platform.platform === this.selectedPlatform.label
      )[0];
      this.selectedOS.deviceType = "desktop";
      this.selectedBrowserName = this.selectedOS.browsers[0].browser_name;
      this.selectedBrowserVersion = this.selectedOS.browsers[0].version;
      this.selectedResolution = this.selectedOS.resolution[0].value;
    }
    this.browserNamesItems = [];
    this.browserNames = this.selectedOS.browsers.map((e: any) => ({
      label: e.browser_name,
      value: e.browser_name,
    }));
    this.browserNames = [...new Set(this.browserNames)];
    this.browserNamesItems = this.browserNames.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.label === thing.label)
    );
    this.extractBrowserVersions();
  }

  extractBrowserVersions(): void {
    this.browserVersionsItems = [];
    const versions = this.selectedOS.browsers.filter(
      (e: any) => e.browser_name === this.selectedBrowserName
    );
    this.browserVersionsItems = versions.map((e: any) => ({
      label: e.version,
      value: e.version,
    }));
    if (this.selectedOS.deviceType === "mobile") {
      this.selectedBrowserVersion = this.selectedOS.browsers.filter(
        (e: any) => e.browser_name === this.selectedBrowserName
      )[0].version;
    }
  }
  browserVersionsItems!: SelectItem[];
  browserNamesItems!: SelectItem[];
  public browserNames: any[] = [];

  groupPlatforms(): void {
    const desktop = {
      label: "Desktop",
      value: "Desktop",
      items: this.platforms.Desktop.map((platform: any) => ({
        label: platform.platform,
        value: platform.platform.toLowerCase(),
      })),
    };

    this.allDevices = this.platforms.Mobile[0].devices.concat(
      this.platforms.Mobile[1].devices
    );
    const iosAllDevices = this.lambdaMobileDevices.filter(
      (e: any) => e.platformName === "ios"
    );
    let androidAllDevices = this.lambdaMobileDevices.filter(
      (e: any) => e.platformName === "android"
    );
    const iosDevices = this.removeDuplicates(iosAllDevices, "deviceName");
    const androidDevices = this.removeDuplicates(
      androidAllDevices,
      "deviceName"
    );
    const iosObj = {
      label: "iOS",
      value: "iOS",
      items: iosDevices.map((platform: any) => ({
        label: platform.deviceName,
        value: "mobile",
      })),
    };
    const iandroidObj = {
      label: "Android",
      value: "Android",
      items: androidDevices.map((platform: any) => ({
        label: platform.deviceName,
        value: "mobile",
      })),
    };
    this.groupedPlatforms.push(desktop);
    // this.dekstopPlatforms = this.platforms.Desktop.map((platform: any) => ({
    //   label: platform.platform,
    //   value: platform.platform.toLowerCase(),
    // }));
    this.groupedPlatforms.push(iosObj);
    this.groupedPlatforms.push(iandroidObj);
  }

  public groupedPlatforms: any[] = [];

  removeDuplicates = (myArr: any, prop: any) => {
    return myArr.filter((obj: any, pos: any, arr: any) => {
      return arr.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  };

  addRow() {
    const { platform, platformName, deviceType, deviceName } = this.selectedOS;
    this.os = platform;
    this.resolution = this.selectedResolution;
    this.environmentType = deviceType;

    let browserDetails = new TestEvironmentBrowerDetails();
    browserDetails.id = Math.random().toString();
    browserDetails.browserName = this.selectedBrowserName;
    browserDetails.browserVersion = this.selectedBrowserVersion;

    let environment = new TestConfigEnvironments();
    environment.id = Math.random();
    environment.os = platform || platformName;
    environment.platformName = deviceName || null;
    environment.resolution = this.selectedResolution;
    environment.environmentType = deviceType.toUpperCase();
    environment.browserDetails.push(browserDetails);
    this.configEnvironments.push(environment);
    this.selectedPlatform = null;
    this.selectedOS = { platform: "", browsers: [], resolution: [] };
    this.selectedBrowserName = "";
    this.selectedBrowserVersion = "";
    this.selectedResolution = "";
  }

  getMobilePlatforms() {
    this.isScreenLoading = true;
    this.configService.mobilePlatform().subscribe((res: any) => {
      this.lambdaMobileDevices = res;
      this.isScreenLoading = false;
      this.groupPlatforms();
    });
  }

  getGeoLocations() {
    this.isScreenLoading = true;
    this.masterDataService.getGeoLocations().subscribe((res: any) => {
      this.geoLocations = res.result;
      this.isScreenLoading = false;
    });
  }

  getExecutionGrid() {
    this.isScreenLoading = true;
    this.subs.sink = this.masterDataService.getExecutionGrid().subscribe(
      (res) => {
        if (res.result.length) {
          this.multiGrids = res.result;
        } else {
        }
        this.isScreenLoading = false;
      },
      (error) => {
        this.isScreenLoading = false;
      }
    );
  }
}
