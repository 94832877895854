<div *ngIf="isScreenLoading">
    <p-progressSpinner></p-progressSpinner>
    <div class="progressspinner-overlay"></div>
</div>
<div class="p-col-12" id="execute">
  <h3>Change Password</h3>
  <p-card>
    <div class="p-fluid">
      <div class="p-field p-grid">
        <label for="oldpassword" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
          >Old Password:</label
        >
        <div class="p-col-12 p-md-6">
          <input
            name="oldpassword"
            [(ngModel)]="oldpassword"
            type="password"
            pInputText
            placeholder="Old Password"
          />
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="newpassword" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
          >New Password:</label
        >
        <div class="p-col-12 p-md-6">
          <input
            name="newpassword"
            [(ngModel)]="newpassword"
            type="password"
            pInputText
            placeholder="New Password"
          />
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="confirmpassword" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
          >Confirm Password:</label
        >
        <div class="p-col-12 p-md-6">
          <input
            name="confirmpassword"
            [(ngModel)]="confirmpassword"
            type="password"
            pInputText
            placeholder="Confirm Password"
          />
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-md-2">
          <button
            [disabled]="!confirmpassword || !newpassword || !oldpassword"
            pButton
            class="login-btn p-f-right"
            type="submit"
            label="Submit"
            (click)="submitHandler()"
          ></button>
        </div>
      </div>
    </div>
  </p-card>
</div>
