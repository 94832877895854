<div class="p-grid p-col-12">

<ng-container *ngIf="scheduleList && scheduleList.length > 0; else noData">
    <p-table #dt [responsive]="true"
  [value]="scheduleList"
  styleClass="p-datatable-striped p-datatable-responsive-demo gtp-table"
  [paginator]="true"
  [rows]="paginationSize"
  [totalRecords]="totalRecords"
  [lazy]="true" (onLazyLoad)="nextPage($event)"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="[10,25,50]"
  [loading]="isGridLoading" [rowHover]="true">
      <ng-template pTemplate="header">
          <tr>
              <!-- <th style="width:4rem;" >S.No. </th> -->
              <th pSortableColumn="tag.tagName">Tag Name </th>
              <th style="width:12rem;" pSortableColumn="name">Job Name </th>
              <th pSortableColumn="frequency">Frequency </th>
              <th style="width:13rem;" >Next Run </th>
              <th pSortableColumn="status">Status </th>
              <th>Result </th>
              <th>Action</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ri="rowIndex" let-test>
          <tr>
              <!-- <td>
                  {{ri + 1}}
              </td> -->
              <td><ng-container *ngIf="test.tag !== null; else noTag">
                {{test.tag.tagName}}
              </ng-container>
              <ng-template #noTag>
                  
              </ng-template>
              </td>
              <td style="width:12rem;" class="overlp-elips" tooltipPosition="top" pTooltip="{{test.name}}">{{test.name}}</td>
              <td>{{test.frequency}}</td>
              <td style="width:13rem;" class="overlp-elips" tooltipPosition="top" pTooltip="{{test.nextRun}}">{{test.nextRun}}</td>
              <td>
                  <ng-container *ngIf="test.isActive === 'true'; else inactive">
                <a style="cursor: pointer;color: #2196F3;" (click)="update(test)" title="Click to deactivate">Active</a>
              </ng-container>
              <ng-template #inactive>
                <a style="cursor: pointer;color: #2196F3;" (click)="update(test)" title="Click to Activate">Inactive</a>
              </ng-template></td>
              <td>
                  <ng-container *ngIf="test.resultCounts && test.resultCounts > 0; else noResults">                        
                        <hero-icon routerLink="/results/{{getProject().applicationId}}" [queryParams]="{jobId: test.testSchedularId}" name="document-report" tooltipPosition="top" pTooltip="View Results" hostDisplay="inlineBlock"
                        type="outline"
                        class="p-2 w-8 h-8 ml-2 cursor-pointer rounded-md text-gray-400 bg-gray-100 hover:text-green-400"
                        ></hero-icon>
                  </ng-container>
                  <ng-template #noResults>
                      No Execution
                  </ng-template>
                  
              </td>
              <td>
                  <hero-icon name="pencil" tooltipPosition="top" pTooltip="Edit" hostDisplay="inlineBlock"
                                type="outline"
                                class="p-2 w-8 h-8 ml-2 cursor-pointer rounded-md text-gray-400 bg-gray-100 hover:text-blue-400"
                                (click)="showDialog(test)"></hero-icon>
                  <hero-icon name="trash" tooltipPosition="top" pTooltip="Delete" hostDisplay="inlineBlock"
                                type="outline"
                                class="p-2 w-8 h-8 ml-2 cursor-pointer rounded-md text-gray-400 bg-gray-100 hover:text-red-400"
                                (click)="deleteScheduled(test)"></hero-icon>
              </td>
          </tr>
      </ng-template>
  </p-table>
</ng-container>
<ng-template #noData>
    
</ng-template>


  
  <!--
       <app-execute-history></app-execute-history> -->
       <p-dialog [(visible)]="displayDialog" [style]="{width: '550px'}" header="Edit Test" [modal]="true"
styleClass="p-fluid">
    <ng-template pTemplate="content">
        <app-schedule-form [isEdit]="true" [updateTest]="selectedTest" (formValues)="getSchedularInfo($event)"></app-schedule-form>
        <div class="p-f-right">
            <button pButton pRipple type="button" (click)="update()" label="Update" icon="pi pi-plus-pencil"></button>
        </div>
    </ng-template>
    
</p-dialog>
</div>