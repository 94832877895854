import {Injectable} from "@angular/core";
import { environment } from "src/environments/environment";

var SockJs = require("sockjs-client");
var Stomp = require("stompjs");

@Injectable()
export class WebSocketService {

    stompClient!: any;
    disabled = true;
    // Open connection with the back-end socket
    public connect() {
      console.log(`${environment.api.socketUrl}?access_token=`+localStorage.getItem('token') );
      
        let socket = new WebSocket(`${environment.api.socketUrl}?access_token=`+localStorage.getItem('token') );
        // let socket$ = new SockJs(`${environment.api.socketUrl}?access_token=`+localStorage.getItem('token') );

        this.stompClient = Stomp.over(socket);

        return this.stompClient;
    }


    disconnect() {
        if (this.stompClient != null) {
          this.stompClient.disconnect();
        }
    
        this.setConnected(false);
        console.log('Disconnected!');
      }

      setConnected(connected: boolean) {
        this.disabled = !connected;
      }
}