import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/core/generic-operations/operations/responce.interface';
import { environment } from '../../../environments/environment';
import { CrudService } from '../../core/generic-operations/services/crud-service';
import { Settings } from '../../modules/execute/schedule-test';
import { GTPVariables } from '../gtp-variables';


@Injectable({
    providedIn: 'root'
})
export class VariablesService extends CrudService<GTPVariables, number> {

    private refreshedVariables$: BehaviorSubject<GTPVariables[]> = new BehaviorSubject(new Array<GTPVariables>());

    constructor(protected http: HttpClient) {
        super(http, `${environment.api.baseUrl}/`, 'application-variable', 'application-variables');
    }

    findByNameApplicationIdScript(varName: string, applicaitonId: number, scriptId: number){
        return this._http.get<IResponse>(`${environment.api.baseUrl}`+'/application-variable/name/' + varName +'/application/'+applicaitonId+'/script/'+scriptId).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }
      
      findByApplicationId(applicaitonId: number){
        return this._http.get<IResponse>(`${environment.api.baseUrl}`+'/application-variables/' + applicaitonId).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }

      getRefreshedVariables(): Observable<GTPVariables[]> {
        return this.refreshedVariables$.asObservable();
      }
  
      setRefreshedVariables(projects: GTPVariables[]) {
          this.refreshedVariables$.next(projects);
      }

}