<p-dialog
    header="{{ heading }}"
    [(visible)]="displayModal"
    [modal]="true"
    (onHide)="close(false)"
    [style]="{ width: '40vw', height: '70vh' }"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
>
    <p-tabView (onChange)="handleTabChange($event)">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <img
                    src="../../../../../assets/icons/svg-icons/desktop.svg"
                    class="h-5 w-5 mr-3"
                    alt=""
                />
                <span>Desktop</span>
            </ng-template>
            <div class="p-fluid p-formgrid">
                <div class="p-field p-mt-2 p-col-12">
                    <label>
                        OS
                        <span class="steric">*</span>
                    </label>
                    <p-dropdown
                        [filter]="true"
                        filterBy="label"
                        [options]="dekstopPlatforms"
                        optionLabel="label"
                        [(ngModel)]="selectedPlatform"
                        placeholder="OS"
                        (ngModelChange)="extractBrowsers()"
                        appendTo="body"
                    >
                        <ng-template pTemplate="selectedItem">
                            <div
                                class="config-dropdown"
                                *ngIf="selectedPlatform"
                            >
                                <img
                                    *ngIf="
                                        selectedPlatform.value.includes(
                                            'windows'
                                        )
                                    "
                                    src="../../../../assets/icons/browsers/windows.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <img
                                    *ngIf="
                                        selectedPlatform.value.includes(
                                            'ios'
                                        ) ||
                                        selectedPlatform.value.includes(
                                            'macos'
                                        ) ||
                                        selectedPlatform.value.startsWith(
                                            'os x'
                                        ) ||
                                        selectedPlatform.label.includes(
                                            'iPhone'
                                        ) ||
                                            selectedPlatform.label.includes(
                                                'iPad'
                                            ) ||
                                            selectedPlatform.label.includes(
                                                'iPod'
                                            )
                                    "
                                    src="../../../../assets/icons/browsers/macintosh.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <img
                                    *ngIf="
                                        selectedPlatform.label.includes(
                                            'Android'
                                        ) ||
                                        !(
                                            selectedPlatform.label.includes(
                                                'iPhone'
                                            ) ||
                                            selectedPlatform.value.includes(
                                                'macos'
                                            ) ||
                                            selectedPlatform.value.startsWith(
                                                'os x'
                                            ) ||
                                            selectedPlatform.value.includes(
                                                'ios'
                                            ) ||
                                            selectedPlatform.label.includes(
                                                'iPad'
                                            ) ||
                                            selectedPlatform.label.includes(
                                                'iPod'
                                            ) ||
                                            selectedPlatform.label.includes(
                                                'Android'
                                            ) ||
                                            selectedPlatform.value.includes(
                                                'windows'
                                            )
                                        )
                                    "
                                    src="../../../../assets/icons/browsers/Android.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <div>{{ selectedPlatform.label }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-os pTemplate="os">
                            <div class="config-dropdown">
                                <img
                                    *ngIf="os.value.includes('windows')"
                                    src="../../../../assets/icons/browsers/windows.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <img
                                    *ngIf="
                                        os.value.includes('ios') ||
                                        os.value.includes('macos') ||
                                        os.value.startsWith('os x') ||
                                        os.label.includes('iPhone') ||
                                            os.label.includes('iPad') ||
                                            os.label.includes('iPod')
                                    "
                                    src="../../../../assets/icons/browsers/macintosh.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <img
                                    *ngIf="
                                        os.label.includes('Android') ||
                                        !(
                                            os.label.includes('iPhone') ||
                                            os.value.includes('macos') ||
                                            os.value.startsWith('os x') ||
                                            os.value.includes('ios') ||
                                            os.label.includes('iPad') ||
                                            os.label.includes('iPod') ||
                                            os.label.includes('Android') ||
                                            os.value.includes('windows')
                                        )
                                    "
                                    src="../../../../assets/icons/browsers/Android.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <div>{{ os.label }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="p-field p-mt-2 p-col-12">
                    <label>
                        Resolution
                        <span class="steric">*</span>
                    </label>
                    <p-dropdown
                        [filter]="true"
                        filterBy="label"
                        placeholder="Resolution"
                        [(ngModel)]="selectedResolution"
                        [options]="selectedOS.resolution"
                        optionValue="value"
                        [disabled]="selectedOS.resolution.length === 0"
                    >
                    </p-dropdown>
                </div>

                <div class="p-field p-mt-2 p-col-12">
                    <label>
                        Browser Name
                        <span class="steric">*</span>
                    </label>
                    <p-dropdown
                        filterBy="label"
                        [options]="browserNamesItems"
                        optionLabel="label"
                        optionValue="value"
                        appendTo="body"
                        placeholder="Browser Name"
                        [(ngModel)]="selectedBrowserName"
                        [disabled]="!browserNamesItems"
                        (ngModelChange)="extractBrowserVersions()"
                    >
                        <ng-template pTemplate="selectedItem">
                            <div
                                class="config-dropdown"
                                *ngIf="selectedBrowserName"
                            >
                                <img
                                    src="../../../../assets/icons/browsers/{{
                                        selectedBrowserName
                                    }}.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <div>{{ selectedBrowserName }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-browser pTemplate="item">
                            <div class="config-dropdown">
                                <img
                                    src="../../../../assets/icons/browsers/{{
                                        browser.label
                                    }}.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <div>{{ browser.label }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="p-field p-mt-2 p-col-12">
                    <label>
                        Browser Version
                        <span class="steric">*</span>
                    </label>
                    <p-dropdown
                        appendTo="body"
                        [filter]="true"
                        filterBy="label"
                        [options]="browserVersionsItems"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Browser Version"
                        [(ngModel)]="selectedBrowserVersion"
                        [disabled]="
                            !browserVersionsItems ||
                            selectedOS.deviceType === 'mobile'
                        "
                        (ngModelChange)="extractBrowserVersions()"
                    ></p-dropdown>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <img
                src="../../../../../assets/icons/svg-icons/mobile.svg"
                class="h-5 w-5 mr-3"
                alt=""
            />
            <span>Mobile</span>
        </ng-template>
            <div class="p-fluid p-formgrid">
                <div class="p-field p-mt-2 p-col-12">
                    <label>
                        Device
                        <span class="steric">*</span>
                    </label>
                    <p-dropdown
                        [filter]="true"
                        filterBy="label"
                        [options]="groupedPlatforms"
                        optionLabel="label"
                        [group]="true"
                        appendTo="body"
                        [(ngModel)]="selectedPlatform"
                        placeholder="Device"
                        (ngModelChange)="extractBrowsers()"
                    >
                        <ng-template pTemplate="selectedItem">
                            <div
                                class="config-dropdown"
                                *ngIf="selectedPlatform"
                            >
                                <img
                                    *ngIf="
                                        selectedPlatform.value.includes(
                                            'windows'
                                        )
                                    "
                                    src="../../../../assets/icons/browsers/windows.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <img
                                    *ngIf="
                                        selectedPlatform.value.includes(
                                            'ios'
                                        ) ||
                                        selectedPlatform.value.includes(
                                            'macos'
                                        ) ||
                                        selectedPlatform.value.startsWith(
                                            'os x'
                                        ) ||
                                        selectedPlatform.label.includes(
                                            'iPhone'
                                        ) ||
                                            selectedPlatform.label.includes(
                                                'iPad'
                                            ) ||
                                            selectedPlatform.label.includes(
                                                'iPod'
                                            )
                                    "
                                    src="../../../../assets/icons/browsers/macintosh.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <img
                                    *ngIf="
                                        selectedPlatform.label.includes(
                                            'Android'
                                        ) ||
                                        !(
                                            selectedPlatform.label.includes(
                                                'iPhone'
                                            ) ||
                                            selectedPlatform.value.includes(
                                                'macos'
                                            ) ||
                                            selectedPlatform.value.startsWith(
                                                'os x'
                                            ) ||
                                            selectedPlatform.value.includes(
                                                'ios'
                                            ) ||
                                            selectedPlatform.label.includes(
                                                'iPad'
                                            ) ||
                                            selectedPlatform.label.includes(
                                                'iPod'
                                            ) ||
                                            selectedPlatform.label.includes(
                                                'Android'
                                            ) ||
                                            selectedPlatform.value.includes(
                                                'windows'
                                            )
                                        )
                                    "
                                    src="../../../../assets/icons/browsers/Android.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <div>{{ selectedPlatform.label }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-os pTemplate="os">
                            <div class="config-dropdown">
                                <img
                                    *ngIf="os.value.includes('windows')"
                                    src="../../../../assets/icons/browsers/windows.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <img
                                    *ngIf="
                                        os.value.includes('ios') ||
                                        os.value.includes('macos') ||
                                        os.value.startsWith('os x') ||
                                        os.label.includes('iPhone') ||
                                            os.label.includes('iPad') ||
                                            os.label.includes('iPod')
                                    "
                                    src="../../../../assets/icons/browsers/macintosh.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <img
                                    *ngIf="
                                        os.label.includes('Android') ||
                                        !(
                                            os.label.includes('iPhone') ||
                                            os.value.includes('macos') ||
                                            os.value.startsWith('os x') ||
                                            os.value.includes('ios') ||
                                            os.label.includes('iPad') ||
                                            os.label.includes('iPod') ||
                                            os.label.includes('Android') ||
                                            os.value.includes('windows')
                                        )
                                    "
                                    src="../../../../assets/icons/browsers/Android.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <div>{{ os.label }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="p-field p-mt-2 p-col-12">
                    <label>
                        OS Version
                        <span class="steric">*</span>
                    </label>
                    <p-dropdown
                        [filter]="true"
                        filterBy="label"
                        placeholder="OS Version"
                        [(ngModel)]="selectedResolution"
                        appendTo="body"
                        [options]="selectedOS.resolution"
                        optionValue="value"
                        [disabled]="selectedOS.resolution.length === 0"
                    >
                    </p-dropdown>
                </div>
                <div class="p-field p-mt-2 p-col-12" [hidden]="true">
                    <label>
                        Browser Name
                        <span class="steric">*</span>
                    </label>
                    <p-dropdown
                        filterBy="label"
                        [options]="browserNamesItems"
                        optionLabel="label"
                        optionValue="value"
                        appendTo="body"
                        placeholder="Browser Name"
                        [(ngModel)]="selectedBrowserName"
                        [disabled]="!browserNamesItems"
                        (ngModelChange)="extractBrowserVersions()"
                    >
                        <ng-template pTemplate="selectedItem">
                            <div
                                class="config-dropdown"
                                *ngIf="selectedBrowserName"
                            >
                                <img
                                    src="../../../../assets/icons/browsers/{{
                                        selectedBrowserName
                                    }}.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <div>{{ selectedBrowserName }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-browser pTemplate="item">
                            <div class="config-dropdown">
                                <img
                                    src="../../../../assets/icons/browsers/{{
                                        browser.label
                                    }}.svg"
                                    class="h-5 w-5 mr-2"
                                />
                                <div>{{ browser.label }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="p-field p-mt-2 p-col-12" [hidden]="true">
                    <label>
                        Browser Version
                        <span class="steric">*</span>
                    </label>
                    <p-dropdown
                        appendTo="body"
                        [filter]="true"
                        filterBy="label"
                        [options]="browserVersionsItems"
                        appendTo="body"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Browser Version"
                        [(ngModel)]="selectedBrowserVersion"
                        [disabled]="
                            !browserVersionsItems ||
                            selectedOS.deviceType === 'mobile'
                        "
                        (ngModelChange)="extractBrowserVersions()"
                    ></p-dropdown>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
    <ng-template pTemplate="footer">
        <button
            pButton
            pRipple
            type="button"
            class="cancel-btn p-button-outlined p-button p-component"
            label="Cancel"
            icon="pi pi-times"
            (click)="close(false)"
        ></button>
        <button
            *ngIf="!edit"
            pButton
            pRipple
            type="button"
            label="Save Environment"
            icon="pi pi-check"
            [disabled]="
                !selectedBrowserVersion ||
                !browserNamesItems ||
                !selectedOS
            "
            (click)="save()"
        ></button>
    </ng-template>
</p-dialog>
