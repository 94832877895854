<div *ngIf="isScreenLoading">
  <p-progressSpinner></p-progressSpinner>
  <div class="progressspinner-overlay"></div>
</div>
<div class="topbar clearfix">
  <div class="p-f-left projects">

		<!-- Parallel Sessions: (Max.: {{organizationConcurrency.maxConcurrency}}, Available: {{organizationConcurrency.maxConcurrency-organizationConcurrency.running}}, Running: {{organizationConcurrency.running}}) -->
		<!-- <ng-container *ngIf="projects.length > 0; else noProjects">
			<p-dropdown
			  [options]="projects"
			  [filter]="true"
			  filterBy="applicationName"
			  placeholder="Select a Project"
			  [(ngModel)]="selectedProject"
			  optionLabel="applicationName"
			  (onChange)="changeProject()"
			  class="selectProject"
        [virtualScroll]="true" [virtualScrollItemSize]="10"
			></p-dropdown>
		  </ng-container>
		  <ng-template #noProjects
			><div class="noProject p-f-left">No Projects</div></ng-template
		  > -->
      <div *ngIf="projects.length > 0" class="no-project-wrapper">
        <span class="noProject text-green-500 font-bold"></span>
        <ng-container *ngIf="selectedProject; else noProject">
          <span class="text-gray-500 font-bold" >{{selectedProject.applicationName}}</span>
        </ng-container>
        <ng-template #noProject>
          <span class="text-gray-500 font-bold" >No Selected Project</span>
        </ng-template>
        
        
        
      </div>

      <!-- <p-dropdown [options]="lazyItems" [(ngModel)]="selectedItem2" placeholder="Select Item" 
                  [virtualScroll]="true" [virtualScrollItemSize]="10" [lazy]="true" 
                  (onLazyLoad)="onLazyLoad($event)" 
                  [virtualScrollOptions]="{ showLoader: true, loading: loading, delay: 250 }">
        <ng-template pTemplate="loader" let-options="options">
            <div class="flex align-items-center py-2 px-3" style="height: 38px">
                <p-skeleton [width]="options.even ? '60%' : '50%'" height="1rem"></p-skeleton>
            </div>
        </ng-template>
    </p-dropdown> -->
	</div>
  <ng-container *ngIf="skeleton; else elseTemplate">
    <p-skeleton
      width="21rem"
      height="2rem"
      styleClass="p-mb-2 p-mt-3 p-mr-4 p-f-right"
      borderRadius="16px"
    ></p-skeleton>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="topbar-right">
      
      <ul class="topbar-items">
        <li>
          <div class="p-d-flex userinfo">
            <i _ngcontent-emq-c91="" class="pi pi-user"></i>
            <button
              pButton
              pRippl
              type="button"
              class="p-button-secondary p-button-text custom-button"
              icon="pi pi-angle-down"
              [label]="loggedInUser"
              (click)="Profile.toggle($event)"
            ></button>
          </div>
          <p-tieredMenu
            #Profile
            [model]="itemsProfile"
            [popup]="true"
          ></p-tieredMenu>
        </li>
        <li>
          <!-- <a href="#">
					<i class="topbar-icon pi pi-fw pi-bell"></i>
					<span class="topbar-badge animated rubberBand">4</span>
					<span class="topbar-item-name">Notifications</span>
					</a> -->
        </li>
      </ul>
    </div>
  </ng-template>
</div>
