
export const environment = {
    production: false,
    environmentName: 'DEV',
    api: {
        baseUrl: 'http://dev-gtp.royalcyber.org:8081/rcteaf-api/api',
        okta_URL: 'http://dev-gtp.royalcyber.org:8081/rcteaf-api/oauth2/authorization/okta',
        lambda_test_automation_api: 'https://api.lambdatest.com/automation/api/v1/',
        lambda_test_screenshots_api: 'https://api.lambdatest.com/screenshots/v1/',    
        publicUrl: 'http://dev-gtp.royalcyber.org:8081/rcteaf-api/api/public',
        socketUrl: 'ws://dev-gtp.royalcyber.org:8081/rcteaf-api/api/socket',
        wsreconnectInterval: 2000,
        genai_url: 'https://genai.gotestpro.com/api/ai-report'
      },
    //openAIToken:'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'  amg
    openAIToken:'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'

  };