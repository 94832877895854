import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ManageUser } from '../modules/manageuser/manage-user'
import { Role } from '../modules/manageuser/role'
import { RoleEnum } from '../core/models/enum'
import { Organization } from '../modules/organizations/organization'
import { User } from '../modules/manageuser/user'
import { UserService } from '../modules/manageuser/services/user.service'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styles: [],
})
export class SignupComponent implements OnInit {
  form!: FormGroup
  isSubmit: boolean = false
  public isRemember: boolean = true
  public emailRegEx = '^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$'
  @Output() 
  @Output() closeModal = new EventEmitter<Boolean>()

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private messageService: MessageService,
    private router: Router
  ) {
    
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailRegEx)])],
      name: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
    })
  }


  onSubmit() {
    if (this.form.invalid) {
      return
    }
    this.isSubmit = true
    const email = this.form.controls.email.value
    const name = this.form.controls.name.value
    const lastName = this.form.controls.lastName.value
    const payLoad: ManageUser = new ManageUser();
    const role: Role = new Role();
    const user: User = new User();
    role.roleId = 2;
    role.roleName = RoleEnum.TestManager;

    // org.isActive = "1";
    // org.organizationId = 0;
    // org.organizationName = organization;
    
    user.email = email;
    user.name = name;
    user.lastName = lastName;
    user.createdBy = 0;
    user.rcTeafRole = role;
    user.permissions = JSON.stringify({
      read: true,
      write: true,
    });
    payLoad.applications = [];
    // payLoad.organization = org;
    payLoad.role = role;
    payLoad.user = user;
    
    this.userService.signup(payLoad).subscribe(
      (data) => {
        this.isSubmit = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: "Signup successful, Please check your email for the instructions",
          life: 6000,
        })
        this.closeModal.emit(false);
        this.router.navigate(['/login']);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Message',
          detail: error.error.apierror.debugMessage,
          life: 3000,
        })
        this.isSubmit = false
      }
    )
  }
  
}
