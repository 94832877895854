<!-- <p-toast></p-toast> -->
<div *ngIf="isScreenLoading">
    <p-progressSpinner></p-progressSpinner>
    <div class="progressspinner-overlay"></div>
</div>
<div class="p-d-flex p-jc-center login h-full items-center" *ngIf="!isLoggedIn">
    <div class="p-col-12 p-d-flex p-jc-center main-box items-center">
        <div class="p-col-5 login-bg"></div>
        <div
            class="p-col-12 p-sm-12 p-md-4 p-lg-4 login-form rounded-xl p-10 bg-white"
        >
            <div class="p-m-3">
                <div class="p-d-flex p-jc-center">
                    <div class="logo-login flex items-center">
                        <img src="assets/gtp-icon.svg" alt="GoTestPro" />
                        <img src="assets/rc-teaf-logo.svg" alt="GoTestPro" />
                    </div>
                </div>
                <form
                    name="form"
                    (ngSubmit)="onSubmit()"
                    [formGroup]="form"
                    novalidate
                >
                    <div class="p-fluid">
                        <div class="p-field">
                            <p-dropdown pTooltip="Select region" tooltipPosition="top" [(ngModel)]="selectedRegion" [ngModelOptions]="{standalone: true}" [options]="regions" formControlName="selectedRegion"  optionLabel="name" optionValue="value" (onChange)="changeRegion()"></p-dropdown>
                        </div>
                        <div class="p-field">
                            <input
                                name="email"
                                required
                                formControlName="email"
                                pInputText
                                placeholder="Email"
                            />

                            <!-- <p-message severity="error" text="Field is required" *ngIf="email.errors && f.submitted"></p-message> -->
                        </div>
                        <div class="p-field flex relative">
                            <input
                            *ngIf="!isPasswordVisible"
                                name="password"
                                required
                                formControlName="password"
                                type="password"
                                pInputText
                                placeholder="Password"
                            />
                            <input
                            *ngIf="isPasswordVisible"
                            name="password"
                            required
                            formControlName="password"
                            type="text"
                            pInputText
                            placeholder="Password"
                        />
                            <hero-icon name="eye"
                            *ngIf="!isPasswordVisible"
                            hostDisplay="inlineBlock" type="outline"
                            class=" absolute right-2 top-1.5 p-2 w-10 h-10 ml-2 cursor-pointer rounded-md text-gray-400"
                            (click)="showPassword(false)"
                           ></hero-icon>
                           <hero-icon name="eye-off"
                           *ngIf="isPasswordVisible"
                            hostDisplay="inlineBlock" type="outline"
                            class=" absolute right-2 top-1.5 p-2 w-10 h-10 ml-2 cursor-pointer rounded-md text-gray-400"
                            (click)="showPassword(true)"
                           ></hero-icon>
                            <!-- <p-message severity="error" text="Field is required" *ngIf="password.errors && f.submitted"></p-message> -->
                        </div>
                        <div class="p-d-flex p-jc-between p-pb-3">
                            <!-- <p-checkbox
                name="remember-me"
                value="val1"
                label="Remember me"
                [(ngModel)]="isRemember"
                [ngModelOptions]="{ standalone: true }"
                [binary]="true"
                inputId="binary"
              ></p-checkbox> -->
                            <div class="p-text-right">
                                <a
                                    href="#"
                                    routerLink="/forgotpassword"
                                    class="forgot"
                                    >Forgot Password?</a
                                >
                            </div>
                        </div>
                        <!-- <div class="p-col-5" style="padding-left: 0rem">
              <button
                pButton
                class="login-btn p-button p-component"
                type="submit"
                label="Login"
                [disabled]="!form.valid"
              ></button>
            </div> -->
                        <div class="p-col-12">
                            <div class="flex items-center justify-between">
                                <button
                                    pButton
                                    class="p-button p-component"
                                    type="submit"
                                    label="Sign In"
                                    [disabled]="!form.valid"
                                ></button>
                                <!-- <a
                                    (click)="loginWithOkta()"
                                    class="p-button p-component"
                                    >Login With Okta</a>
                                 -->
                                 <button
                                        pButton
                                        class="p-button p-component"
                                        type="button"
                                        label="Sign In With SSO"
                                        (click)="showSelectOrgDialog()"
                                    ></button> 
                             
                                <!-- <a (click)="showSelectOrgDialog()" href="#">No Account ? Sign up</a> -->
                                <a routerLink="/signup">No Account ? Sign up</a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="displayDialog" [style]="{width: '550px'}" header="Select Organization" [modal]="true"
    styleClass="p-fluid">
        <ng-template pTemplate="content">
            <input
            name="organization"
            required
            [(ngModel)]="organization"
            type="text"
            pInputText
            placeholder="Type organization name"
        />
            <!-- <p-dropdown pTooltip="Select Organization" tooltipPosition="top" [(ngModel)]="selectedOrganization" [ngModelOptions]="{standalone: true}" [options]="organizations" optionLabel="name" optionValue="value"></p-dropdown> -->
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="p-d-flex p-jc-end">
                <button pButton pRipple type="button" 
                [disabled]="!organization" (click)="loginWithOkta()" label="Submit" icon="pi pi-plus-pencil"></button>
            </div>
            </ng-template>
        
    </p-dialog>
</div>
