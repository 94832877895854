import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-total-test-plans',
  templateUrl: '../graph/total-test-plans.component.html',
  styles: [],
})
export class TotalTestPlansComponent implements OnInit {
  public _scriptStatistics: any = null;
  _message: string = "";
  @Input() set message(value: string) {
    this._message = value
  }
  _count: number = 0;
  @Input() set count(value: number) {
    this._count = value
  }
  @Input()
  set scriptStatistics(data: any) {
    if (data) {
      this._scriptStatistics = data
      this.chartData = [
        [
          this._scriptStatistics.scenarioPassed,
          this._scriptStatistics.scenarioFailed,
          0,
        ],
      ]
    }
  }
  chartLabels = ['Total Script', 'Available']
  chartData = [[120, 40]]
  chartColors = [
    {
      backgroundColor: [
        'rgb(163, 161, 251)',
        'rgb(237, 236, 254)',
      ],
    },
  ]
  chartoptions = {
    cutoutPercentage: 50,
    plugins: {
      legend: {
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 22,
          },
        },
      },
    },
  }
  ngOnInit(): void {

  }
}
