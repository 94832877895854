import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { map, retry, share, switchMap, takeUntil, tap, takeWhile } from 'rxjs/operators';
import { IResponse, IResponsePaginated, IResponsePaginatedTests } from 'src/app/core/generic-operations/operations/responce.interface';
import { environment } from '../../../../environments/environment';
import { RunTest } from '../run-test';

@Injectable({providedIn:'root'})
export class ExecuteService implements OnDestroy {

  private stopPolling = new Subject();

    constructor(private http: HttpClient) { 
        
    }

    
      applciationExecutions(applicationId: number, rowsPerPage: number, startIndex: number): Observable<IResponsePaginatedTests> {
        return this.http.get<IResponsePaginatedTests>(`${environment.api.baseUrl}/executions/` + applicationId + '/rpp/'+rowsPerPage+'/si/'+startIndex).pipe(
          map((o:IResponsePaginatedTests): IResponsePaginatedTests => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }  

      applciationExecutionsWithSelectedTags(applicationId: number, rowsPerPage: number, startIndex: number, tags: Array<number>): Observable<IResponsePaginated> {
        return this.http.get<IResponsePaginated>(`${environment.api.baseUrl}/executions/` + applicationId + '/rpp/'+rowsPerPage+'/si/'+startIndex+'/tags/'+tags).pipe(
          map((o:IResponsePaginated): IResponsePaginated => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }  


     

      executeParallelNow(testData: RunTest): Observable<IResponse> {
        return this.http.post<IResponse>(`${environment.api.baseUrl}/execution/parallel`, JSON.stringify(testData)).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }  

      executeNow(testData: RunTest): Observable<IResponse> {
        return this.http.post<IResponse>(`${environment.api.baseUrl}/execution/`, JSON.stringify(testData)).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }  

      runHistories(userId: number, applicationId: number): Observable<IResponse> {
        return this.http.get<IResponse>(`${environment.api.baseUrl}/runHistory/`+applicationId+'/'+userId).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }
      
      runHistoryList(data: any): Observable<IResponsePaginatedTests> {
        return this.http.post<IResponsePaginatedTests>(`${environment.api.baseUrl}/`+'runHistories', data).pipe(
          map((o:IResponsePaginatedTests): IResponsePaginatedTests => ( { 
              code: o.code, 
              message: o.message,
              result: o.result
          })));
      }

      sessionDetails(endPoint: string){
        return this.http.get(`${endPoint}sessions/`);
      }

      pollSessions(endPoint: string){
        return timer(0, 10000).pipe(
          switchMap((s : any) => this.http.get(`${endPoint}sessions/`)),
          map((s: any) => {
            console.log(s);
            
          }),          
          retry(),
          tap(),
          share(),
          takeUntil(this.stopPolling));
          
        
      } 

      // findPaginated(id : number, userId:number, rowsPerPage: number, startIndex: number, tabIndex: number){
      //   return this.http.get<IResponsePaginated>(`${environment.api.baseUrl}/`+'runHistory/' + id +'/'+userId+'/'+tabIndex+'/rpp/'+rowsPerPage+'/si/'+startIndex).pipe(
      //     map((o:IResponsePaginated): IResponsePaginated => ({ 
      //         code: o.code, 
      //         message: o.message,
      //         result: o.result 
      //     }))
      //     );
      // }

      findPaginated(data: any){
        return timer(0, 10000).pipe(
          switchMap(() => this.http.post<IResponsePaginatedTests>(`${environment.api.baseUrl}/`+'runHistories', data)),
          retry(),
          tap(),
          share(),
          takeUntil(this.stopPolling)
        );
      } 
      
  
      findFiltered(id : number,userId:number, term: string){
        return this.http.get<IResponse>(`${environment.api.baseUrl}/`+'runHistory/' + id +'/'+userId +'/'+term).pipe(
          map((o:IResponse): IResponse => ({ 
              code: o.code, 
              message: o.message,
              result: o.result 
          }))
          );
      }  

      // pollingRunHistories(id : number, userId:number, rowsPerPage: number, startIndex: number, tabIndex: number): Observable<IResponsePaginated> {
      //   return this.findPaginated(id, userId, rowsPerPage, startIndex, tabIndex).pipe(
      //       map((o:IResponsePaginated): IResponsePaginated => ({ 
      //         code: o.code, 
      //         message: o.message,
      //         result: o.result 
      //     })),   
      //     tap(() => console.log('data sent to subscriber'))
      //   );
      // }

      findVideoUrl(sessionId: string, endPoint: string) {
        return this.http.get<any>(`${endPoint}/` + sessionId + '/video');
      }

      retrieveTagScripts(request: any): Observable<IResponse> {
        return this.http.post<IResponse>(`${environment.api.baseUrl}/test-plan-execution`, request).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }

      executeScriptSequence(data:any[]): Observable<IResponse> {
        return this.http.post<IResponse>(`${environment.api.baseUrl}/execution/tag/sequence`, data).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }

      resultId(): Observable<IResponse> {
        return this.http.get<IResponse>(`${environment.api.baseUrl}/execution/resultId`).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }

      executeFilter(params: any): Observable<IResponse> {
        return this.http.post<IResponse>(`${environment.api.baseUrl}/execution/filter`, params).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }  


      

      
      ngOnDestroy(): void {
        this.stopPolling.next(null);
      }
}


