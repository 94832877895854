<div *ngIf="isScreenLoading">
  <p-progressSpinner></p-progressSpinner>
  <div class="progressspinner-overlay"></div>
</div>
<div class="p-d-flex p-jc-center" *ngIf="!isLinkExpired">
  <div class="p-col-12 p-d-flex p-jc-center main-box">
    <div class="p-col-5 login-bg"></div>
    <div
      class="p-col-12 p-sm-12 p-md-5 p-lg-3"
      style="padding-left: 0rem; padding-bottom: 0rem"
    >
      <div class="p-m-6">
        <div class="p-d-flex p-jc-center">
          <div class="logo-login">
            <img src="assets/gtp-icon.svg" alt="GoTestPro" />
            <img src="assets/rc-teaf-logo.svg" alt="GoTestPro"/>
          </div>
        </div>
        <!-- <h6 class="p-text-center">Please Reset your Password</h6> -->
        <div class="p-fluid">
          <div class="p-field">
            <input
              name="newpassword"
              type="password"
              pInputText
              placeholder="New Password"
              [(ngModel)]="newpassword"
            />
          </div>
          <div class="p-field">
            <input
              name="password"
              type="password"
              pInputText
              [(ngModel)]="confirmpassword"
              placeholder="Confirm Password"
            />
          </div>
          <div class="p-col-12" style="padding-left: 0rem">
            <button
              [disabled]="!confirmpassword || !newpassword"
              pButton
              class="login-btn"
              type="submit"
              label="Submit"
              (click)="submitHandler()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>