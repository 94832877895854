
import { Base } from "src/app/core/models/base.model";
import { GTPVariables } from "src/app/variables/gtp-variables";
import { User } from "../../manageuser/user";
import { Project } from "../../projects/project";
import { Scenario } from "./scenario";
import { Tags } from "./tags";
import { EnterpriseChild } from "../../projects/enterprise-application";

export class Script extends Base {
  
  scriptId!: number;
  scriptName!: string;
  scriptDescription!: string;
  rcTeafApplication!: Project;
  rcTeafUser!: User;
  tags: Tags[] = new Array<Tags>();
  rcTeafScenarios: Scenario[] = new Array<Scenario>();
  isRecorded!: string;
  variables!: GTPVariables[];
  testType!: number;
  jiraKey!: string;
  allScriptScenarios?: Scenario[];
  selected!: boolean;
  createdBy?: number;
  enterpriseSubApplication?: EnterpriseChild;
  isSystem?: string;
}


export class ScriptDetail extends Base{
  scriptId!: number;
  scriptName!: string;
  scriptDescription!: string;
  isRecorded!: string;
  testType!: number;
  jiraKey!: string;
  selected!: boolean;
  enterpriseSubApplication?: EnterpriseChild;
  isSystem?: string;
  rcTeafApplication!: Project;
}
