import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable, Observer } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { UserStorageService } from './user-storage.service';

@Injectable({
    providedIn: "root"
  })
export class RouteLinkGuard implements CanActivate {
  constructor(private router: Router, private confirmationService: ConfirmationService, private userStorageService: UserStorageService) {}

  canActivate() {
// Here you can use whatever logic you want 
    if (this.userStorageService.getUserSelectedProject().applicationId === 0) {
        return false;
    } else {
      return true;
    }
  }
}