<div class="p-d-flex p-jc-center">
  <div class="p-col-10 p-d-flex p-jc-center main-box">
    <div
      class="p-col-12 p-sm-12 p-md-8 p-lg-5"
      style="padding-left: 0rem; padding-bottom: 0rem"
    >
      <p-card>
        <div class="p-d-flex p-jc-center">
          <div class="logo-login">
            <img src="assets/gtp-icon.svg" alt="GoTestPro" />
            <img src="assets/rc-teaf-logo.svg" alt="GoTestPro"/>
          </div>
        </div>
        <h5 class="p-text-center">
          Application is under Maintanance.
        </h5>
        <div class="p-col-12 p-text-center">
          <a routerLink="/login"
            class="p-button error-btn p-mt-3" 
          >Back to Login</a>
        </div>
      </p-card>
    </div>
  </div>
</div>
