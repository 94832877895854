import { Base } from 'src/app/core/models/base.model';

export class TestEvironmentBrowerDetails extends Base {
  id!: string;
  browserDetailsId!: number;
  testConfigEnvironmentsId!: number;
  browserName!: string;
  browserVersion!: string;
  testConfigId!: string;
  deviceName!: string;
  capability1?: string;
  capability2?: string;
  constructor(
    id?: string,
    browserDetailsId?: number,
    testConfigEnvironmentsId?: number,
    browserName?: string,
    browserVersion?: string,
    testConfigId?: string
  );
  constructor(
    id?: string,
    browserDetailsId?: number,
    testConfigEnvironmentsId?: number,
    browserName?: string,
    browserVersion?: string,
    testConfigId?: string
  ) {
    super();
    this.id = id || '';
    this.browserDetailsId = browserDetailsId || 0;
    this.testConfigEnvironmentsId = testConfigEnvironmentsId || 0;
    this.browserName = browserName || '';
    this.browserVersion = browserVersion || '';
  }
}
