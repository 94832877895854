import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Organization } from '../organization';
import { environment } from '../../../../environments/environment';
import { CrudService } from '../../../core/generic-operations/services/crud-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IResponse } from 'src/app/core/generic-operations/operations/responce.interface';
import { map } from 'rxjs/operators';
import { OrganizationConcurrency } from 'src/app/core/models/organization-concurrency';

@Injectable({
    providedIn:'root'
})
export class OrganizationService extends CrudService<Organization, number>{

  private concurrency$: BehaviorSubject<OrganizationConcurrency> = new BehaviorSubject(new OrganizationConcurrency());

    constructor(protected http: HttpClient) {
        super(http, `${environment.api.baseUrl}/`, 'organization', 'organizations');
      }

      findActives(): Observable<IResponse> {
        return this._http.get<IResponse>(`${environment.api.baseUrl}/organizations/actives`).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      } 

      checkDuplicate(name: string): Observable<IResponse> {
        return this._http.get<IResponse>(`${environment.api.baseUrl}/organization/name/`+name).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      } 

      organizationConcurrency(endPoint: string) : Observable<OrganizationConcurrency>{
        return this.http.get(`${endPoint}`).pipe(
          map((o: any): OrganizationConcurrency => ( { 
              maxConcurrency: o.data.max_concurrency, 
              maxQueue: o.data.max_queue,
              pQueued: o.data.pqueued,
              queued:o.data.queued,
              created: o.data.created,
              running: o.data.running
          })));
      }

      getConcurrency(endPoint: string): Observable<OrganizationConcurrency> {
        return this.organizationConcurrency(`${endPoint}`);
      }
  
      setConcurrency(concurrency: OrganizationConcurrency) {
          this.concurrency$.next(concurrency);
      }

      

}