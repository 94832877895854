import { ApplicationRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { SwUpdate } from '@angular/service-worker';
import { Message, MessageService } from 'primeng/api';
import { interval, Observable, Subscription } from 'rxjs';
import { FREE_TRAIL_MESSAGE } from './constants';
import { MasterDataService } from './core/common/services/master-data.service';
import { AuthenticationService } from './core/_helpers/_services/authentication.service';
import { UserStorageService } from './core/_helpers/_services/user-storage.service';
import { WebSocketService } from './core/_helpers/_services/web-socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
,
  styles: []
})

export class AppComponent implements OnInit {
  auth$! : Subscription; 
  title = 'rc-teaf';
  isUserLoggedIn!: boolean;
  freeTrailMessages: Message[] = [];
  isHide: boolean = true;
  notifications: any;
  
    currentUser: any;
  
    constructor(private token: UserStorageService, private router: Router,
      private authenticationService: AuthenticationService, 
      private messageService: MessageService, private masterDataService: MasterDataService, private websocketService: WebSocketService ) {
        // this.updates.checkForUpdates();
        this.isUserLoggedIn = this.authenticationService.isUserLoggedIn();
        this.websocketService.connect();
        
        
      }

      
  
    ngOnInit(): void {
     
      
      this.authenticationService.getCurrentError().subscribe((error: any) => {
        this.messageService.add({severity:'error', summary: 'error', detail: error, life: 3000});            
      })
      this.auth$ = this.authenticationService.authenticationResult.subscribe((res) => {
        if (res) {
          this.isHide = true
        setTimeout(() => {
          this.isFreeTrail();
        }, 2000);
          this.isUserLoggedIn = true;
        }
        else {
          this.isUserLoggedIn = false;
        }
      });
    }

    isFreeTrail()  {
      this.masterDataService.getShowFreeTrailMessage().subscribe(res => {
        console.log("Listened Changed " + res);
        
        if(res){
          this.isHide = false;
          this.freeTrailMessages = [{
            severity: 'warn', detail:FREE_TRAIL_MESSAGE, life: 10000
          }]
        }else{
          this.isHide = true;
          this.freeTrailMessages = [];
        }
      })
    }

}
