import { Injectable } from '@angular/core';
import { PROJECT_UUID, SUAPP_ID, USER_KEY, USER_SELECTED_APPLICATION } from 'src/app/constants';
import { ManageUser } from 'src/app/modules/manageuser/manage-user';
import { Role } from 'src/app/modules/manageuser/role';
import { Organization } from 'src/app/modules/organizations/organization';
import { Project } from 'src/app/modules/projects/project';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {
  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public getRole() : Role {
    return this.getUser().role;
  }

  getUserSelectedProject():Project{
    let selectedProject: Project;
    const selectedUser : string | null = window.localStorage.getItem(USER_SELECTED_APPLICATION); 
    if(selectedUser != String(undefined)  && selectedUser != null){
      const project = <Project>JSON.parse(window.localStorage.getItem(USER_SELECTED_APPLICATION) || '{}') as Project;;
      selectedProject = new Project();
      if(project !== null){
        selectedProject.applicationId = project.applicationId;
        selectedProject.applicationName = project.applicationName;
        selectedProject.rcTeafApplicationType = project.rcTeafApplicationType;
        selectedProject.rcTeafAppExecEnvs = project.rcTeafAppExecEnvs;
        selectedProject.scriptCount = project.scriptCount;
        // selectedProject.isDefault = project.isDefault;
        selectedProject.isSelected = project.isSelected;
        selectedProject.jiraKey = project.jiraKey;
        selectedProject.jiraName = project.jiraName;
      }
    }else{
      selectedProject = new Project();
    }
    return selectedProject;
  }

  getDefaultProject(): Project{
    return <any>JSON.parse(window.localStorage.getItem(USER_SELECTED_APPLICATION) || '{}') as Project;
  }

  updateUserSelectedProject(project : Project){
    window.localStorage.removeItem(USER_SELECTED_APPLICATION);
    window.localStorage.setItem(USER_SELECTED_APPLICATION, JSON.stringify(project));
  }

  updateUser(user: ManageUser){
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  getSUAPPID(): string {
    return localStorage.getItem(SUAPP_ID) || "";
  }

  getProjectUUID(): string {
    return localStorage.getItem(PROJECT_UUID) || "";
  }
  
}