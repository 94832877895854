<div id="staticlayout" class="layout-wrapper menu-layout-static h-full" >
    <p-toast></p-toast>
	<ng-container *ngIf="isUserLoggedIn">
        <app-header></app-header>
        <div class="layout-menu-container" >
            <div class="menu-scroll-content">            
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </ng-container>
    <div [ngClass]="{'layout-main': isUserLoggedIn}">
        <!-- <p-toast></p-toast> -->
<span  [hidden]="isHide">
    <!-- <p-messages severity="warn">
        <ng-template pTemplate>
            <span class="p-message-icon pi pi-exclamation-triangle"></span>
            <span class="p-message-summary">Warning</span>
            <span class="p-message-detail">Seems like your extention is disabled. Please <a target="_blank" href="https://chrome.google.com/webstore/detail/gtp/ohaeppcmckoalmhpehngkdoolfcpnkaa?hl=en">click here</a> to enable it.</span>
        </ng-template>
    </p-messages> -->
    <!-- <p-messages [(value)]="freeTrailMessages" [enableService]="false"></p-messages> -->
</span>
        <!-- {{freeTrailMessages.length}}
        <div [ngClass]="{'hide': freeTrailMessages.length === 0, 'show': freeTrailMessages.length  > 0  }">
            <p-messages [(value)]="freeTrailMessages" [enableService]="false"></p-messages>
        </div> -->
        <!-- <app-loading-indicator *ngIf="expression"></app-loading-indicator> -->
        <router-outlet></router-outlet>
    </div>
    <ng-container *ngIf="isUserLoggedIn">
        <div id="hidemask" class="layout-mask" onclick="hidemask()"></div>
        <app-footer></app-footer>
    </ng-container>
</div>
<!-- <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog> -->
<!-- <div id="staticlayout" class="layout-wrapper menu-layout-static" >
        <app-header></app-header>
        <div class="layout-menu-container" >
            <div class="menu-scroll-content">            
                <app-sidebar></app-sidebar>
            </div>
        </div>
    <div class="layout-main">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
</div> -->
