<ng-container *ngIf="!isFreeTrail; else elseAppliTemplate">
    <!-- <div *ngIf="isScreenLoading">
    <p-progressSpinner></p-progressSpinner>
    <div class="progressspinner-overlay"></div>
  </div> -->
    <form [formGroup]="configForm" class="config-form" novalidate>
        <div class="p-fluid">
            <div class="p-col-12 parallel-session-bg pt-5 pb-0.5 pl-5 mb-3">
                <div class="p-field p-grid">
                    <strong> Parallel Sessions: </strong>
                    <img src="../../../../assets/icons/svg-icons/max.svg" alt="" class="h-5 w-5 mr-2 ml-3" />
                    <span class="p-ml-1">
                        Max.: {{ concurencyObj?.maxConcurrency }}
                    </span>

                    <img src="../../../../assets/icons/svg-icons/check.svg" alt="" class="h-5 w-5 mr-2 ml-3" />
                    <span class="p-ml-1 sesn-colr-1">
                        Available: {{ concurencyObj.queued }}
                    </span>
                    <img src="../../../../assets/icons/svg-icons/play.svg" alt="" class="h-5 w-5 mr-2 ml-3" />
                    <span class="sesn-colr-2">
                        Running: {{ concurencyObj.running }}
                    </span>
                </div>
            </div>

            <div class="p-field p-grid">
                <label for="envKey" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">Project Environment:<span
                        class="steric-config">*</span></label>

                <div class="p-col-12 p-md-6">
                    <p-dropdown placeholder="Please Select" formControlName="rcTeafAppExecEnv" [options]="appEnvs"
                        optionLabel="envKeyUrl"></p-dropdown>
                </div>
            </div>

            <div class="p-field p-grid">
                <label for="preferredGeoLocation" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">Geo Location:</label>
                <div class="p-col-12 p-md-6">
                    <p-dropdown placeholder="Please Select" [filter]="true" formControlName="preferredGeoLocation"
                        [options]="geoLocations" optionLabel="name" optionValue="code"></p-dropdown>
                </div>
            </div>

            <!-- <div class="p-field p-grid">
        <label for="scriptExecute" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0"
          >Test Run Configuration:</label
        >
        <div class="p-col-12 p-md-6">
          <p-dropdown
            placeholder="Please Select"
            formControlName="testRunEnvironment"
            [options]="testRunEnvironmentItems"
            optionLabel="label"
            optionValue="value"
          ></p-dropdown>
        </div>
      </div> -->
            <!-- <div class="p-field p-grid">
        <label for="platform" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0"
          >Platform:</label
        >
        <div class="p-col-12 p-md-6">
          <p-dropdown
            placeholder="Please Select"
            formControlName="platformChoice"
            [options]="platformItems"
            optionLabel="value"
            optionValue="value"
          ></p-dropdown>
        </div>
      </div> -->
            <!-- <div class="p-field p-grid">
        <label for="type" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0"
          >Application Type:</label
        >
        <div class="p-col-12 p-md-6">
          <p-dropdown
            placeholder="Please Select"
            formControlName="rcTeafApplicationType"
            [options]="applicationTypeItems"
            optionLabel="applicationType"
          ></p-dropdown>
        </div>
      </div> -->
            <!-- <div class="p-field p-grid">
        <label for="implicit" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0"
          >Implicit wait (Seconds):</label
        >
        <div class="p-col-12 p-md-6">
          <p-dropdown
            placeholder="Please Select"
            formControlName="defaultWait"
            [options]="implicit"
            optionLabel="value"
            optionValue="value"
          ></p-dropdown>
        </div>
      </div> -->
            <div class="p-field p-grid">
                <label for="loadTime" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">Auto Healing Wait Time (Seconds):</label>
                <div class="p-col-12 p-md-6">
                    <p-dropdown placeholder="Please Select" formControlName="autoHealingWaitTime"
                        [options]="autoHealingWaitTimeOpt" optionLabel="label" optionValue="value"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="reporting" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">Enable Reporting Emailing:</label>
                <div class="p-col-12 p-md-6">
                    <p-inputSwitch formControlName="emailReporting"></p-inputSwitch>
                </div>
            </div>
            <div class="p-field p-grid" *ngIf="configForm.value.emailReporting">
                <label for="reporting" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">Email Recipients:<span
                        class="steric-config">*</span>
                </label>
                <div class="p-col-12 p-md-6">
                    <input type="string" formControlName="emailRecipients" pInputText placeholder="Email Recipients" />
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="Concurrency" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">Concurrency:</label>
                <div class="p-col-12 p-md-6">
                    <p-radioButton formControlName="concurrency" class="p-ml-2" name="concurrency" value="Sequential"
                        inputId="Sequential"></p-radioButton>
                    <label class="p-ml-1" for="Parallel">Sequential</label>
                    <p-radioButton formControlName="concurrency" class="p-ml-2" name="concurrency" value="Parallel"
                        inputId="Parallel"></p-radioButton>
                    <label class="p-ml-1" for="Parallel">Parallel</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">Parallel Browser Execution Setting:</label>
                <div class="p-col-12 p-md-6">
                    <label class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">Maximum 5 Browsers can be Kept.</label>
                </div>
            </div>
            <div class="p-field p-grid" *ngIf="!environment.production">
                <label class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">Execution Grid:</label>
                <div class="p-col-12 p-md-6">
                    <p-dropdown placeholder="Please Select" [options]="multiGrids" formControlName="gridId"
                        optionLabel="gridName" optionValue="gridId"></p-dropdown>
                </div>
            </div>

            <div class="p-field p-grid">
                <!-- <label for="browsers" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0"
          >Select Browsers:</label
        >
        <div class="p-col-12 p-md-6">
          <p-multiSelect
            formControlName="browserChoice"
            [options]="browserItems"
            optionLabel="label"
            optionValue="value"
          ></p-multiSelect>
        </div> -->
                <div class="p-fluid p-formgrid p-grid browsers-grid">
                    <div class="p-field p-col-12">
                        <div class="flex justify-between">
                            <label for="browsers" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0 mb-3">Environment(s):<span
                                    class="text-red-600 ml-1">*</span></label>
                            <button [disabled]="configEnvironments.length >= 5" pButton pRipple type="button"
                                class="p-button-outlined mr-1 env-btn"
                                (click)="showLocatorDialog('Add New Environment', false)">
                                <img src="../../assets/icons/svg-icons/plus.svg" alt="Icon" class="plus-icon icon mr-2" />
                                Add New Environment
                            </button>
                        </div>

                        <p-table styleClass="gtp-table mt-5" [value]="configEnvironments" [responsive]="true"
                            [rows]="10">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>OS</th>
                                    <th>Browser Name</th>
                                    <th>Browser Version</th>
                                    <th>Resolution / Version</th>
                                    <!-- <th style="text-align: center;">Edit</th> -->
                                    <th>Actions</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-env let-ri="rowIndex">
                                <tr>
                                    <td>
                                        <div class="config-form-image truncate">
                                            <img *ngIf="
                                                    env.os.includes('Windows')
                                                " class="h-5 w-5 mr-2"
                                                src="../../../../assets/icons/browsers/windows.svg" />
                                            <img *ngIf="
                                                    env.os
                                                        .toLowerCase()
                                                        .includes('ios') ||
                                                    env.os
                                                        .toLowerCase()
                                                        .includes('macos') ||
                                                    env.os
                                                        .toLowerCase()
                                                        .startsWith('os x') ||
                                                    env.os
                                                        .toLowerCase()
                                                        .includes('iphone') ||
                                                    env.os
                                                        .toLowerCase()
                                                        .includes('ipad') ||
                                                    env.os
                                                        .toLowerCase()
                                                        .includes('ipod')
                                                " class="h-5 w-5 mr-2"
                                                src="../../../../assets/icons/browsers/macintosh.svg" />
                                            <img *ngIf="
                                                    env.os
                                                        .toLowerCase()
                                                        .includes('android') ||
                                                    !(
                                                        env.os
                                                            .toLowerCase()
                                                            .includes(
                                                                'iphone'
                                                            ) ||
                                                        env.os
                                                            .toLowerCase()
                                                            .includes(
                                                                'macos'
                                                            ) ||
                                                        env.os
                                                            .toLowerCase()
                                                            .startsWith(
                                                                'os x'
                                                            ) ||
                                                        env.os
                                                            .toLowerCase()
                                                            .includes('ios') ||
                                                        env.os
                                                            .toLowerCase()
                                                            .includes('ipad') ||
                                                        env.os
                                                            .toLowerCase()
                                                            .includes('ipod') ||
                                                        env.os
                                                            .toLowerCase()
                                                            .includes(
                                                                'android'
                                                            ) ||
                                                        env.os
                                                            .toLowerCase()
                                                            .includes('windows')
                                                    )
                                                " class="h-5 w-5 mr-2"
                                                src="../../../../assets/icons/browsers/Android.svg" />
                                            <span *ngIf="
                                                    env.environmentType ===
                                                    'MOBILE'
                                                ">{{ env.platformName }}</span>
                                            <span *ngIf="
                                                    !env.environmentType ||
                                                    env.environmentType ===
                                                        'DESKTOP'
                                                ">{{ env.os }}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="config-form-image">
                                            <img class="h-5 w-5 mr-2" src="../../../../assets/icons/browsers/{{
                                                    env.browserDetails[0]
                                                        .browserName
                                                }}.svg" />
                                            {{
                                            env.browserDetails[0]
                                            .browserName
                                            }}
                                        </div>
                                    </td>
                                    <td>
                                        {{
                                        env.browserDetails[0].browserVersion
                                        }}
                                    </td>
                                    <td>{{ env.resolution }}</td>
                                    <td>
                                        <hero-icon name="trash" tooltipPosition="top" pTooltip="Delete"
                                            hostDisplay="inlineBlock" type="outline"
                                            class="p-2 w-8 h-8 ml-2 cursor-pointer rounded-md text-gray-400 bg-gray-100 hover:text-red-400"
                                            (click)="
                                                removeEnvironmentHandler(env)
                                            "></hero-icon>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="flex justify-end text-center flex-grow">
                        <!-- <button [disabled]="configEnvironments.length >= 5" pButton pRipple type="button" label="Add"
                            class="p-button-outlined mr-1" (click)="
                                showLocatorDialog('Add New Environment', false)
                            "></button> -->
                        <button *ngIf="testConfiguration.testConfigId === 0" pButton pRipple type="submit"
                            label="Submit" [disabled]="
                                configForm.invalid || !configEnvironments.length
                            " class="p-ml-1 p-ripple p-button p-component" (click)="save()"></button>

                        <button *ngIf="testConfiguration.testConfigId !== 0" pButton pRipple type="submit"
                            label="Submit" [disabled]="
                                configForm.invalid || !configEnvironments.length
                            " class="p-ml-1 p-ripple p-button p-component" (click)="update()"></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog> -->
        <div class="p-field p-grid" style="visibility: hidden">
            <label for="tunnel" class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">Tunnel:</label>
            <div class="p-col-12 p-md-6">
                <p-dropdown placeholder="Please Select" formControlName="tunnel" [options]="tunnelOptions"
                    optionLabel="label" optionValue="value"></p-dropdown>
            </div>
        </div>
    </form>

    <app-add-environment *ngIf="displayModal" [heading]="heading" [edit]="edit" [platforms]="platforms"
        [mobileDevices]="lambdaMobileDevices" [displayModal]="displayModal" (closeModal)="close($event)"
        [grid]="this.configForm.value.gridId" (browserDetailsEmitter)="browserDetailsEmitter($event)">
    </app-add-environment>
</ng-container>
<ng-template #elseAppliTemplate>
    <p-messages severity="warn">
        <ng-template pTemplate>
            <span class="p-message-icon pi pi-exclamation-triangle"></span>
            <span class="p-message-detail"
                >Please Subscribe to avail this Feature. For Assistance:
                support@gotestpro.com
            </span>
        </ng-template>
    </p-messages>
</ng-template>