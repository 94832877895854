import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule, routes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./resetpassword/resetpassword.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { SharedModule } from "./modules/shared/shared.module";
import { AdminService } from "./services/admin.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { authInterceptorProviders } from "./core/_helpers/auth.interceptor";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { ErrorInterceptor } from "./core/_helpers/error.interceptor";
import { DatePipe } from "@angular/common";
import { UnsubscribeOnDestroyAdapter } from "./core/unsubscribe-on-destroy-adapter";
import { CachingInterceptor } from "./core/_helpers/cache.interceptor";
import { ForgotPasswordComponent } from "./forgotpassword/forgotpassword.component";
import { ChangePasswordComponent } from "./changepassword/changepassword.component";
import { ConfigFormComponent } from "./modules/config/components/config-form.component";
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from "../environments/environment";
import { VariablesListingComponent } from "./variables/components/variables-listing.component";
import { AddVariableComponent } from "./variables/components/add-variable.component";
import { RegistrationExpiredComponent } from "./registration-expired/registration-expired.component";
import { ErrorComponent } from "./error/error.component";
import { SignupComponent } from "./signup/signup.component";
import { WebSocketService } from "./core/_helpers/_services/web-socket.service";
import { menu, HeroIconModule } from "ng-heroicon";
// import { AddEnvironmentComponent } from './modules/config/components/add-environment.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    UnsubscribeOnDestroyAdapter,
    RegistrationExpiredComponent,
    ErrorComponent,
    SignupComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: "reload",
    }),
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    HeroIconModule.forRoot(
      {
        menu,
      },
      {
        defaultHostDisplay: "inlineBlock", // default 'none'
        attachDefaultDimensionsIfNoneFound: true, // default 'false'
      }
    ),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: false,
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],
  providers: [
    MessageService,
    ConfirmationService,
    AdminService,
    authInterceptorProviders,
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    // ,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CachingInterceptor,
    //   multi: true
    // }
    DatePipe,
    WebSocketService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
