import { Component, OnInit } from '@angular/core'
import { MessageService } from 'primeng/api'
import { MasterDataService } from '../core/common/services/master-data.service'
import { UserStorageService } from '../core/_helpers/_services/user-storage.service'
import { ChangePassword } from './changepassword'

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styles: [],
})
export class ChangePasswordComponent implements OnInit {
  public oldpassword: string = ''
  public newpassword: string = ''
  public confirmpassword: string = ''
  public isScreenLoading: boolean = false
  public params = new ChangePassword()
  constructor(
    private masterDataService: MasterDataService,
    private userStorage: UserStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  submitHandler() {
    this.isScreenLoading = true
    if (this.confirmpassword !== this.newpassword) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "New Password and Confirm Password does not match",
        life: 3000,
      })
      this.isScreenLoading = false
      return
    }
    if (this.confirmpassword.length <= 5 || this.newpassword.length <= 5) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Password length should be minimum 6 charectors",
        life: 3000,
      })
      this.isScreenLoading = false
      return
    }
    this.params.oldPassword = this.oldpassword
    this.params.confirmPassword = this.confirmpassword
    this.params.email = this.userStorage.getUser().user.email
    this.params.password = this.newpassword
    this.masterDataService.changePassword(this.params).subscribe((res: any) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: res.message,
        life: 3000,
      })
      this.isScreenLoading = false
      this.oldpassword = '';
      this.newpassword = '';
      this.confirmpassword = '';
    }, error => {
      this.isScreenLoading = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.apierror.debugMessage,
        life: 10000,
      })
    })
  }
}
