
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewContainerRef,
  ComponentFactoryResolver,
  ViewChild,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { USER_SELECTED_APPLICATION } from "src/app/constants";
import { MasterDetailCommands } from "src/app/core/master-detail-command";
import { UnsubscribeOnDestroyAdapter } from "src/app/core/unsubscribe-on-destroy-adapter";
import { UserStorageService } from "src/app/core/_helpers/_services/user-storage.service";
import { User } from "src/app/modules/manageuser/user";
import { Project } from "src/app/modules/projects/project";
import { Scenario } from "src/app/modules/tests/scripts/scenario";
import { Script } from "src/app/modules/tests/scripts/script";
import { GTPVariables } from "../gtp-variables";
@Component({
  selector: "app-add-variable",
  templateUrl: `../pages/add-variable.component.html`,
  styles: [],
})
export class AddVariableComponent
  extends UnsubscribeOnDestroyAdapter
  implements MasterDetailCommands<GTPVariables>, OnInit
{
  @Input() data!: GTPVariables;
  @Input() heading!: string;
  @Input() edit!: boolean;
  @Input() displayModal!: boolean;
  @Output() outputScenario: EventEmitter<any> = new EventEmitter<any>();
  @Input() scriptId!: number;

  submitted: boolean = false;

  searchKey!: string;

  loggedInUser!: User;

  user: User = new User();

  script: Script = new Script();

  applicationType!: any;

  scenarioTags!: string[];
  isScreenLoading: boolean = false;
  activeTabIndex!: number;

  commonScenarios: Array<Scenario> = new Array<Scenario>();
  @Output() closeModal = new EventEmitter<boolean>();

  public variableName: string = "";
  public applicationVariableId!: number;
  variableDescription!: string;
  variableValue!: string;
  constructor(private userStorageService: UserStorageService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);

    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.close();
    if (changes.scriptId) {
      this.scriptId = changes.scriptId.currentValue;
    }
    if (changes.data) {
      const data = changes.data.currentValue;
      if (Object.keys(data).length > 0) {
        this.heading = "Edit";
        this.variableName = data.variableName;
        this.variableValue = data.variableValue;
        this.applicationVariableId = data.applicationVariableId;
        this.variableDescription = data.variableDescription;
        console.log(this.variableValue);
      }
    }
  }

  ngOnInit(): void {
    this.getUser();
    console.log(this.data);
    if (this.data && Object.keys(this.data).length !== 0) {
      this.variableName = this.data.variableName;
      this.variableValue = this.data.variableValue;
      this.variableDescription = this.data.variableDescription;
      this.applicationVariableId = this.data.applicationVariableId;
    }
  }

  save() {
    this.data.variableName = this.variableName;
    this.data.variableValue = this.variableValue;
    this.data.variableDescription = this.variableDescription;
    this.data.applicationVariableId = this.applicationVariableId;
    if (this.scriptId) {
      this.data.scriptId = this.scriptId;
    }
    this.outputScenario.emit(this.data);
    this.close();
    // }
  }

  getUser() {
    let user: any = this.userStorageService.getUser();
    this.loggedInUser = user.user;
  }

  getProject(): Project {
    return (<any>(
      JSON.parse(window.localStorage.getItem(USER_SELECTED_APPLICATION) || "{}")
    )) as Project;
  }

  close() {
    this.displayModal = false;
    this.closeModal.emit(this.displayModal);
    this.data = new GTPVariables();
    this.variableName = "";
    this.variableValue = "";
    this.variableDescription = "";
    this.heading = "";
    this.heading = "Add";
  }

  delete() {}
  select() {}
  update() {}
  getAll() {}
}
