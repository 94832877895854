import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Project } from '../../../modules/projects/project'
import { environment } from '../../../../environments/environment'
import { CrudService } from '../../generic-operations/services/crud-service'
import { ProjectType } from '../../../modules/projects/project-type'
import { BehaviorSubject, forkJoin, Observable } from 'rxjs'
import { IResponse } from '../../generic-operations/operations/responce.interface'
import { ChangePassword } from 'src/app/changepassword/changepassword'
import { VariablesService } from 'src/app/variables/services/variables.service'
import { GTPVariables } from 'src/app/variables/gtp-variables'
import { ActionKeyword } from 'src/app/modules/tests/scripts/action-keywords'
import { StepService } from 'src/app/modules/tests/scripts/services/step.service'
import { mapValues } from "lodash";
import { Script, ScriptDetail } from 'src/app/modules/tests/scripts/script'
import { map } from 'rxjs/operators'
import { ManageUser } from 'src/app/modules/manageuser/manage-user'

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  applicationVariables!: GTPVariables[];
  private showFreeTrailMessage$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  constructor(protected http: HttpClient,
     private variableService: VariablesService,
     private stepService: StepService) {}

  getProjectTypes(): Observable<IResponse> {
    return this.http.get<IResponse>(
      `${environment.api.baseUrl}/master/applicationTypes/`
    )
  }

  getActions(): Observable<IResponse> {
    return this.http.get<IResponse>(
      `${environment.api.baseUrl}/master/actions/`
    )
  }

  getPageElements(
    id: number,
    rowsPerPage: number,
    startIndex: number,
    scriptId: number,
  ): Observable<IResponse> {
    return this.http.get<IResponse>(
      `${environment.api.baseUrl}/` +
        'application-page-locators/' +
        id +
        '/rpp/' +
        rowsPerPage +
        '/si/' +
        startIndex + 
        '/script/' +
        scriptId
    )
  }

  getEnterprisePageElements(
    id: number,
    rowsPerPage: number,
    startIndex: number,
    enterpriseId: number,
  ): Observable<IResponse> {
    return this.http.get<IResponse>(
      `${environment.api.baseUrl}/` +
        'application-page-locators/' +
        id +
        '/rpp/' +
        rowsPerPage +
        '/si/' +
        startIndex + 
        '/enterprise/' +
        enterpriseId
    )
  }

  getpageLocatorTypes(): Observable<IResponse> {
    return this.http.get<IResponse>(
      `${environment.api.baseUrl}/master/locator-types/`
    )
  }

  getOrgGridDetails(user: ManageUser) : any {
    return user.organization.executionGrid;
  }

  getData(
    id: number,
    rowsPerPage: number,
    startIndex: number,
    scriptId: number,
    isEnterprise: boolean,
    enterpriseId: number
  ): Observable<any> {
    const actions = this.getActions()
    let pageElements;
    if(isEnterprise){
      pageElements = this.getEnterprisePageElements(id, rowsPerPage, startIndex, enterpriseId)
    }else{
      pageElements = this.getPageElements(id, rowsPerPage, startIndex, scriptId)
    }
    // const pageElements = this.getPageElements(id, rowsPerPage, startIndex, scriptId)
    const appVariabiles = this.variableService.findByApplicationId(id);
    const conditionTypes = this.stepService.getConditions();
    const executionGrids = this.getExecutionGrid();
    return forkJoin([actions, pageElements, appVariabiles, conditionTypes, executionGrids])
  }

  getPlatforms(endPoint: string): Observable<any> {
    return this.http.get<any>(`${endPoint}`)
  }

  getOsResolutions(endPoint: string): Observable<any> {
    return this.http.get<any>(`${endPoint}`)
  }

  changePassword(data: ChangePassword): Observable<any> {
    return this.http.post<any>(
      `${environment.api.baseUrl}/request-change-password`,
      data
    )
  }

  updateAction(data: ActionKeyword): Observable<any> {
    return this.http.post<any>(
      `${environment.api.baseUrl}/master/action/`,
      data
    )
  }

  resetPassword(data: ChangePassword): Observable<any> {
    return this.http.post<any>(
      `${environment.api.publicUrl}/user/request-create-password`,
      data
    )
  }

  forgotPassword(data: ChangePassword): Observable<any> {
    return this.http.post<any>(
      `${environment.api.publicUrl}/user/request-forgot-password`,
      data
    )
  }

  createPassword(data: ChangePassword): Observable<any> {
    return this.http.post<any>(
      `${environment.api.publicUrl}/user/request-create-password`,
      data
    )
  }

  checkEmail(email: string): Observable<any> {
    return this.http.get<any>(
      `${environment.api.baseUrl}/user/check-email/${email}`
    )
  }
  
  getAPIMethods(): Observable<any> {
    return this.http.get<any>(
      `${environment.api.baseUrl}/master/api-method-types/`
    )
  }


  getApplicationVariables(){
    
    return this.applicationVariables;
  }

  setApplicationVariables(data: GTPVariables[]){
    this.applicationVariables = new Array<GTPVariables>();
    this.applicationVariables = data;
  }

  getShowFreeTrailMessage(): Observable<Boolean> {
    return this.showFreeTrailMessage$.asObservable();
  }

  setShowFreeTrailMessage(show: boolean) {
      this.showFreeTrailMessage$.next(show);
  }

  getGeoLocations(): Observable<any> {
    return this.http.get<any>(
      `${environment.api.baseUrl}/master/geo-locations/`
    )
  }


  webTypes(){
    const webList = [{
      "platformName":"Service Now",
      "instance":"https://royalcyberincdemo8.service-now.com/navpage.do",
      "childs": [{
        "name":"IT Service Management",
        "testCases":[
          {
          "testCase": "Service Request Management - Create Request",
          "description": "Test that a service request can be created with all mandatory fields filled out correctly, including request category, priority, and requester details."
          },
          {
          "testCase": "Service Request Management - Fulfillment",
          "description": "Test the service request fulfillment process to ensure that the request is assigned to the appropriate group or individual and that the requester is notified of the progress and status."
          },
          {
          "testCase": "Change Management - Create Request",
          "description": "Test that a change request can be created with all mandatory fields filled out correctly, including change type, impact, and urgency."
          },
          {
          "testCase": "Change Management - Approval Process",
          "description": "Test the change request approval process to ensure that the change is reviewed and approved by the appropriate stakeholders before it is implemented."
          },
          {
          "testCase": "Change Management - Implementation",
          "description": "Test the change implementation process to ensure that the change is implemented as planned and that the change records are updated accordingly."
          },
          {
          "testCase": "Problem Management - Create Record",
          "description": "Test that a problem record can be created with all mandatory fields filled out correctly, including problem description and impact analysis."
          },
          {
          "testCase": "Problem Management - Investigation",
          "description": "Test the problem investigation process to ensure that the root cause of the problem is identified and that appropriate corrective actions are taken."
          },
          {
          "testCase": "Problem Management - Resolution",
          "description": "Test the problem resolution process to ensure that the problem is resolved and that the resolution is communicated to all stakeholders."
          },
          {
          "testCase": "Knowledge Management - Create Article",
          "description": "Test that a knowledge article can be created with all mandatory fields filled out correctly, including article type, category, and tags."
          },
          {
          "testCase": "Knowledge Management - Review Process",
          "description": "Test the knowledge article review process to ensure that the article is reviewed and approved by the appropriate stakeholders before it is published."
          },
          {
          "testCase": "Knowledge Management - Search Functionality",
          "description": "Test the knowledge article search functionality to ensure that the article can be easily found by users based on relevant keywords and categories."
          },
          {
          "testCase": "Asset Management - Create Record",
          "description": "Test that an asset record can be created with all mandatory fields filled out correctly, including asset type, location, and owner."
          },
          {
          "testCase": "Asset Management - Tracking",
          "description": "Test the asset tracking process to ensure that the asset is tracked throughout its lifecycle, including procurement, deployment, maintenance, and retirement."
          },
          {
          "testCase": "Asset Management - Reporting Functionality",
          "description": "Test the asset reporting functionality to ensure that accurate and relevant asset data can be easily retrieved for inventory management and compliance purposes."
          }
          ]
      },{
        "name":"IT Operations Management"
      }, {
        "name":"Strategic Portfolio Management"
      }]
    },{
      "platformName":"Salesforce",
      "instance":"https://abc-5b8.lightning.force.com/",
      "childs": [
        { "name": 'Sales Cloud' },
        { "name": 'Service Cloud' },
        { "name": 'Marketing Cloud' },
        { "name": 'Commerce Cloud' },
        { "name": 'Community Cloud' },
        { "name": 'Analytics Cloud' },
        { "name": 'App Cloud' },
        { "name": 'IoT Cloud' },
        { "name": 'Health Cloud' },
        { "name": 'Financial Services Cloud' }
      ]
    },{
      "platformName":"SAP Financial",
      "childs": [{ "name": "Financial Accounting (FI)",
      "testCases": [
        {
          "testCase": "General Ledger (GL)",
          "description": "General Ledger (GL)"
        },
        {
          "testCase": "Accounts Receivable (AR)",
          "description": "Accounts Receivable (AR)"
        },
        {
          "testCase": "Accounts Payable (AP)",
          "description": "Accounts Payable (AP)"
        },
        {
          "testCase": "Cash Management (CE)",
          "description": "Cash Management (CE)"
        },
        {
          "testCase": "Fixed Assets (FA)",
          "description": "Fixed Assets (FA)"
        },
        {
          "testCase": "Treasury Management (TRM)",
          "description": "Treasury Management (TRM)"
        },
        {
          "testCase": "Advanced Collections (AC)",
          "description": "Advanced Collections (AC)"
        },
        {
          "testCase": "E-Business Tax (ZX)",
          "description": "E-Business Tax (ZX)"
        },
        {
          "testCase": "Financial Consolidation Hub (FCH)",
          "description": "Financial Consolidation Hub (FCH)"
        },
        {
          "testCase": "Lease and Finance Management (LFM)",
          "description": "Lease and Finance Management (LFM)"
        },
        {
          "testCase": "Revenue Management Cloud (RM)",
          "description": "Revenue Management Cloud (RM)"
        },
        {
          "testCase": "Procurement Cloud (PC)",
          "description": "Procurement Cloud (PC)"
        },
        {
          "testCase": "Risk Management Cloud (RM)",
          "description": "Risk Management Cloud (RM)"
        },
        {
          "testCase": "Enterprise Performance Management (EPM)",
          "description": "Enterprise Performance Management (EPM)"
        }
      ] },
      { "name": "Controlling (CO)" },
      { "name": "Accounts Payable (AP)" },
      { "name": "Accounts Receivable (AR)" },
      { "name": "Asset Accounting (AA)" },
      { "name": "Treasury Management (TR)" },
      { "name": "Funds Management (FM)" },
      { "name": "Special Purpose Ledger (FI-SL)" },
      { "name": "Travel Management (FI-TV)" },
      { "name": "Bank Accounting (FI-BL)" },
      { "name": "Real Estate (RE)" },
      { "name": "Lease Accounting (FI-LA)" },
      { "name": "Public Sector Management (PSM)" },
      { "name": "Grants Management (GM)" },
      { "name": "Investment Management (IM)" }]
    },{
      "platformName":"Guidewire",
      "childs": [{
        "name":"Policy center"
      },{
        "name":"Claim Center"
      },{
        "name":"Billing Center"
      }]
    },{
      "platformName":"Workday",
      "childs": [{ "name": "Human Capital Management (HCM)" },
      { "name": "Payroll" },
      { "name": "Time Tracking" },
      { "name": "Recruiting" },
      { "name": "Learning" },
      { "name": "Performance and Goal Management" }]
    },{
      "platformName":"Proprietary",
      "childs": []
    }];

    return webList;
  }


  getMockAIResponse(){
    const input = `"Here is an example of a valid JSON format scenario for testing the incident management functionality of https://royalcyberincdemo8.service-now.com/:

[
	{
		"ScenarioName": "Create Incident",
		"Steps": [
			{
				"StepName": "Open the ServiceNow homepage",
				"Locators": {
					"url": "https://royalcyberincdemo8.service-now.com/"
				},
				"Command": "NavigateToUrl"
			},
			{
				"StepName": "Log in to the system",
				"Locators": {
					"id": "user_name",
					"xpath": "//input[@id='user_password']"
				},
				"testValue": "testuser",
				"Command": "ClearFieldAndEnterText"
			},
			{
				"StepName": "Enter the password",
				"Locators": {
					"id": "user_password",
					"xpath": "//input[@id='sysverb_login']"
				},
				"testValue": "testpassword",
				"Command": "ClearFieldAndEnterText"
			},
			{
				"StepName": "Click on the Incident tab",
				"Locators": {
					"xpath": "//span[text()='Incident']"
				},
				"Command": "Click"
			},
			{
				"StepName": "Click on the New button to create a new incident",
				"Locators": {
					"xpath": "//button[text()='New']"
				},
				"Command": "Click"
			},
			{
				"StepName": "Enter the short description",
				"Locators": {
					"id": "incident.short_description"
				},
				"testValue": "Test Incident",
				"Command": "ClearFieldAndEnterText"
			},
			{
				"StepName": "Click on the Submit button to create the incident",
				"Locators": {
					"xpath": "//button[text()='Submit']"
				},
				"Command": "Click"
			},
			{
				"StepName": "Verify that the incident was created successfully",
				"Locators": {
					"xpath": "//div[text()='Incident created']"
				},
				"Command": "WaitForVisibilityOfElement"
			}
		]
	}
]
This scenario will open the ServiceNow homepage, log in to the system, navigate to the incident management module, create a new incident with a short description, and verify that the incident was created successfully. Each step includes a step name, locators (using both a URL and an ID/xpath), test values, and commands (such as clicking on a button, entering text, or waiting for an element to appear).
"`;



const output = JSON.parse(input.substring(input.indexOf("["), input.lastIndexOf("]") + 1));
console.log(output);
  }


  extractJSON(str: string) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }

  mockITServiceManagementData() : any{
    let data = `[
      {
          "modifiedBy": null,
          "createdDate": "2023-04-06T16:17:57.205+00:00",
          "modifiedDate": null,
          "scriptId": 87988,
          "scriptDescription": "Test to verify user authentication and login functionality.",
          "scriptName": "User Login",
          "rcTeafApplication": {
              "createdBy": 704,
              "modifiedBy": 704,
              "createdDate": "2022-06-09T11:33:05.000+00:00",
              "modifiedDate": "2023-04-04T11:38:50.000+00:00",
              "applicationId": 31557,
              "applicationName": "Service Now",
              "isActive": 1,
              "applicationDescription": "",
              "isSelected": null,
              "rcTeafAppExecEnvs": [
                  {
                      "id": null,
                      "appExecEnvId": 31558,
                      "envKey": "QA",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "false",
                      "applicationId": 0
                  },
                  {
                      "id": null,
                      "appExecEnvId": 86091,
                      "envKey": "IT Service Mgt",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "true",
                      "applicationId": 0
                  },
                  {
                      "id": null,
                      "appExecEnvId": 86104,
                      "envKey": "IT Ops Mgt",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "false",
                      "applicationId": 0
                  }
              ],
              "scriptCount": 0,
              "jiraKey": "",
              "jiraName": "",
              "uuid": null,
              "userName": null
          },
          "rcTeafScenarios": [],
          "rcTeafUser": {
              "createdBy": 61,
              "modifiedBy": null,
              "createdDate": "2021-03-08T14:53:06.000+00:00",
              "modifiedDate": "2021-09-23T14:41:50.000+00:00",
              "userId": 704,
              "email": "amex-tm@gmail.com",
              "password": "$2a$12$IidFgLTWaqZyCwx2IpnITeyc8nFrchtiePhgIkj0DasEa9N/sILWC",
              "name": "Amex",
              "lastName": "amex",
              "active": 1,
              "rcTeafOrganization": {
                  "createdBy": null,
                  "modifiedBy": 704,
                  "createdDate": "2021-03-08T14:51:49.000+00:00",
                  "modifiedDate": "2023-02-20T15:52:02.000+00:00",
                  "organizationId": 703,
                  "isActive": 1,
                  "organizationName": "Amex",
                  "isDeleted": "false",
                  "applitools": "\"5fK1dMuvxPTQ9M3NGoS5GgfvyfmbhYW5VZCwYBq5oXU110,RKAnLtc3vEyx0W5ry6dMxg__,false\"",
                  "trialStartDate": null,
                  "trialEndDate": null,
                  "subscriptionStartDate": "2022-10-01",
                  "subscriptionEndDate": "2023-11-30",
                  "cloudUser": "vendorroyalcyber",
                  "cloudPassword": "wb0Xya9u0yz042krFSKzQWvzMsQolcY6YoJlltwdZoIjw0AqcN",
                  "cloudHubUrlDesktop": "@hub.lambdatest.com/wd/hub",
                  "cloudHubUrlMobile": "@beta-hub.lambdatest.com/wd/hub",
                  "gridId": 0
              },
              "rcTeafRole": {
                  "createdBy": null,
                  "modifiedBy": null,
                  "createdDate": null,
                  "modifiedDate": null,
                  "roleId": 2,
                  "roleName": "Test Manager"
              },
              "resetPasswordString": null,
              "registrationLinkExpireTime": 0,
              "uuid": "false"
          },
          "tags": [
              {
                  "createdBy": null,
                  "modifiedBy": null,
                  "createdDate": "2023-04-06T16:17:57.216+00:00",
                  "modifiedDate": null,
                  "tagId": 87989,
                  "tagName": "userLogin",
                  "applicationId": 31557,
                  "numberOfTests": 0
              }
          ],
          "isRecorded": null,
          "testType": 1,
          "jiraKey": null,
          "generateMenuItems": [
              {
                  "label": "Using Default AI Settings",
                  "icon": "pi pi-cog"
              },
              {
                  "label": "Using Custom Message",
                  "icon": "pi pi-comment"
              }
          ]
      },
      {
          "modifiedBy": null,
          "createdDate": "2023-04-06T16:17:57.225+00:00",
          "modifiedDate": null,
          "scriptId": 87990,
          "scriptDescription": "Test to verify creation of incident functionality.",
          "scriptName": "Create Incident",
          "rcTeafApplication": {
              "createdBy": 704,
              "modifiedBy": 704,
              "createdDate": "2022-06-09T11:33:05.000+00:00",
              "modifiedDate": "2023-04-04T11:38:50.000+00:00",
              "applicationId": 31557,
              "applicationName": "Service Now",
              "isActive": 1,
              "applicationDescription": "",
              "isSelected": null,
              "rcTeafAppExecEnvs": [
                  {
                      "id": null,
                      "appExecEnvId": 31558,
                      "envKey": "QA",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "false",
                      "applicationId": 0
                  },
                  {
                      "id": null,
                      "appExecEnvId": 86091,
                      "envKey": "IT Service Mgt",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "true",
                      "applicationId": 0
                  },
                  {
                      "id": null,
                      "appExecEnvId": 86104,
                      "envKey": "IT Ops Mgt",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "false",
                      "applicationId": 0
                  }
              ],
              "scriptCount": 0,
              "jiraKey": "",
              "jiraName": "",
              "uuid": null,
              "userName": null
          },
          "rcTeafScenarios": [],
          "rcTeafUser": {
              "createdBy": 61,
              "modifiedBy": null,
              "createdDate": "2021-03-08T14:53:06.000+00:00",
              "modifiedDate": "2021-09-23T14:41:50.000+00:00",
              "userId": 704,
              "email": "amex-tm@gmail.com",
              "password": "$2a$12$IidFgLTWaqZyCwx2IpnITeyc8nFrchtiePhgIkj0DasEa9N/sILWC",
              "name": "Amex",
              "lastName": "amex",
              "active": 1,
              "rcTeafOrganization": {
                  "createdBy": null,
                  "modifiedBy": 704,
                  "createdDate": "2021-03-08T14:51:49.000+00:00",
                  "modifiedDate": "2023-02-20T15:52:02.000+00:00",
                  "organizationId": 703,
                  "isActive": 1,
                  "organizationName": "Amex",
                  "isDeleted": "false",
                  "applitools": "\"5fK1dMuvxPTQ9M3NGoS5GgfvyfmbhYW5VZCwYBq5oXU110,RKAnLtc3vEyx0W5ry6dMxg__,false\"",
                  "trialStartDate": null,
                  "trialEndDate": null,
                  "subscriptionStartDate": "2022-10-01",
                  "subscriptionEndDate": "2023-11-30",
                  "cloudUser": "vendorroyalcyber",
                  "cloudPassword": "wb0Xya9u0yz042krFSKzQWvzMsQolcY6YoJlltwdZoIjw0AqcN",
                  "cloudHubUrlDesktop": "@hub.lambdatest.com/wd/hub",
                  "cloudHubUrlMobile": "@beta-hub.lambdatest.com/wd/hub",
                  "gridId": 0
              },
              "rcTeafRole": {
                  "createdBy": null,
                  "modifiedBy": null,
                  "createdDate": null,
                  "modifiedDate": null,
                  "roleId": 2,
                  "roleName": "Test Manager"
              },
              "resetPasswordString": null,
              "registrationLinkExpireTime": 0,
              "uuid": "false"
          },
          "tags": [
              {
                  "createdBy": null,
                  "modifiedBy": null,
                  "createdDate": "2023-04-06T16:17:57.233+00:00",
                  "modifiedDate": null,
                  "tagId": 87991,
                  "tagName": "createIncident",
                  "applicationId": 31557,
                  "numberOfTests": 0
              }
          ],
          "isRecorded": null,
          "testType": 1,
          "jiraKey": null,
          "generateMenuItems": [
              {
                  "label": "Using Default AI Settings",
                  "icon": "pi pi-cog"
              },
              {
                  "label": "Using Custom Message",
                  "icon": "pi pi-comment"
              }
          ]
      },
      {
          "modifiedBy": null,
          "createdDate": "2023-04-06T16:17:57.240+00:00",
          "modifiedDate": null,
          "scriptId": 87992,
          "scriptDescription": "Test to verify incident update functionality.",
          "scriptName": "Update Incident",
          "rcTeafApplication": {
              "createdBy": 704,
              "modifiedBy": 704,
              "createdDate": "2022-06-09T11:33:05.000+00:00",
              "modifiedDate": "2023-04-04T11:38:50.000+00:00",
              "applicationId": 31557,
              "applicationName": "Service Now",
              "isActive": 1,
              "applicationDescription": "",
              "isSelected": null,
              "rcTeafAppExecEnvs": [
                  {
                      "id": null,
                      "appExecEnvId": 31558,
                      "envKey": "QA",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "false",
                      "applicationId": 0
                  },
                  {
                      "id": null,
                      "appExecEnvId": 86091,
                      "envKey": "IT Service Mgt",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "true",
                      "applicationId": 0
                  },
                  {
                      "id": null,
                      "appExecEnvId": 86104,
                      "envKey": "IT Ops Mgt",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "false",
                      "applicationId": 0
                  }
              ],
              "scriptCount": 0,
              "jiraKey": "",
              "jiraName": "",
              "uuid": null,
              "userName": null
          },
          "rcTeafScenarios": [],
          "rcTeafUser": {
              "createdBy": 61,
              "modifiedBy": null,
              "createdDate": "2021-03-08T14:53:06.000+00:00",
              "modifiedDate": "2021-09-23T14:41:50.000+00:00",
              "userId": 704,
              "email": "amex-tm@gmail.com",
              "password": "$2a$12$IidFgLTWaqZyCwx2IpnITeyc8nFrchtiePhgIkj0DasEa9N/sILWC",
              "name": "Amex",
              "lastName": "amex",
              "active": 1,
              "rcTeafOrganization": {
                  "createdBy": null,
                  "modifiedBy": 704,
                  "createdDate": "2021-03-08T14:51:49.000+00:00",
                  "modifiedDate": "2023-02-20T15:52:02.000+00:00",
                  "organizationId": 703,
                  "isActive": 1,
                  "organizationName": "Amex",
                  "isDeleted": "false",
                  "applitools": "\"5fK1dMuvxPTQ9M3NGoS5GgfvyfmbhYW5VZCwYBq5oXU110,RKAnLtc3vEyx0W5ry6dMxg__,false\"",
                  "trialStartDate": null,
                  "trialEndDate": null,
                  "subscriptionStartDate": "2022-10-01",
                  "subscriptionEndDate": "2023-11-30",
                  "cloudUser": "vendorroyalcyber",
                  "cloudPassword": "wb0Xya9u0yz042krFSKzQWvzMsQolcY6YoJlltwdZoIjw0AqcN",
                  "cloudHubUrlDesktop": "@hub.lambdatest.com/wd/hub",
                  "cloudHubUrlMobile": "@beta-hub.lambdatest.com/wd/hub",
                  "gridId": 0
              },
              "rcTeafRole": {
                  "createdBy": null,
                  "modifiedBy": null,
                  "createdDate": null,
                  "modifiedDate": null,
                  "roleId": 2,
                  "roleName": "Test Manager"
              },
              "resetPasswordString": null,
              "registrationLinkExpireTime": 0,
              "uuid": "false"
          },
          "tags": [
              {
                  "createdBy": null,
                  "modifiedBy": null,
                  "createdDate": "2023-04-06T16:17:57.248+00:00",
                  "modifiedDate": null,
                  "tagId": 87993,
                  "tagName": "updateIncident",
                  "applicationId": 31557,
                  "numberOfTests": 0
              }
          ],
          "isRecorded": null,
          "testType": 1,
          "jiraKey": null,
          "generateMenuItems": [
              {
                  "label": "Using Default AI Settings",
                  "icon": "pi pi-cog"
              },
              {
                  "label": "Using Custom Message",
                  "icon": "pi pi-comment"
              }
          ]
      },
      {
          "modifiedBy": null,
          "createdDate": "2023-04-06T16:17:57.254+00:00",
          "modifiedDate": null,
          "scriptId": 87994,
          "scriptDescription": "Test to verify incident assignment functionality.",
          "scriptName": "Assign Incident",
          "rcTeafApplication": {
              "createdBy": 704,
              "modifiedBy": 704,
              "createdDate": "2022-06-09T11:33:05.000+00:00",
              "modifiedDate": "2023-04-04T11:38:50.000+00:00",
              "applicationId": 31557,
              "applicationName": "Service Now",
              "isActive": 1,
              "applicationDescription": "",
              "isSelected": null,
              "rcTeafAppExecEnvs": [
                  {
                      "id": null,
                      "appExecEnvId": 31558,
                      "envKey": "QA",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "false",
                      "applicationId": 0
                  },
                  {
                      "id": null,
                      "appExecEnvId": 86091,
                      "envKey": "IT Service Mgt",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "true",
                      "applicationId": 0
                  },
                  {
                      "id": null,
                      "appExecEnvId": 86104,
                      "envKey": "IT Ops Mgt",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "false",
                      "applicationId": 0
                  }
              ],
              "scriptCount": 0,
              "jiraKey": "",
              "jiraName": "",
              "uuid": null,
              "userName": null
          },
          "rcTeafScenarios": [],
          "rcTeafUser": {
              "createdBy": 61,
              "modifiedBy": null,
              "createdDate": "2021-03-08T14:53:06.000+00:00",
              "modifiedDate": "2021-09-23T14:41:50.000+00:00",
              "userId": 704,
              "email": "amex-tm@gmail.com",
              "password": "$2a$12$IidFgLTWaqZyCwx2IpnITeyc8nFrchtiePhgIkj0DasEa9N/sILWC",
              "name": "Amex",
              "lastName": "amex",
              "active": 1,
              "rcTeafOrganization": {
                  "createdBy": null,
                  "modifiedBy": 704,
                  "createdDate": "2021-03-08T14:51:49.000+00:00",
                  "modifiedDate": "2023-02-20T15:52:02.000+00:00",
                  "organizationId": 703,
                  "isActive": 1,
                  "organizationName": "Amex",
                  "isDeleted": "false",
                  "applitools": "\"5fK1dMuvxPTQ9M3NGoS5GgfvyfmbhYW5VZCwYBq5oXU110,RKAnLtc3vEyx0W5ry6dMxg__,false\"",
                  "trialStartDate": null,
                  "trialEndDate": null,
                  "subscriptionStartDate": "2022-10-01",
                  "subscriptionEndDate": "2023-11-30",
                  "cloudUser": "vendorroyalcyber",
                  "cloudPassword": "wb0Xya9u0yz042krFSKzQWvzMsQolcY6YoJlltwdZoIjw0AqcN",
                  "cloudHubUrlDesktop": "@hub.lambdatest.com/wd/hub",
                  "cloudHubUrlMobile": "@beta-hub.lambdatest.com/wd/hub",
                  "gridId": 0
              },
              "rcTeafRole": {
                  "createdBy": null,
                  "modifiedBy": null,
                  "createdDate": null,
                  "modifiedDate": null,
                  "roleId": 2,
                  "roleName": "Test Manager"
              },
              "resetPasswordString": null,
              "registrationLinkExpireTime": 0,
              "uuid": "false"
          },
          "tags": [
              {
                  "createdBy": null,
                  "modifiedBy": null,
                  "createdDate": "2023-04-06T16:17:57.257+00:00",
                  "modifiedDate": null,
                  "tagId": 87995,
                  "tagName": "assignIncident",
                  "applicationId": 31557,
                  "numberOfTests": 0
              }
          ],
          "isRecorded": null,
          "testType": 1,
          "jiraKey": null,
          "generateMenuItems": [
              {
                  "label": "Using Default AI Settings",
                  "icon": "pi pi-cog"
              },
              {
                  "label": "Using Custom Message",
                  "icon": "pi pi-comment"
              }
          ]
      },
      {
          "modifiedBy": null,
          "createdDate": "2023-04-06T16:17:57.267+00:00",
          "modifiedDate": null,
          "scriptId": 87996,
          "scriptDescription": "Test to verify incident resolving functionality.",
          "scriptName": "Resolve Incident",
          "rcTeafApplication": {
              "createdBy": 704,
              "modifiedBy": 704,
              "createdDate": "2022-06-09T11:33:05.000+00:00",
              "modifiedDate": "2023-04-04T11:38:50.000+00:00",
              "applicationId": 31557,
              "applicationName": "Service Now",
              "isActive": 1,
              "applicationDescription": "",
              "isSelected": null,
              "rcTeafAppExecEnvs": [
                  {
                      "id": null,
                      "appExecEnvId": 31558,
                      "envKey": "QA",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "false",
                      "applicationId": 0
                  },
                  {
                      "id": null,
                      "appExecEnvId": 86091,
                      "envKey": "IT Service Mgt",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "true",
                      "applicationId": 0
                  },
                  {
                      "id": null,
                      "appExecEnvId": 86104,
                      "envKey": "IT Ops Mgt",
                      "envUrl": "https://royalcyberincdemo8.service-now.com/",
                      "parameters": null,
                      "envKeyUrl": null,
                      "isDefault": "false",
                      "applicationId": 0
                  }
              ],
              "scriptCount": 0,
              "jiraKey": "",
              "jiraName": "",
              "uuid": null,
              "userName": null
          },
          "rcTeafScenarios": [],
          "rcTeafUser": {
              "createdBy": 61,
              "modifiedBy": null,
              "createdDate": "2021-03-08T14:53:06.000+00:00",
              "modifiedDate": "2021-09-23T14:41:50.000+00:00",
              "userId": 704,
              "email": "amex-tm@gmail.com",
              "password": "$2a$12$IidFgLTWaqZyCwx2IpnITeyc8nFrchtiePhgIkj0DasEa9N/sILWC",
              "name": "Amex",
              "lastName": "amex",
              "active": 1,
              "rcTeafOrganization": {
                  "createdBy": null,
                  "modifiedBy": 704,
                  "createdDate": "2021-03-08T14:51:49.000+00:00",
                  "modifiedDate": "2023-02-20T15:52:02.000+00:00",
                  "organizationId": 703,
                  "isActive": 1,
                  "organizationName": "Amex",
                  "isDeleted": "false",
                  "applitools": "\"5fK1dMuvxPTQ9M3NGoS5GgfvyfmbhYW5VZCwYBq5oXU110,RKAnLtc3vEyx0W5ry6dMxg__,false\"",
                  "trialStartDate": null,
                  "trialEndDate": null,
                  "subscriptionStartDate": "2022-10-01",
                  "subscriptionEndDate": "2023-11-30",
                  "cloudUser": "vendorroyalcyber",
                  "cloudPassword": "wb0Xya9u0yz042krFSKzQWvzMsQolcY6YoJlltwdZoIjw0AqcN",
                  "cloudHubUrlDesktop": "@hub.lambdatest.com/wd/hub",
                  "cloudHubUrlMobile": "@beta-hub.lambdatest.com/wd/hub",
                  "gridId": 0
              },
              "rcTeafRole": {
                  "createdBy": null,
                  "modifiedBy": null,
                  "createdDate": null,
                  "modifiedDate": null,
                  "roleId": 2,
                  "roleName": "Test Manager"
              },
              "resetPasswordString": null,
              "registrationLinkExpireTime": 0,
              "uuid": "false"
          },
          "tags": [
              {
                  "createdBy": null,
                  "modifiedBy": null,
                  "createdDate": "2023-04-06T16:17:57.270+00:00",
                  "modifiedDate": null,
                  "tagId": 87997,
                  "tagName": "resolveIncident",
                  "applicationId": 31557,
                  "numberOfTests": 0
              }
          ],
          "isRecorded": null,
          "testType": 1,
          "jiraKey": null,
          "generateMenuItems": [
              {
                  "label": "Using Default AI Settings",
                  "icon": "pi pi-cog"
              },
              {
                  "label": "Using Custom Message",
                  "icon": "pi pi-comment"
              }
          ]
      }
  ]`;

  

  const script: Array<ScriptDetail> = mapValues(JSON.parse(data));

  return script;
  }

  mockTestAIData(){
    const data = `[
      {
          "TestName": "Navigation Testing",
          "Description": "Test the navigation of ServiceNow.",
          "AcceptanceCriteria": "<h3>Acceptance Criteria.</h3><ul><li>It should be possible to login to ServiceNow</li><li>It should be possible to navigate to Home, Incident, Problem, Change, and Service Catalog pages.</li><li>It should be possible to select options from the drop-down menus and open any page using them.</li><li>All pages should display properly without any errors or missing information.</li></ul>"
      },
      {
          "TestName": "Login Testing",
          "Description": "Test the login functionality in ServiceNow.",
          "AcceptanceCriteria": "<h3>Acceptance Criteria.</h3><ul><li>It should be possible to login to ServiceNow using valid credentials.</li><li>It should show an error message when invalid credentials are used.</li></ul>"
      },
      {
          "TestName": "Incident Management Testing",
          "Description": "Test the Incident Management functionalities in ServiceNow.",
          "AcceptanceCriteria": "<h3>Acceptance Criteria.</h3><ul><li>It should be possible to raise a new incident.</li><li>It should be possible to edit an existing incident.</li><li>It should be possible to assign an incident to a user.</li><li>It should be possible to resolve an incident.</li></ul>"
      },
      {
          "TestName": "Change Management Testing",
          "Description": "Test the Change Management functionalities in ServiceNow.",
          "AcceptanceCriteria": "<h3>Acceptance Criteria.</h3><ul><li>It should be possible to raise a new change request.</li><li>It should be possible to edit an existing change request.</li><li>It should be possible to assign a change request to a user.</li><li>It should be possible to approve or reject a change request.</li><li>It should be possible to close a change request.</li></ul>"
      },
      {
          "TestName": "Service Catalog Testing",
          "Description": "Test the Service Catalog functionalities in ServiceNow.",
          "AcceptanceCriteria": "<h3>Acceptance Criteria.</h3><ul><li>It should be possible to view all the available services in the Service Catalog.</li><li>It should be possible to order a service.</li><li>It should be possible to track the progress of a service order.</li><li>It should be possible to cancel a service order.</li></ul>"
      }
  ]`

  return data;
  }

  getExecutionGrid(): Observable<IResponse> {
    return this.http.get<IResponse>(`${environment.api.baseUrl}/execution-grid/`).pipe(
      map((o:IResponse): IResponse => ( { 
          code: o.code, 
          message: o.message,
          result: o.result 
      })));
  } 

   readDataFromJsonFile(){
    return this.http.get('assets/data.json');
   }


}




