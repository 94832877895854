import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, scheduled } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponse, IResponsePaginated, IResponsePaginatedTests } from 'src/app/core/generic-operations/operations/responce.interface';
import { environment } from '../../../../environments/environment';
import { CrudService } from '../../../core/generic-operations/services/crud-service';
import { ScheduleTest } from '../schedule-test';


@Injectable({
  providedIn: 'root'
})
export class ScheduleTestService extends CrudService<ScheduleTest, number> {
    findFiltered(id: number, value: string) {
      return this._http.get<IResponse>(`${environment.api.baseUrl}/schedules/` + id + '/'+value).pipe(
        map((o:IResponse): IResponse => ( { 
            code: o.code, 
            message: o.message,
            result: o.result 
        })));
    }

    constructor(protected http: HttpClient) {
        super(http, `${environment.api.baseUrl}/`, 'schedule', 'schedules');
      }

    findScheduleTestsByApplicationId(data: any){
      return this._http.post<IResponsePaginatedTests>(`${environment.api.baseUrl}/schedules/`, data).pipe(
        map((o:IResponsePaginatedTests): IResponsePaginatedTests => ( { 
            code: o.code, 
            message: o.message,
            result: o.result 
        })));
    }

   
      
}