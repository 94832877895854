import { environment } from "src/environments/environment"

export const USER_KEY = 'auth-user'
export const SUAPP_ID = 'suapp_id'
export const PROJECT_UUID = 'project_uapp_id'

export const SUPER_ADMIN = 'RC Super Admin'
export const TEST_MANAGER = 'Test Manager'
export const TEST_ENGINEER = 'Test Engineer'
export const USER_SELECTED_APPLICATION = 'user-selected-application'
export const USER_SELECTED_ENTERPRISE_APPLICATION = 'user-selected-enterprise-application'
export const USER_DEFAULT_PROJECT = 'user-default-application'
export const CACHE_BLACK_LIST: Array<string> = ['script', 'scenarios']
export const START_INDEX: number = 0
export const PAGINATION_SIZE_FIVE: number = 5
export const PAGINATION_SIZE_TEN: number = 10
export const PAGINATION_SIZE_20: number = 20
// export const LAMBDA_TEST_CREDENTIALS = {
//   username: 'abdul.gaho',
//   accessKey: 'eDIrO9esNRQsCVNk5urSyCMB9ddgzJktOo1Gwd3eMql3876EEc',
// }

export const LAMBDA_TEST_CREDENTIALS = {
    username: 'vendorroyalcyber',
    accessKey: 'wb0Xya9u0yz042krFSKzQWvzMsQolcY6YoJlltwdZoIjw0AqcN',
   }
export const SCRIPT_LISTING_ROUTE = '/tests/listing';
export const EXECUTE_ROUTE = '/execute';
export const PAGE_ELEMENTS_ROUTE = '/page-elements';
export const CONFIG_ROUTE = '/config';
export const RESULTS_ROUTE = '/results';
export const SETTINGS_ROUTE = '/settings';
export const APP_OAUTH_DATA = 'rcteaf-client:devglan-secret';
export const PUBLISHED_EXTENSION_KEY = 'ldmfmccaoihffboppndecjpfldhamafn';
export const MAX_UPLOAD_FILE_SIZE = 2000000;
export const XRAY_ACCESS_TOKEN = 'xray-access-token';
export const JIRA_URL = 'jira-url';
export const JIRA_API_TOKEN = 'jira-api-token';
export const JIRA_USER = 'jira-user';
export const DESKTOP_PLATFORMS = ["Windows 11", "Windows 10", "Windows 8.1", "Windows 8", "Window 7", "macOS Monterey",  "macOS Big Sur", "macOS Catalina",  "macOS Mojave", "macOS High Sierra", "OS X El Capitan"];
export const COMMON_RESOLUTIONS = [ {"label":"1920x1080","value":"1920x1080"},
                                  {"label":"1280x1024","value":"1280x1024"}, 
                                  {"label":"1440x900","value":"1440x900"}, 
                                  {"label":"2048x1536","value":"2048x1536"}, 
                                  {"label":"2560x1440","value":"2560x1440"}, 
                                  {"label":"1600x1200","value":"1600x1200"},
                                  {"label":"1024x768","value":"1024x768"}]

export const FREE_TRAIL_MESSAGE = "Please Subscribe to avail this Feature. For Assistance: support@gotestpro.com";                                  
export enum VARIABLE_SCOPE {
                        GLOBAL = "Global",
                        LOCAL = "Local",
                        CICD = "Cicd"
                    };
export const EXTENSION_ID="extension-id";
export const AI_TOKEN="ai-token";
export const DEFAULT_AI_TOKEN = environment.openAIToken
export const AI_MODEL="ai-model";
export const DEFAULT_AI_MODEL = "gpt-3.5-turbo"
export const TOKEN_LENGTH="token-length";
export const DEFAULT_TOKEN_LENGTH = "512";
export const TEST_CASE_PROMPT="test-case-prompt";
export const TEST_SCENARIO_PROMPT="test-scenario-prompt";
export const DATA_SOURCE_TYPES = [{"label":"Excel"}, {"label":"API"}, {"label":"Variables"}]
export const TEST_PROMPT_RESPONSE_STRUCTURE = "in valid JSON format such as Array of {“TestName“:String, “Description“: String, “AcceptanceCriteria“: “<h3>Acceptance Criteria.</h3>list“}.Do not return any non-json text or numbering."
export const TEST_SCENARIOS_PROMPT_RESPONSE_STRUCTURE = "covert response in JSON {“Scenarios“:[{“ScenarioName“:String,“Steps“:[{“StepName“:string,“testValue“:“testdata“,“Locators“:{“xpath“:“xpath“,“url“:String, “id“:“id“},{“Command“:“Click“ || “SendKeys“ || “OpenApp“ || “SelectDropDown“ || “Refreshpage“ || “MouseHover“ || “DoubleClick“ || “AcceptAlert“ || “DeclineAlert“ || “SwitchToNewTab“ || “SwitchToNewWindow“ || “SwitchToNewWindowWithTitle“ || “ScrollPageByCoordinates“ || “WaitFor“ || “CloseWindow“ || “WaitForVisibilityOfElement“ || “WaitForElementPresence“ || “NavigateToUrl“ || “VerifyToolTipMessage“ || “AssertElementPresent“ || “AssertTextPresent“ || “AssertPageTitle“ || “AssertPartialText“ || “AssertElementNotPresent“ || “AssertTextNotPresent“ || “AssertChecked“ || “AssertEnabled“ || “SwitchToDefaultWindow“ || “ScrollPageToElement“ || “SwitchToiFrame“ || “SwitchToMainContent“ || “ExtractTextAndStoreToVariable“ || “AssertStoredTextPresent“ || “DragAndDrop“ || “ClearFieldAndEnterText“ || “PDFDownload“ || “TakeScreenshot“ || “AssertDisabled“ || “CloseTab“ || “AssertUnChecked“ || “SwtichToMainTab“ || “SwitchTab“ || “AddSelection“ || “SelectCheckbox“ || “StorePartialTextToVariable“ || “ResizeWindow“ || “EyesCheckWindow“ || “EyesCheckElement“ || “EyesSetViewPortSize“ || “EyesSetMatchTimeout“ || “EyesSetMatchLevel“ || “FileUpload“ || “SwitchWindow“ || “MFA“ || “EmailOTP“ || “IF“ || “ELSE“ || “End IF“ || “End ELSE“ || “SelectRadioButton“ || “ForLoop“ || “API Request“}]}]} with dynamic xpath and add if/else Command if required. No numberings and non-json text."
export const DEFAULT_TEST_SCENARIOS_PROMPT_START = "Scenario to automate";
export const DEFAULT_TEST_CASE_PROMPT_START = "List 10 prompts to cover full functional testing";
export const SCENARIO_STEPS_PROMPT_RESPONSE_STRUCTURE = "{“StepName“:random-string,“testValue“:String,“Locators“:{“id“:String,“classname“:String,“xpath“:String,“cssselector“:String,“text“:String,“name“:String,“url“:String},{“Command“:String}}  with first “Step“ as “OpenApp“"
export const SUPER_ADMIN_SELECTED_ORGANIZATION_ID = "super-admin-selected-organization-id";
