import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { ExecutionFilter } from 'src/app/core/models/enum';
import { UserStorageService } from 'src/app/core/_helpers/_services/user-storage.service';
import { Product } from 'src/app/modules/execute/product';
import { ProductService } from 'src/app/modules/execute/productservice';

@Component({
    selector: 'app-schedule-testtable',
    templateUrl: '../list/schedule-testtable.component.html',
    styles: [],
})

export class ScheduleTestTableComponent implements OnInit {
    @Output() filter = new EventEmitter();
    _data: any;
    totalRecords: number = 0;
    @Output() paginationEmitter = new EventEmitter();
    @Input() set data(value: any) {
        this._data = value
        this.totalRecords = this._data?.tests?.total;
    }

    public scheduleFilters = ExecutionFilter
    scheduleFiltersItems!: SelectItem[]
    public selectedFilter: string = ''
    paginationSize: number = 5

    constructor(private userService: UserStorageService) {
    }

    
    getProject(): number{
        return this.userService.getUserSelectedProject().applicationId;
    }

    ngOnInit() {
        this.scheduleFiltersItems = []
        for (const [key, value] of Object.entries(this.scheduleFilters)) {
            this.scheduleFiltersItems.push({ label: value, value: value })
        }
        
        
    }

    onFilterChange(event: any) {
        this.filter.emit(event.value.label)
    }

    nextPage(event: LazyLoadEvent) {
        this.paginationEmitter.emit(event)
    }
}