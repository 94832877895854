import { CrudOperations } from "../operations/crud-operations.interface";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, shareReplay, tap } from 'rxjs/operators';
import { IResponse, IResponsePaginated, IResponsePaginatedTests } from "../operations/responce.interface";

export abstract class CrudService<T, ID> implements CrudOperations<T, ID> {

    constructor(
      protected _http: HttpClient,
      protected _base: string,
      protected _entity: string, 
      protected _entities: string
    ) {}
  
    save(t: T): Observable<IResponse> {
      return this._http.post<IResponse>(this._base + this._entity+'/', t).pipe(
        map((o:IResponse): IResponse => ( { 
            code: o.code, 
            message: o.message,
            result: o.result 
        })));
    }
  
    update(id: ID, t: T): Observable<IResponse> {
      return this._http.put<IResponse>(this._base + this._entity+'/' + id , t, {}).pipe(
        map((o:IResponse): IResponse => ( { 
            code: o.code, 
            message: o.message,
            result: o.result 
        })));
    }
  
    findOne(id: ID): Observable<IResponse> {
      return this._http.get<IResponse>(this._base + this._entity + "/" + id).pipe(
        map((o:IResponse): IResponse => ( { 
            code: o.code, 
            message: o.message,
            result: o.result 
        })));
    }
  
    findAll(): Observable<IResponse> {
      return this._http.get<IResponse>(this._base + this._entities).pipe(
        map((o:IResponse): IResponse => ( { 
            code: o.code, 
            message: o.message,
            result: o.result 
        })));
    }
  
    delete(id: ID): Observable<IResponse> {
      return this._http.delete<IResponse>(this._base + this._entity+'/' + id).pipe(
        map((o:IResponse): IResponse => ( { 
            code: o.code, 
            message: o.message,
            result: o.result 
        })));
      }

      // findPaginated(filter : any, pageSize: number, startIndex: number){
      //   return this._http.get<IResponsePaginated>(this._base + this._entities+'/' + filter +'/rpp/'+pageSize+'/si/'+startIndex).pipe(
      //     map((o:IResponsePaginated): IResponsePaginated => ( { 
      //         code: o.code, 
      //         message: o.message,
      //         result: o.result 
      //     })));
      // }

      findPaginated(filter : any, pageSize: number, startIndex: number): Observable<IResponsePaginatedTests> {
        return this._http.get<IResponsePaginatedTests>(this._base + this._entities+'/' + filter +'/rpp/'+pageSize+'/si/'+startIndex).pipe(
          map((o:IResponsePaginatedTests): IResponsePaginatedTests => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }

      findAllPaginated(pageSize: number, startIndex: number): Observable<IResponsePaginatedTests> {
        return this._http.get<IResponsePaginatedTests>(this._base + this._entities+'/rpp/'+pageSize+'/si/'+startIndex).pipe(
          map((o:IResponsePaginatedTests): IResponsePaginatedTests => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }

      findAllFiltered(filter: any): Observable<IResponse> {
        return this._http.post<IResponse>(this._base + this._entities+'/filter', filter).pipe(
          map((o:IResponse): IResponse => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }

      findByEntity(t: T): Observable<IResponsePaginated> {
        return this._http.post<IResponsePaginated>(this._base + this._entities, t).pipe(
          map((o:IResponsePaginated): IResponsePaginated => ( { 
              code: o.code, 
              message: o.message,
              result: o.result 
          })));
      }
  
     
  }