<div class="p-grid p-flex-column">
  <!-- <div class="p-col">
    <div class="box">
      <label class="p-mb-3 p-f-left">Organization Name</label>
    </div>
  </div> -->
  <div class="p-col">
    <div class="box">
      <span *ngIf="_message !== ''">{{_message}}</span>
      <span class="script-count" *ngIf="_message === ''">{{_count}}</span>
    </div>
  </div>  
</div>