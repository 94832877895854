import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { forkJoin, Observable } from 'rxjs'
import { ChangePassword } from 'src/app/changepassword/changepassword'
import { IResponsePaginated } from '../core/generic-operations/operations/responce.interface'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(protected http: HttpClient) { }

  getCommonScenarios(orgId: number, userId: number, applicationId: number): Observable<any> {
    return this.http.get<any>(`${environment.api.baseUrl}/dashboard/organization/${orgId}/project/${applicationId}/commonscenarios/${userId}`)
  }

  getTotalScripts(orgId: number, userId: number, applicationId: number): Observable<any> {
    return this.http.get<any>(`${environment.api.baseUrl}/dashboard/organization/${orgId}/project/${applicationId}/scripts/${userId}`)
  }

  getTotalExecutions(orgId: number, userId: number, applicationId: number): Observable<any> {
    return this.http.get<any>(`${environment.api.baseUrl}/dashboard/organization/${orgId}/project/${applicationId}/executions/${userId}`)
  }

  getExecutionStats(orgId: number, executionType: string, filter: string): Observable<any> {
    return this.http.get<any>(`${environment.api.baseUrl}/dashboard/organization/${orgId}/projects/execution/stats/${executionType}/${filter}`)
  }

  findAllPaginated(orgId: number, executionType: string, filter: string, userEmail: string, pageSize: number, startIndex: number, applicationId: number): Observable<IResponsePaginated> {
    return this.http.get<IResponsePaginated>(`${environment.api.baseUrl}/dashboard/organization/${orgId}/project/${applicationId}/execution/stats/${executionType}/${filter}/user/${userEmail}/userType/rpp/${pageSize}/si/${startIndex}`).pipe(
      map((o: IResponsePaginated): IResponsePaginated => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }


}
