import { Base } from "src/app/core/models/base.model";
import { Organization } from "../organizations/organization";
import { Role } from "./role";

export class User extends Base {
    userId!: number;
    email!: string;
    name!: string;
    lastName!: string;
    password!: string;
    active!: string;
    rcTeafOrganization!: Organization;
    rcTeafRole!: Role;
    permissions?: any;
    parsedPermissions?: any
}