import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Project } from '../project';
import { environment } from '../../../../environments/environment';
import { CrudService } from '../../../core/generic-operations/services/crud-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IResponse, IResponsePaginated, IResponsePaginatedTests } from 'src/app/core/generic-operations/operations/responce.interface';
import { map } from 'rxjs/operators';
import { ProjectExecEnvs } from '../project-exec-env';
import { ResultRequest } from '../../results/resultRequest';

@Injectable({
  'providedIn': 'root'
})
export class ProjectService extends CrudService<Project, number>{
  

  private currentProject$: BehaviorSubject<Project> = new BehaviorSubject(new Project());
  private updateCurrentProject$: BehaviorSubject<Project> = new BehaviorSubject(new Project());
  private updatedProject$: BehaviorSubject<Project> = new BehaviorSubject(new Project());
  private refreshedProjects$: BehaviorSubject<Project[]> = new BehaviorSubject(new Array<Project>());

  constructor(protected http: HttpClient) {
    super(http, `${environment.api.baseUrl}/`, 'application', 'applications');
  }

  projects(request: ResultRequest): Observable<IResponsePaginatedTests> {
    return this._http.post<IResponsePaginatedTests>(`${environment.api.baseUrl}/` + 'applications/', request).pipe(
      map((o: IResponsePaginatedTests): IResponsePaginatedTests => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  organizationProjects(id: number, userId: number): Observable<IResponse> {
    return this._http.get<IResponse>(`${environment.api.baseUrl}/` + 'applications/' + id + '/' + userId).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  userProjects(userId: number): Observable<IResponse> {
    return this._http.get<IResponse>(`${environment.api.baseUrl}/` + 'applications/user/' + userId).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  subapplicationdetail(request: ResultRequest): Observable<IResponse> {
    return this._http.post<IResponse>(`${environment.api.baseUrl}/` + 'enterprise/subAppTestCount', request).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }


  getCurrentProject(): Observable<Project> {
    return this.currentProject$.asObservable();
  }

  setCurrentProject(project: Project) {
    this.currentProject$.next(project);
  }

  getRefreshedProjects(): Observable<Project[]> {
    return this.refreshedProjects$.asObservable();
  }

  setRefreshedProjects(projects: Project[]) {
    this.refreshedProjects$.next(projects);
  }

  getUpdateCurrentProject(): Observable<Project> {
    return this.updateCurrentProject$.asObservable();
  }

  setUpdateCurrentProject(project: Project) {
    this.updateCurrentProject$.next(project);
  }

  getUpdatedProjects(): Observable<Project> {
    return this.updatedProject$.asObservable();
  }

  setUpdatedProjects(project: Project) {
    this.updatedProject$.next(project);
  }

  markAsSelected(userId: number, applicationId: number, isSelected: string, suappId: string, application: Project): Observable<IResponse> {
    return this._http.post<IResponse>(`${environment.api.baseUrl}/` + 'application/markselected/' + userId + '/' + applicationId + '/' + isSelected + '/' + suappId, application).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }


  deSelectAll(userId: number, uuid: string, appId: number, application: Project): Observable<IResponse> {
    return this._http.post<IResponse>(`${environment.api.publicUrl}/` + 'application/' + userId + '/' + uuid + '/' + appId + '/deselect', application).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  getProject(project: Project): Project {
    console.log(project);
    
    const currentProject = new Project();
    currentProject.applicationId = project.applicationId;
    currentProject.applicationName = project.applicationName;
    currentProject.rcTeafApplicationType = project.rcTeafApplicationType;
    currentProject.rcTeafAppExecEnvs = project.rcTeafAppExecEnvs;
    currentProject.isActive = project.isActive;
    currentProject.jiraKey = project.jiraKey;
    currentProject.jiraName = project.jiraName;
    currentProject.isEnterprise = project.isEnterprise;
    currentProject.rcTeafEnterpriseApplication = project.rcTeafEnterpriseApplication;
    return currentProject;
  }

  deleteEnvironment(appId: number, envId: number): Observable<IResponse> {
    return this._http.delete<IResponse>(`${environment.api.baseUrl}/` + 'application/delete/environment/' + appId + '/' + envId).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  ProjectName(appName: string, orgId: number): Observable<IResponse> {
    return this._http.get<IResponse>(`${environment.api.baseUrl}/` + 'application/duplicate/application/' + appName + '/organization/' + orgId).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  markEnvironmentAsDefault(envId: number, env: ProjectExecEnvs): Observable<IResponse> {
    return this._http.put<IResponse>(`${environment.api.baseUrl}/` + 'application/environment/' + envId, env).pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  getEnterpriseApplications(): Observable<IResponse> {
    return this._http.get<IResponse>(`${environment.api.baseUrl}/` + 'enterprise/applications').pipe(
      map((o: IResponse): IResponse => ({
        code: o.code,
        message: o.message,
        result: o.result
      })));
  }

  checkDuplicateProjectName(params: any): Observable<any> {
    return this._http.post(
      `${environment.api.baseUrl}/` + "application/check-duplicate-projectname",
      params
    );
  }
}