import { Base } from 'src/app/core/models/base.model'
import { TestEvironmentBrowerDetails } from './test-environment-browser-details'

export class TestConfigEnvironments extends Base {
  id!: number
  testConfigEnvironmentsId!: number
  os!: string
  browserDetails!: TestEvironmentBrowerDetails[]
  resolution!: string
  testConfigId!: string
  environmentType!: string //mobile or desktop
  platformName?: string
  constructor(
    id?:number,
    testConfigEnvironmentsId?: number,
    os?: string,
    browserDetails?: TestEvironmentBrowerDetails[],
    resolution?: string,
    testConfigId?: string,
    environmentType?: string
  )
  constructor(
    id?: number,
    testConfigEnvironmentsId?: number,
    os?: string,
    browserDetails?: TestEvironmentBrowerDetails[],
    resolution?: string,
    testConfigId?: string,
    environmentType?: string
  ) {
    super()
    this.id = id || 0
    this.testConfigEnvironmentsId = testConfigEnvironmentsId || 0
    this.os = os || ''
    this.browserDetails = browserDetails || []
    this.resolution = resolution || ''
    this.testConfigId = testConfigId || '',
    this.environmentType = environmentType || ''
  }
}
