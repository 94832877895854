import { Component, Injectable, OnDestroy } from "@angular/core";
import { SubSink } from "./models/sub.sink";

@Component({
    template:''
})
export class UnsubscribeOnDestroyAdapter implements OnDestroy {
    subs = new SubSink();
    ngOnDestroy(): void {
        this.subs.unsubscribe();
      }

}