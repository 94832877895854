<div *ngIf="isScreenLoading">
    <p-progressSpinner></p-progressSpinner>
    <div class="progressspinner-overlay"></div>
</div>
<div class="p-grid p-col-12">
    <ng-container *ngIf="scheduleList && scheduleList.length > 0">
        <p-table
            #dt
            [responsive]="true"
            [value]="scheduleList"
            [rowHover]="true"
            styleClass="p-datatable-striped p-datatable-responsive-demo gtp-table"
            [paginator]="true"
            [rows]="paginationSize"
            [totalRecords]="totalRecords"
            [lazy]="true"
            (onLazyLoad)="nextPage($event)"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[10, 25, 50]"
            [loading]="isGridLoading"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 5rem">S.No.</th>
                    <th style="width: 7rem" pSortableColumn="tag.tagName">
                        Test Plan
                    </th>
                    <th style="width: 20rem" pSortableColumn="name">
                        Job Name
                    </th>
                    <th style="width: 8rem" pSortableColumn="frequency">
                        Retry Count
                    </th>
                    <th style="width: 8rem" pSortableColumn="name">
                        Console Logs
                    </th>
                    <th style="width: 8rem">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-ri="rowIndex" let-test>
                <tr>
                    <td class="col-ellipsis">
                        {{ ri + 1 }}
                    </td>
                    <td>
                        <ng-container *ngIf="test.tagName !== null; else noTag">
                            {{ test.tagNames }}
                        </ng-container>
                        <ng-template #noTag> </ng-template>
                    </td>
                    <td>{{ test.testName }}</td>
                    <td>{{ test.retryCount }}</td>
                    <td>{{ test.consoleLogs }}</td>
                    <td class="col-no-ellipsis">
                        <hero-icon
                            name="clipboard-copy"
                            tooltipPosition="top"
                            pTooltip="Copy URL"
                            hostDisplay="inlineBlock"
                            type="outline"
                            class="p-2 w-8 h-8 ml-2 cursor-pointer rounded-md text-gray-400 bg-gray-100 hover:text-blue-400"
                            (click)="copyText(test.executeUrl)"
                        ></hero-icon>
                        <hero-icon
                            name="pencil"
                            tooltipPosition="top"
                            pTooltip="Edit"
                            hostDisplay="inlineBlock"
                            type="outline"
                            class="p-2 w-8 h-8 ml-2 cursor-pointer rounded-md text-gray-400 bg-gray-100 hover:text-blue-400"
                            (click)="showDialog(test)"
                        ></hero-icon>
                        <hero-icon
                            name="trash"
                            tooltipPosition="top"
                            pTooltip="Delete"
                            hostDisplay="inlineBlock"
                            type="outline"
                            class="p-2 w-8 h-8 ml-2 cursor-pointer rounded-md text-gray-400 bg-gray-100 hover:text-red-400"
                            (click)="deleteScheduled(test)"
                        ></hero-icon>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
    </ng-container>

    <!--
           <app-execute-history></app-execute-history> -->
    <p-dialog
        [(visible)]="displayDialog"
        [style]="{ width: '550px' }"
        header="Edit Test"
        [modal]="true"
        styleClass="p-fluid"
    >
        <ng-template pTemplate="content">
            <app-settings-form
                [isEdit]="true"
                [updateTest]="selectedTest"
                (formValues)="getSchedularInfo($event)"
            >
            </app-settings-form>
            <div class="p-f-right">
                <button
                    pButton
                    pRipple
                    type="button"
                    (click)="update()"
                    label="Update"
                    icon="pi pi-plus-circle"
                ></button>
            </div>
        </ng-template>
    </p-dialog>
</div>
