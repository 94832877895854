import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MasterDataService } from 'src/app/core/common/services/master-data.service';
import { IResponse } from 'src/app/core/generic-operations/operations/responce.interface';
import { RoleEnum } from 'src/app/core/models/enum';
import { OrganizationConcurrency } from 'src/app/core/models/organization-concurrency';
import { UnsubscribeOnDestroyAdapter } from 'src/app/core/unsubscribe-on-destroy-adapter';
import { AuthenticationService } from 'src/app/core/_helpers/_services/authentication.service';
import { UserStorageService } from 'src/app/core/_helpers/_services/user-storage.service';
import { Organization } from 'src/app/modules/organizations/organization';
import { OrganizationService } from 'src/app/modules/organizations/services/organization.service';
import { ProjectService } from 'src/app/modules/projects/services/projects.service';
import { VariablesService } from 'src/app/variables/services/variables.service';
import { PROJECT_UUID, SUAPP_ID, USER_DEFAULT_PROJECT, USER_SELECTED_APPLICATION } from '../../constants';
import { ManageUser } from '../../modules/manageuser/manage-user';
import { User } from '../../modules/manageuser/user';
import { Project } from '../../modules/projects/project';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  loggedInUser: any;
  user: ManageUser = new ManageUser();
  projects: Project[] = new Array<Project>();
  selectedProject!: Project;
  userOrganization: Organization = new Organization();
  userApplications: Project[] = new Array<Project>();
  loggedInUserId!: number;
  skeleton!: boolean;
  public isScreenLoading: boolean = false;
  organizationConcurrency!: OrganizationConcurrency;
  constructor(private userStorageService: UserStorageService,
    private authenticationService: AuthenticationService,
    private projectService: ProjectService, private confirm: ConfirmationService,
    private router: Router, private variableService: VariablesService, 
    private masterService: MasterDataService, 
    private organizationService: OrganizationService,
    private masterDataService: MasterDataService) {
    super();

  }
  itemsProfile: MenuItem[] = [];
  items!: MenuItem[];


  ngOnInit() {
    this.projectService.getCurrentProject().subscribe(updatedProject => {
      if (updatedProject && updatedProject.applicationId != 0) {
        this.loadApplicationVariables(updatedProject.applicationId);
          this.selectedProject = updatedProject;        
      }
    });    
    this.projectService.getRefreshedProjects().subscribe(refreshed => {
      if(Object.keys(this.user)){
        this.processProjects();
      }
      
    })

    this.processProjects();
    this.getOrganizationConcurrency();
  }
  private processProjects() {
    
    this.updateCurrentProject();
    this.updateProjects();
    this.getUser();
    this.getProjects();
   
    this.itemsProfile = [
      { label: 'Change Password', icon: 'pi pi-fw pi-user', routerLink: '/changepassword' },
      { separator: true },
      {
        label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: (event) => {
          //event.originalEvent: Browser event
          //event.item: menuitem metadata
          event.click = this.logout();
        }
      }
    ];
  }
  getOrganizationConcurrency() {
    const executionGrid = this.masterDataService.getOrgGridDetails(this.getUser());
    const apis = JSON.parse(executionGrid.apiData);
    this.organizationService.getConcurrency(apis.concurrency).subscribe(res =>{
      this.organizationConcurrency = res;
      
    })
  }

  

  private updateCurrentProject() {
    this.projectService.getUpdateCurrentProject().subscribe(project => {
     
      if (project.applicationId != 0) {
        this.selectedProject = project;
        this.markAsSelected(true);
      }
    });
  }

  private getCurrentProject() {
    this.projectService.getCurrentProject().subscribe(project => {
      if (project && project.applicationId != 0) {
        this.selectedProject = project;
      }
    });
  }

  private updateProjects() {
    this.projectService.getUpdatedProjects().subscribe(project => {
      if (project.applicationId != 0) {
        this.getProjects();
      }
    });
  }


  /**
   * toggleSidebar
  : boolean  */
  public toggleSidebar(status: boolean): boolean {
    return !status;
  }

  logout() {
    this.authenticationService.logout(true).subscribe(res => {
      // this.router.navigate(['/login']);
    });
  }



  getProjects() {
    
    console.log(this.userStorageService.getUserSelectedProject());
    
    this.skeleton = true;
    if (this.isTestManager || this.isAdmin) {
      if(this.user){
        this.subs.sink = this.projectService.organizationProjects(this.userOrganization.organizationId, this.user.user.userId).subscribe((res: IResponse) => {
          this.projects = res.result;

          this.selectedProject = <any>this.projects.find(project => project.isSelected && project.uuid === this.userStorageService.getSUAPPID());
          if(!this.selectedProject){
            
            this.selectedProject = <any>this.projects.find(project => this.userStorageService.getUserSelectedProject().applicationId === project.applicationId);
            if(!this.selectedProject){
              this.selectedProject = <any>this.projects.find(project => project.isSelected);
            }
        }

          if (this.selectedProject) {
            this.setUserProject();
          } else {
            this.selectUserProject();
          }
          setTimeout(() => {
            this.projectService.setCurrentProject(this.selectedProject);
            this.skeleton = false;
          }, 1000);
  
        });
      }
      
    } else {
      this.projectService.userProjects(this.user.user.userId).subscribe(res => {
        this.projects = res.result;
        this.selectedProject = <any>this.projects.find(project => project.isSelected && project.uuid === this.userStorageService.getSUAPPID());
          if(!this.selectedProject){
            // this.selectedProject = <any>this.projects.filter(project => project.isSelected);
            
            this.selectedProject = <any>this.projects.find(project => this.userStorageService.getUserSelectedProject().applicationId === project.applicationId);
            if(!this.selectedProject){
              this.selectedProject = <any>this.projects.find(project => project.isSelected);
            }
          }
      
          if (this.selectedProject) {
            this.setUserProject();
          } else {
            this.selectUserProject();
          }
        this.projectService.setCurrentProject(this.selectedProject);
        
        this.skeleton = false;
      })

    }

  }

  private selectUserProject() {
    if (this.projects !== null) {
      // if (this.projects.length === 1) {
      // this.selectedProject = <any>this.projects.find(project => project.isDefault);
      this.setUserProject();
      // }
    }
  }

  setUserProject() {
    this.projectService.setCurrentProject(this.selectedProject);
    window.localStorage.setItem(USER_SELECTED_APPLICATION, JSON.stringify(this.selectedProject));

  }

  setDefaultProject() {

  }

  get isTestManager(): boolean {
    return this.authenticationService.hasRole(RoleEnum.TestManager);

  }

  get isAdmin(): boolean {
    return this.authenticationService.hasRole(RoleEnum.SuperAdmin);
  }

  get isTestEngineer(): boolean {
    return this.authenticationService.hasRole(RoleEnum.TestEngineer);
  }

  getUser() {
    let user: ManageUser = this.userStorageService.getUser();
    this.user = user;
    if (Object.keys(user).length > 0) {
      if(user.user &&  user.user.lastName !== null){
         this.loggedInUser = user.user.name + ' ' + user.user.lastName; 
      }else if(user.user && user.user.lastName === null){
        this.loggedInUser = user.user.name
      }
      this.userOrganization = user.organization;
      this.userApplications = user.applications;
      this.loggedInUserId = user.user.userId;
    } else {
      this.authenticationService.logout(true).subscribe(data => {
        console.log(data);
        
      })
    }

    return user;

  }

  changeProject() {    
    this.isScreenLoading = true;
    if (this.router.url.indexOf('scripts') > -1) {
      this.confirm.confirm({
        message: 'Are you sure that you want to change the project? This action will redirect to the dashboard.',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          
          this.isScreenLoading = false
          this.markAsSelected(false);
          this.router.navigate(['/dashboard']);
        },
        reject: (type: any) => {
          this.isScreenLoading = false
          if (this.userStorageService.getUserSelectedProject()) {
           this.selectedProject = <any>this.projects.find(project => project.applicationId === this.userStorageService.getUserSelectedProject().applicationId);;
          }

        }
      });
    }
    if (this.router.url.indexOf('dashboard') > -1) {
      this.markAsSelected(false);
      setTimeout(() => {
        this.isScreenLoading = false
      }, 1500);
    } else {
      this.isScreenLoading = false;
      this.confirm.confirm({
        message: 'Are you sure that you want to change the project? This action will redirect to the dashboard.',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.isScreenLoading = false
          this.markAsSelected(false);
          this.router.navigate(['/dashboard']);
        },
        reject: (type: any) => {
          this.isScreenLoading = false
          if (this.userStorageService.getUserSelectedProject()) {
            this.selectedProject = <any>this.projects.find(project => project.applicationId === this.userStorageService.getUserSelectedProject().applicationId);;
          }

        }
      });

    }
  }

  markAsSelected(fetchProjects: boolean) {
    
    let self = this;
    const isSelected: string = 'true';
    const suappId = localStorage.getItem(SUAPP_ID) || "";
    this.subs.sink = this.projectService.markAsSelected(this.loggedInUserId, this.selectedProject.applicationId, 
      isSelected, suappId, this.selectedProject).subscribe({
      next(res) {
        
        self.projects.forEach(project => {
          if (project.applicationId === res.result.id.applicationId) {
            project.isSelected = <any>isSelected;
            self.projectService.setCurrentProject(project);            
            window.localStorage.setItem(USER_SELECTED_APPLICATION, JSON.stringify(project));
            if (fetchProjects) {
              self.getProjects();
            }
          }
        });
      },
      error(error) {
        console.log(error);
      },
      complete() {
      }
    });


    //    window.location.reload();
    //    this.isScreenLoading = false;
    //  })
  }

  async loadApplicationVariables(appId: number){
    const applicationVariables = await this.variableService.findByApplicationId(appId).toPromise(); 
    if (applicationVariables) {
      this.masterService.setApplicationVariables(applicationVariables.result);
    }
  }

}