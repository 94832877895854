import {
    CUSTOM_ELEMENTS_SCHEMA,
    ModuleWithProviders,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

import { ButtonModule } from 'primeng/button'
import { InputTextModule } from 'primeng/inputtext'
import { CheckboxModule } from 'primeng/checkbox'
import { CardModule } from 'primeng/card'
import { MenuModule } from 'primeng/menu'
import { AvatarModule } from 'primeng/avatar'
import { AvatarGroupModule } from 'primeng/avatargroup'
import { MenubarModule } from 'primeng/menubar'
import { TieredMenuModule } from 'primeng/tieredmenu'
import { SidebarModule } from 'primeng/sidebar'
import { TableModule } from 'primeng/table'
import { PaginatorModule } from 'primeng/paginator'
import { ToggleButtonModule } from 'primeng/togglebutton'
import { FieldsetModule } from 'primeng/fieldset'
import { InputSwitchModule } from 'primeng/inputswitch'
import { ToastModule } from 'primeng/toast'
import { CalendarModule } from 'primeng/calendar'
import { SliderModule } from 'primeng/slider'
import { MultiSelectModule } from 'primeng/multiselect'
import { ContextMenuModule } from 'primeng/contextmenu'
import { DialogModule } from 'primeng/dialog'
import { DropdownModule } from 'primeng/dropdown'
import { ProgressBarModule } from 'primeng/progressbar'
import { FileUploadModule } from 'primeng/fileupload'
import { ToolbarModule } from 'primeng/toolbar'
import { RatingModule } from 'primeng/rating'
import { RadioButtonModule } from 'primeng/radiobutton'
import { InputNumberModule } from 'primeng/inputnumber'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { TabViewModule } from 'primeng/tabview'
import { ChipsModule } from 'primeng/chips'
import { TooltipModule } from 'primeng/tooltip'
import { ChipModule } from 'primeng/chip'
import { TagModule } from 'primeng/tag'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { PanelModule } from 'primeng/panel'
import { PickListModule } from 'primeng/picklist'
import { UserRoleDirective } from '../../core/common/directive/user-role.directive'

import { TreeTableModule } from 'primeng/treetable'
import { ChartsModule } from '@rinminase/ng-charts'
import { GalleriaModule } from 'primeng/galleria'
import { SkeletonModule } from 'primeng/skeleton'
import { SelectButtonModule } from 'primeng/selectbutton'
import { SafeUrlPipe } from 'src/app/core/common/pipe/safe.pipe'
import { ConfigFormComponent } from '../config/components/config-form.component'
import { AddEnvironmentComponent } from '../config/components/add-environment.component'
import { MessagesModule } from 'primeng/messages'
import { AccordionModule } from 'primeng/accordion'
import { VariablesListingComponent } from 'src/app/variables/components/variables-listing.component'
import { AddVariableComponent } from 'src/app/variables/components/add-variable.component'
import { InplaceModule } from 'primeng/inplace'
import { NgSelectModule } from '@ng-select/ng-select'
import { DividerModule } from 'primeng/divider'
import { NgJsonEditorModule } from '@maaxgr/ang-jsoneditor'
import { allIcons, HeroIconModule } from 'ng-heroicon'
import {EditorModule} from 'primeng/editor';
import { UploadFileComponent } from '../tests/scripts/components/upload-file/upload-file.component'
import { ScheduleFormComponent } from '../execute/components/schedule-form.component'
import { ExecuteDataComponent } from '../execute/components/execute-data.component'
import { SettingsFormComponent } from '../execute/components/settings-form.component'
import { ScheduleListComponent } from '../execute/components/schedule-list.component'
import { SettingsListComponent } from '../execute/components/settings-list.component'
import { LoadingIndicatorComponent } from '../../loading-indicator/loading-indicator.component'

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const config: SocketIoConfig = { url: 'http://localhost:8081', options: {} };

@NgModule({
    declarations: [
        UserRoleDirective,
        ConfigFormComponent,
        AddEnvironmentComponent,
        SafeUrlPipe,
        AddVariableComponent,
        VariablesListingComponent,
        UploadFileComponent,
        ScheduleFormComponent,
        ExecuteDataComponent,
        SettingsFormComponent,
        ScheduleListComponent,
        SettingsListComponent,
        LoadingIndicatorComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        InputTextModule,
        CheckboxModule,
        CardModule,
        MenuModule,
        AvatarModule,
        AvatarGroupModule,
        MenubarModule,
        TieredMenuModule,
        SidebarModule,
        TableModule,
        PaginatorModule,
        ReactiveFormsModule,
        FormsModule,
        ToggleButtonModule,
        FieldsetModule,
        InputSwitchModule,
        CalendarModule,
        SliderModule,
        DialogModule,
        MultiSelectModule,
        ContextMenuModule,
        DropdownModule,
        ToastModule,
        ProgressBarModule,
        FileUploadModule,
        ToolbarModule,
        RatingModule,
        RadioButtonModule,
        InputNumberModule,
        ConfirmDialogModule,
        InputTextareaModule,
        TabViewModule,
        ChipsModule,
        ProgressSpinnerModule,
        TooltipModule,
        PanelModule,
        PickListModule,
        ChipModule,
        TreeTableModule,
        TagModule,
        ChartsModule,
        GalleriaModule,
        AutoCompleteModule,
        SkeletonModule,
        SelectButtonModule,
        MessagesModule,
        AccordionModule,
        InplaceModule,
        NgSelectModule,
        DividerModule,
        OverlayPanelModule,
        NgJsonEditorModule,
        EditorModule,
        SocketIoModule.forRoot(config),
        HeroIconModule.withIcons(
            {
                ...allIcons,
            },
            {
                /**
                 * Child level options.
                 * Non passed options will use the rootModule options.
                 */
                defaultHostDisplay: 'block',
            }
        ),
    ],
    exports: [
        CommonModule,
        ButtonModule,
        InputTextModule,
        CheckboxModule,
        CardModule,
        MenuModule,
        AvatarModule,
        AvatarGroupModule,
        MenubarModule,
        TieredMenuModule,
        SidebarModule,
        TableModule,
        PaginatorModule,
        ReactiveFormsModule,
        FormsModule,
        ToggleButtonModule,
        FieldsetModule,
        InputSwitchModule,
        CalendarModule,
        SliderModule,
        DialogModule,
        MultiSelectModule,
        ContextMenuModule,
        DropdownModule,
        ToastModule,
        ProgressBarModule,
        FileUploadModule,
        ToolbarModule,
        RatingModule,
        RadioButtonModule,
        InputNumberModule,
        ConfirmDialogModule,
        InputTextareaModule,
        TabViewModule,
        ChipsModule,
        ProgressSpinnerModule,
        TooltipModule,
        UserRoleDirective,
        PanelModule,
        PickListModule,
        ChipModule,
        TreeTableModule,
        TagModule,
        ChartsModule,
        GalleriaModule,
        AutoCompleteModule,
        SkeletonModule,
        SelectButtonModule,
        SafeUrlPipe,
        ConfigFormComponent,
        AddEnvironmentComponent,
        MessagesModule,
        AccordionModule,
        AddVariableComponent,
        VariablesListingComponent,
        InplaceModule,
        NgSelectModule,
        DividerModule,
        OverlayPanelModule,
        NgJsonEditorModule,
        UploadFileComponent,
        EditorModule,
        ScheduleFormComponent,
        ExecuteDataComponent,
        SettingsFormComponent,
        ScheduleListComponent,
        SettingsListComponent,
        LoadingIndicatorComponent
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        }
    }
}
