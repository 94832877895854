import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginSessionHistoryService {
  constructor() { }

  updateSessionData(key: string, newData: any): void {
    const currentData = this.getSessionData(key) || [];
    // If currentData is an array, append; otherwise, replace or merge
    if (Array.isArray(currentData)) {
      currentData.push(newData); // Append new data to the array
    } else {
      // For object data, you could merge or replace depending on your needs
      // Example for a simple merge (this will not deep merge properties)
      Object.assign(currentData, newData);
    }
    sessionStorage.setItem(key, JSON.stringify(currentData));
  }

  getSessionData(key: string): any {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  clearSessionData(key: string): void {
    sessionStorage.removeItem(key);
  }

  clearAllSessionData(): void {
    sessionStorage.clear();
  }
}
