<!-- <p-dialog header="{{heading}} Variable" (onShow)="activeTabIndex = 0" (onHide)="close()" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-mt-2 p-col-12 p-md-6">
            <span class="steric">*</span>
            <input id="variableName" [(ngModel)]="variableName" type="text" placeholder="Name" pInputText> 
        </div>
        <div class="p-field p-mt-2 p-col-12 p-md-6">
            <span class="steric">*</span>
            <input id="variableValue" [(ngModel)]="variableValue" type="text" placeholder="Value" pInputText> 
            
        </div>
        <div class="p-field p-col-12">
            <textarea id="description" [(ngModel)]="variableDescription" type="text" rows="4" placeholder="Description" pInputTextarea></textarea>
        </div>
    </div>
    <div class="p-f-right p-mb-3">
        <button pButton pRipple type="button" class="cancel-btn p-mr-2" label="Cancel" icon="pi pi-times"
        (click)="close()"></button>
        <button [disabled]="!variableName" pButton pRipple type="button" label="{{heading === 'Add' ? 'Save' : 'Update'}}" icon="pi pi-check"
            (click)="save()"></button>
    </div>
</p-dialog> -->
<div class="p-col-12" id="variables">
    <div *ngIf="isScreenLoading">
        <p-progressSpinner></p-progressSpinner>
        <div class="progressspinner-overlay"></div>
    </div>

    <h5>{{heading}} Variable</h5>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label> Name
                <span class="steric">*</span>
              </label>
            <input id="variableName" [(ngModel)]="variableName" type="text" placeholder="Name" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label> Value
                <span class="steric">*</span>
              </label>
            <input id="variableValue" [(ngModel)]="variableValue" type="text" placeholder="Value" pInputText>
        </div>
        <div class="p-field p-col-12">
            <textarea id="description" [(ngModel)]="variableDescription" type="text" rows="4" placeholder="Description"
                pInputTextarea></textarea>
        </div>

        <div class="p-col-12">
            <div class="p-d-flex p-f-right">
                <button pButton pRipple type="button"
                    class="p-mr-1 p-f-right p-button-outlined p-button p-component" label="Cancel"
                    icon="pi pi-times" (click)="close()"></button>
                <button [disabled]="!variableName || !variableValue" pButton pRipple
                    class="p-ml-1 p-f-right p-ripple p-button p-component" type="button"
                    label="{{heading === 'Add' ? 'Save' : 'Update'}}" icon="pi pi-check" (click)="save()"></button>
            </div>
        </div>
    </div>
</div>