import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { mapValues } from 'lodash';
import { ConfirmationService, FilterMetadata, LazyLoadEvent, MessageService } from 'primeng/api';
import { PAGINATION_SIZE_FIVE, START_INDEX } from 'src/app/constants';
import { MasterDetailCommands } from 'src/app/core/master-detail-command';
import { Frequencies } from 'src/app/core/models/enum';
import { UnsubscribeOnDestroyAdapter } from 'src/app/core/unsubscribe-on-destroy-adapter';
import { UserStorageService } from 'src/app/core/_helpers/_services/user-storage.service';
import { ManageUser } from '../../manageuser/manage-user';
import { Project } from '../../projects/project';
import * as moment from 'moment';
import { ScheduleTest, Settings } from '../schedule-test';
import { ScheduleTestService } from '../services/schedule-test.service';
import { SettingsService } from '../../settings/services/settings.service';
@Component({
    selector: 'app-settings-list',
    templateUrl: '../pages/settings-list.component.html',
    styleUrls: []
})
export class SettingsListComponent extends UnsubscribeOnDestroyAdapter implements MasterDetailCommands<Settings>, OnInit, OnChanges {

    @Input() refresh!: boolean;
    scheduleList!: Array<Settings>;
    totalRecords!: number;
    isScreenLoading!: boolean;
    displayDialog!: boolean;
    @Output() formValues: EventEmitter<any> = new EventEmitter<any>();
    scheduleInfo!: Settings;
    isGridLoading!: boolean;
    selectedTest!: Settings;
    selectedId!: number;
    paginationSize = PAGINATION_SIZE_FIVE;

    constructor(private scheduleTestService: ScheduleTestService,
        private userStorage: UserStorageService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private settingsService: SettingsService,
        private datePipe: DatePipe) {
        super();
    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes.refresh.currentValue) {
            console.log("in get all refresh");
            this.getAll();
        }
    }

    ngOnInit() {

        this.getAll();
    }

    save() {

    }

    getAll() {
        console.log("in get all");
        
        this.loadPaginated(this.userStorage.getUserSelectedProject().applicationId, this.paginationSize, START_INDEX);
    }

    close() {

    }

    delete(testInfo: Settings) {
        this.isGridLoading = true;
        this.subs.sink = this.settingsService.deleteSchedule(testInfo.jobId).subscribe(res => {
            this.scheduleList = this.scheduleList.filter(val => val.jobId !== testInfo.jobId);
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: res.message, life: 3000 });
            this.isGridLoading = false;
        });
    }

    select() {

    }

    update() {
        this.isScreenLoading = true
        let scheduleInfo: Settings = new Settings()
        scheduleInfo = mapValues(this.scheduleInfo);
        scheduleInfo.consoleLogs = scheduleInfo.consoleLogs.toString()
        scheduleInfo.modifiedBy = this.getUser().user.userId;
        this.settingsService.updateService(this.selectedId, scheduleInfo).subscribe(res => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: res.message, life: 3000 });
            this.scheduleList[this.findIndexById(res.result.jobId)] = res.result;
            this.isScreenLoading = false;
        })
        this.displayDialog = false;
    }

    findIndexById(id: number): number {
        let index = -1;
        for (let i = 0; i < this.scheduleList.length; i++) {
            if (this.scheduleList[i].jobId === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    getUser(): ManageUser {
        return this.userStorage.getUser();
    }

    private filteredResults(id: number, value: string) {
        this.subs.sink = this.scheduleTestService
            .findFiltered(id, value)
            .subscribe((res) => {
                this.scheduleList = res.result;
                this.isScreenLoading = false;
            });
    }

    private loadPaginated(id: number, rowsPerPage: number, page: number) {
        this.isScreenLoading = true;
        const email = this.getUser().user.email
        const settings: Settings = new Settings;
        settings.applicationId = id;
        settings.userEmail = email; 
        settings.rowsPerPage = rowsPerPage;
        settings.startIndex = page;
        this.subs.sink = this.settingsService
            .findSettingsTestsByApplicationId(settings)
            .subscribe((res) => {
                this.scheduleList = res.result.content;
                this.totalRecords = res.result.totalElements;
                this.isScreenLoading = false;
            });
    }


    nextPage(event: LazyLoadEvent) {
        console.log(event);

        const first = event.first;

        let page = 0;
        if (first == 0) {
            page = 0;
        } else {
            page = <number>first / <number>event.rows;
        }
        const id = this.getProject().applicationId;

        const rowsPerPage = <number>event.rows;
        this.loadPaginated(id, rowsPerPage, page);
    }
    getProject(): Project {
        return this.userStorage.getUserSelectedProject();
    }

    deleteScheduled(testInfo: Settings) {
        if (testInfo) {
            this.confirmationService.confirm({
                message: "Are you sure you want to delete " + testInfo.testName + "?",
                header: "Delete Confirmation",
                icon: "pi pi-info-circle",
                accept: () => {
                    this.delete(testInfo);
                },
            });
        }

    }

    showDialog(test: Settings) {
        this.displayDialog = true;
        this.selectedTest = test;
        this.selectedId = test.jobId;
    }

    getSchedularInfo(event: any) {
        // this.formValues.emit(event);
        this.scheduleInfo = event;
    }

    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.includes('/'))) {
            const dateTime = value.split(" ");
            const dateStr = dateTime[0].split('/');
            const timeStr = dateTime[1].split(':');
            const hr = Number(timeStr[0]);
            const minute = Number(timeStr[1]);

            const year = Number(dateStr[2]);
            const month = Number(dateStr[1]) - 1;
            const date = Number(dateStr[0]);

            return new Date(year, month, date, hr, minute);
        } else if ((typeof value === 'string') && value === '') {
            return new Date();
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    /* To copy any Text */
    copyText(val: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.messageService.add({ detail: "URL copied to clipboard", life: 5000, closable: true, severity: 'info' })
    }
}
