<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-mt-2 p-col-12">
        <p-fileUpload
            name="demo[]"
            (onSelect)="onFileSelect($event)"
            (onRemove)="onFileClear($event)"
            [customUpload]="customUpload"
            (uploadHandler)="onUpload($event)"
            [maxFileSize]="maxFileSize"
            invalidFileSizeMessageDetail="Maximum upload size is 2mb"
            accept=".xlsx, .xls"
        >
        </p-fileUpload>
    </div>
</div>

<p-table
    *ngIf="uploadedFile"
    [columns]="cols"
    [value]="stepsData"
    [rowHover]="true"
    styleClass="p-datatable-striped p-datatable-responsive-demo gtp-table"
    [scrollable]="true"
    scrollHeight="250px"
    [responsive]="true"
>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th
                *ngFor="let col of columns"
                class="col-ellipsis"
                [pTooltip]="col.header"
                tooltipPosition="top"
            >
                {{ col.header }}
            </th>
        </tr>
        <tr>
            <td *ngFor="let col of columns; let i = index">
                <div class="p-col-12">
                    <p-dropdown
                        placeholder="Value"
                        appendTo="body"
                        [(ngModel)]="dropdownValue[i]"
                        [options]="dataDriverDropdownItems"
                        optionLabel="value"
                        optionValue="value"
                    >
                    </p-dropdown>
                </div>
            </td>
            <!-- <td>
                <div class="p-col-12">
                    <p-dropdown placeholder="Element" [showClear]="true">
                    </p-dropdown>
                </div>
            </td>
            <td>
                <div class="p-col-12">
                    <p-dropdown placeholder="Value" [showClear]="true">
                    </p-dropdown>
                </div>
            </td>
            <td>
                <div class="p-col-12">
                    <p-dropdown placeholder="Element" [showClear]="true">
                    </p-dropdown>
                </div>
            </td> -->
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns" class="col-ellipsis">
                {{ rowData[col.field] }}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody" let-columns="columns">
        <tr style="height: 34px">
            <td *ngFor="let col of columns">
                <p-skeleton [ngStyle]="{ width: '100%' }"></p-skeleton>
            </td>
        </tr>
    </ng-template>
</p-table>