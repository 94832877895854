<div *ngIf="isScreenLoading">
  <p-progressSpinner></p-progressSpinner>
  <div class="progressspinner-overlay"></div>
</div>
<div class="p-d-flex p-jc-center">
  <div class="p-col-12 p-d-flex p-jc-center main-box">
    <div class="p-col-5 login-bg"></div>
    <div
      class="p-col-12 p-sm-12 p-md-5 p-lg-3"
      style="padding-left: 0rem; padding-bottom: 0rem"
    >
    <div class="p-m-6">
        <div class="p-d-flex p-jc-center">
          <div class="logo-login">
            <img src="assets/gtp-icon.svg" alt="GoTestPro" />
            <img src="assets/rc-teaf-logo.svg" alt="GoTestPro"/>
          </div>
        </div>
        <h6 class="p-text-center">Forgot Password?</h6>
          <div class="p-fluid">
            <div class="p-field">
              <input
                name="email"
                required
                pInputText
                placeholder="Email"
                [(ngModel)]="email"
              />
            </div>
            <div class="p-col-12 p-text-center" style="padding-left: 0rem">
              <button
                pButton
                class="login-btn p-button p-component p-mb-2"
                type="submit"
                label="Submit"
                [disabled]="!email"
                (click)="submitHandler()"
              ></button>
            </div>
          </div>
    </div>
    </div>
  </div>
</div>






















<!-- 

<div class="p-d-flex p-jc-center">
  <div class="p-col-10 p-d-flex p-jc-center main-box">
    <div class="p-col-5 login-bg"></div>
    <div
      class="p-col-12 p-sm-12 p-md-8 p-lg-5"
      style="padding-left: 0rem; padding-bottom: 0rem"
    >
    <div class="p-m-6">
        <div class="p-d-flex p-jc-center">
          <div class="logo-login">
            <img src="assets/gtp-icon.svg" alt="GoTestPro" />
            <img src="assets/rc-teaf-logo.svg" alt="GoTestPro"/>
          </div>
        </div>
        <h6 class="p-text-center">Forgot Password</h6>
        <div class="p-fluid">
          <div class="p-field">
            <input
              name="email"
              pInputText
              placeholder="Email"
              [(ngModel)]="email"
            />
          </div>
          <div class="p-field p-text-center">
            <div class="p-col-3">
              <button
                pButton
                class="login-btn"
                type="submit"
                label="Submit"
                [disabled]="!email"
                (click)="submitHandler()"
              ></button>
            </div>
          </div>
        </div>
    </div>
    </div>
    
  </div>
</div> -->
