import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserStorageService } from './user-storage.service';
import { environment } from 'src/environments/environment';
import { IResponse } from '../../generic-operations/operations/responce.interface';

const AUTH_API = 'http://localhost:8081/api/user/login';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, private tokenStorage: UserStorageService) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API, {
      email,
      password
    }, httpOptions);
  }

  decodeToken(token: string): any {
    const jwtHelper = new JwtHelperService();
    return jwtHelper.decodeToken(token);
  }

   // Example method to make a request with a custom header
   loginWithOkta(organizationId: number) {
    const headers = new HttpHeaders({
      'X-Organization-Id': organizationId.toString()
    });

    return this.http
    .get<IResponse>(`${environment.api.baseUrl}/auth/okta-login?org=${organizationId.toString()}`, { headers });
  }

  isLoggedIn() : boolean{   
  
    // create an instance of JwtHelper class.  
    let jwtHelper = new JwtHelperService();  
  
    // get the token from the localStorage as we have to work on this token.  
    let token = this.tokenStorage.getToken();  
  
    // check whether if token have something or it is null.  
    if(!token)  
    {  
      return false;  
    }  
  
    // get the Expiration date of the token by calling getTokenExpirationDate(String) method of JwtHelper class. this method accepts a string value which is nothing but a token.  
  
    if(token)  
    {  
      let expirationDate = jwtHelper.getTokenExpirationDate(token);  
  
      // check whether the token is expired or not by calling isTokenExpired() method of JwtHelper class.  
  
      let isExpired = jwtHelper.isTokenExpired(token);  
  
      return !isExpired;      
    }   
    
    return true;
  }  
}