<!--<p-dropdown class="p-f-right p-mb-3" [options]="scheduleFiltersItems" (onChange)="onFilterChange($event)" [(ngModel)]="selectedFilter" optionLabel="label" ></p-dropdown>-->
<p-table styleClass="p-datatable-striped p-datatable-responsive-demo gtp-table px-5" [value]="_data?.tests?.content" [rows]="paginationSize" [paginator]="true" [totalRecords]="totalRecords"
(onLazyLoad)="nextPage($event)" [lazy]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
    <ng-template pTemplate="header">
        <tr>
            <th>Test Plan </th>
            <th>Test Name </th>
            <!--<th>Frequency </th>-->
            <th>Date & Time </th>
            <!--<th>View Last Run Result </th> -->
            <th>Owner</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
        <tr>
            <td>{{product.tagName}}</td>
            <td><a href="javascript:;" routerLink="/results/{{getProject()}}/details/{{product.scriptResultId}}/Completed">{{product.scriptName}}</a>  </td>
            <td> {{product.endDateTime  | date:'yyyy-MM-dd HH:mm'}}</td>
            <td>{{product.userEmail}} </td>
        </tr>
    </ng-template>
</p-table>