import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { Resolutions } from "src/app/core/models/enum";
import { environment } from "src/environments/environment";
import { ConfigService } from "../services/config.service";
import { TestEvironmentBrowerDetails } from "../test-environment-browser-details";
@Component({
  selector: "app-add-environment",
  templateUrl: "../pages/add-environment.component.html",
  styles: [
    `
      .config-dropdown img: {
        width: 50px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEnvironmentComponent implements OnInit {
  loggedInUserId: any;
  public isScreenLoading: boolean = false;
  @Input() heading!: string;
  @Input() edit!: boolean;
  @Input() displayModal!: boolean;
  public _platforms: any = null;
  public browserNames: any[] = [];
  browserNamesItems!: SelectItem[];
  public browserVersions: any[] = [];
  browserVersionsItems!: SelectItem[];
  resolutions = Resolutions;
  resolutionsItems!: SelectItem[];
  public selectedOS: any = null;
  public selectedPlatform: any = null;
  public selectedBrowserName: string = "";
  public selectedBrowserVersion: string = "";
  public selectedResolution: string = "";
  public resolution: string = "";
  public dekstopPlatforms: any[] = [];
  public groupedPlatforms: any[] = [];
  public allDevices: any[] = [];
  public _mobileDevices: any[] = [];
  public env = environment
  public localEnvs: any[] = [
    {
      "os": "Windows",
      "os_version": "11",
      "browser": "chrome",
      "device": null,
      "browser_version": "111.0",
      "real_mobile": null
    },
    {
      "os": "Windows",
      "os_version": "10",
      "browser": "chrome",
      "device": null,
      "browser_version": "111.0",
      "real_mobile": null
    },
    {
      "os": "Windows",
      "os_version": "11",
      "browser": "firefox",
      "device": null,
      "browser_version": "111.0",
      "real_mobile": null
    },
    {
      "os": "Windows",
      "os_version": "10",
      "browser": "firefox",
      "device": null,
      "browser_version": "111.0",
      "real_mobile": null
    },
    {
      "os": "Windows",
      "os_version": "11",
      "browser": "edge",
      "device": null,
      "browser_version": "111.0",
      "real_mobile": null
    },
    {
      "os": "Windows",
      "os_version": "10",
      "browser": "edge",
      "device": null,
      "browser_version": "111.0",
      "real_mobile": null
    }
  ]
  @Input()
  set platforms(data: any[]) {
    if (data) {
      this._platforms = data;
      // this.groupPlatforms();
    }
  }
  @Input()
  set mobileDevices(data: any[]) {
    if (data) {
      this._mobileDevices = data;
      this.groupPlatforms();
    }
  }

  public gridId: any = 0
  @Input()
  set grid(data: any) {
    console.log(data);
    this.gridId = data
    this.groupPlatforms();
  }

  @Output() browserDetailsEmitter =
    new EventEmitter<TestEvironmentBrowerDetails>();

  @Output() closeModal = new EventEmitter<boolean>();
  constructor(private _configService: ConfigService) { }

  ngOnInit(): void {
    this.selectedOS = { platform: "Windows 11", browsers: [], resolution: [] };
  }

  close = (val: any) => {
    this.displayModal = false;
    this.closeModal.emit(val);
  };

  extractBrowsers(): void {
    this.browserNamesItems = [];
    this.browserVersionsItems = [];
    this.selectedBrowserName = "";
    console.log(this.selectedPlatform);
    if (!this.env.production && this.gridId === 3) {
      console.log(this.selectedPlatform);
      this.browserNamesItems = [];
      this.browserNames = this.localEnvs.map((e: any) => ({
        label: e.browser,
        value: e.browser,
      }));
      this.browserNames = [...new Set(this.browserNames)];
      console.log(this.browserNames);

      this.browserNamesItems = this.browserNames.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.label === thing.label)
      );
      this.extractBrowserVersions();
    } else {
      if (this.selectedPlatform.value === "mobile") {
        const osVersion = this._mobileDevices.filter(
          (platform: any) => platform.deviceName === this.selectedPlatform.label
        );
        this.selectedOS = osVersion[0];
        const androidBrowsers = [
          {
            browser_name: "Firefox",
            version: "97.0",
          },
          {
            browser_name: "Chrome",
            version: "99.0",
          },
        ];
        const iosBrowsers = [
          {
            browser_name: "Firefox",
            version: "97.0",
          },
          {
            browser_name: "Safari",
            version: "15.0",
          },
        ];

        this.selectedOS.resolution = osVersion.map((e: any) => ({
          label: e.platformVersion,
          value: e.platformVersion,
        }));

        this.selectedOS.browsers =
          this.selectedOS.deviceName.includes("iPhone") ||
            this.selectedOS.deviceName.includes("ios") ||
            this.selectedOS.deviceName.includes("iPad") ||
            this.selectedOS.deviceName.includes("iPod")
            ? [...iosBrowsers]
            : [...androidBrowsers];
        this.selectedOS.deviceType = "mobile";
        this.selectedBrowserName = this.selectedOS.browsers[1].browser_name;
        this.selectedBrowserVersion = this.selectedOS.browsers[1].version;
      } else {
        this.selectedOS = this._platforms.Desktop.filter(
          (platform: any) => platform.platform === this.selectedPlatform.label
        )[0];
        this.selectedOS.deviceType = "desktop";
        this.selectedBrowserName = this.selectedOS.browsers[0].browser_name;
        this.selectedBrowserVersion = this.selectedOS.browsers[0].version;
        this.selectedResolution = this.selectedOS.resolution[0].value;
      }
      this.browserNamesItems = [];
      this.browserNames = this.selectedOS.browsers.map((e: any) => ({
        label: e.browser_name,
        value: e.browser_name,
      }));
      this.browserNames = [...new Set(this.browserNames)];
      this.browserNamesItems = this.browserNames.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.label === thing.label)
      );
      this.extractBrowserVersions();
    }
  }

  extractBrowserVersions(): void {
    this.browserVersionsItems = [];
    if (!this.env.production && this.gridId === 3) {
      const versions = this.localEnvs.filter(
        (e: any) => e.browser === this.selectedBrowserName
      );
      this.browserVersionsItems = versions.map((e: any) => ({
        label: e.browser_version,
        value: e.browser_version,
      }));

    } else {
      const versions = this.selectedOS.browsers.filter(
        (e: any) => e.browser_name === this.selectedBrowserName
      );
      this.browserVersionsItems = versions.map((e: any) => ({
        label: e.version,
        value: e.version,
      }));
      if (this.selectedOS.deviceType === "mobile") {
        this.selectedBrowserVersion = this.selectedOS.browsers.filter(
          (e: any) => e.browser_name === this.selectedBrowserName
        )[0].version;
      }
    }
  }
  save(): void {


    if (!this.env.production && this.gridId === 3) {
      let browserDetails = new TestEvironmentBrowerDetails();
      browserDetails.id = Math.random().toString();
      browserDetails.browserName = this.selectedBrowserName;
      browserDetails.browserVersion = this.selectedBrowserVersion;
      const obj = {
        platform: this.selectedPlatform.label,
        deviceName: null,
        resolution: "",
      };
      console.log(obj);
      this.close(obj);
      this.browserDetailsEmitter.emit(browserDetails);
    } else {
      let browserDetails = new TestEvironmentBrowerDetails();
      browserDetails.id = Math.random().toString();
      browserDetails.browserName = this.selectedBrowserName;
      browserDetails.browserVersion = this.selectedBrowserVersion;
      const obj = {
        platform: this.selectedOS.platform || this.selectedOS.platformName,
        deviceName: this.selectedOS.deviceName,
        resolution: this.selectedResolution || "",
        environmentType: this.selectedOS.deviceType.toUpperCase()
      };
      console.log(obj);
      this.close(obj);
      this.browserDetailsEmitter.emit(browserDetails);
    }



    this.selectedOS = { platform: "", browsers: [], resolution: [] };
    this.selectedBrowserName = "";
    this.selectedBrowserVersion = "";
    this.selectedResolution = "";
  }

  groupPlatforms(): void {
    const desktop = {
      label: "Desktop",
      value: "Desktop",
      items: this._platforms.Desktop.map((platform: any) => ({
        label: platform.platform,
        value: platform.platform.toLowerCase(),
      })),
    };
    this.allDevices = this._platforms.Mobile[0].devices.concat(
      this._platforms.Mobile[1].devices
    );
    // const iosDevices = this._platforms.Mobile.filter(
    //   (e: any) => e.platform === 'ios'
    // )[0];
    const iosAllDevices = this._mobileDevices.filter(
      (e: any) => e.platformName === "ios"
    );
    let androidAllDevices = this._mobileDevices.filter(
      (e: any) => e.platformName === "android"
    );
    console.log(iosAllDevices);

    // const androidDevices = this._platforms.Mobile.filter(
    //   (e: any) => e.platform === 'android'
    // )[0];

    const iosDevices = this.removeDuplicates(
      iosAllDevices,
      "deviceName"
    );
    const androidDevices = this.removeDuplicates(
      androidAllDevices,
      "deviceName"
    );
    const iosObj = {
      label: "iOS",
      value: "iOS",
      items: iosDevices.map((platform: any) => ({
        label: platform.deviceName,
        value: "mobile",
      })),
    };
    const iandroidObj = {
      label: "Android",
      value: "Android",
      items: androidDevices.map((platform: any) => ({
        label: platform.deviceName,
        value: "mobile",
      })),
    };
    // this.groupedPlatforms.push(desktop);
    if (!this.env.production && this.gridId === 3) {
      this.dekstopPlatforms = this.localEnvs.map((platform: any) => ({
        label: platform.os,
        value: platform.os.toLowerCase(),
      }));
      this.dekstopPlatforms = this.removeDuplicates(
        this.dekstopPlatforms,
        "label"
      );
      console.log(this.dekstopPlatforms);

    } else {
      this.dekstopPlatforms = this._platforms.Desktop.map((platform: any) => ({
        label: platform.platform,
        value: platform.platform.toLowerCase(),
      }));
    }

    this.groupedPlatforms.push(iosObj);
    this.groupedPlatforms.push(iandroidObj);
  }

  removeDuplicates = (myArr: any, prop: any) => {
    return myArr.filter((obj: any, pos: any, arr: any) => {
      return arr.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  };

  handleTabChange(event: any): void {
    if (event.index === 1) {
      this.groupedPlatforms;
    }
    this.selectedPlatform = null;
    this.selectedOS = { platform: "", browsers: [], resolution: [] };
    this.selectedBrowserName = "";
    this.selectedBrowserVersion = "";
    this.selectedResolution = "";
  }
}
