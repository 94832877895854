import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../core/_helpers/_services/auth.service";
import { UserStorageService } from "../core/_helpers/_services/user-storage.service";
import { AuthenticationService } from "../core/_helpers/_services/authentication.service";
import { MessageService } from "primeng/api";
import {
  AI_MODEL,
  AI_TOKEN,
  DEFAULT_AI_MODEL,
  DEFAULT_AI_TOKEN,
  DEFAULT_TEST_CASE_PROMPT_START,
  DEFAULT_TEST_SCENARIOS_PROMPT_START,
  DEFAULT_TOKEN_LENGTH,
  EXTENSION_ID,
  PUBLISHED_EXTENSION_KEY,
  SUAPP_ID,
  TEST_CASE_PROMPT,
  TEST_SCENARIO_PROMPT,
  TOKEN_LENGTH,
  USER_KEY,
  USER_SELECTED_APPLICATION,
  USER_SELECTED_ENTERPRISE_APPLICATION,
} from "../constants";
import { v4 as uuid } from "uuid";
import { environment } from "src/environments/environment";
import { log } from "console";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styles: [],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = "";
  roles: string[] = [];
  isScreenLoading: boolean = false;
  public isRemember: boolean = true;
  showSignupDialog!: boolean;
  displayDialog!: boolean;
  selectedOrganization!: any;
  organization!:any;
  regions = [
    {
      name: "us-central",
      value: "us-central",
    },
    {
      name: "europe-central",
      value: "europe-central",
    },
  ];

  organizations = [
    {
      name: "swbc",
      value: "SWBC",
    }
  ];

  public selectedRegion: string = this.regions[0].name
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

   ngOnInit(): void {
    if (this.authenticationService.isUserLoggedIn()) {
      this.authenticationService.setAuthentication(true);
      this.router.navigate(["/dashboard"]);
      return;
    }
    this.form = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required])],
      password: ["", Validators.required],
      selectedRegion: [],
    });

    if (window.location.href.includes("eu")) {
      this.selectedRegion = this.regions[1].name
      this.form.patchValue({
        selectedRegion: [this.regions[1].name],
      });
    } else {
      this.selectedRegion = this.regions[0].name
      this.form.patchValue({
        selectedRegion: [this.regions[0].name],
      });
    }
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      console.log('token', token);
      if (token) {
       
        // this.getUserDetails(token);
        localStorage.setItem(SUAPP_ID, uuid());
        localStorage.setItem('token', token);
        const tokenData = this.authService.decodeToken(token);
        this.getUserDetails(tokenData.email, "");
        // Redirect to the home page or any other route in your Angular app
        this.router.navigate(['/']);
      } else {
        // Handle the case where the token is missing or invalid
        console.error('Token not found in URL');
      }

      

     
    });
   
  }

  reloadPage(): void {
    window.location.reload();
  }

  showSelectOrgDialog(){
    this.displayDialog = true;
  }

  loginWithOkta(){
    setTimeout(() => {
      console.log("loginWithOkta");
      localStorage.setItem(SUAPP_ID, uuid());
      this.authService.loginWithOkta(117816).subscribe(
        (response) => {
          console.log("response", response);
          // Handle the response from Okta
          // You can perform any additional actions here
          window.location.href = response.result;
        },
        (error) => {
          console.error('Error logging in with Okta:', error);
          // Handle the error
        }
      );
      //window.location.href = `${environment.api.okta_URL}`;
    }, 1000);
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.isScreenLoading = true;
    
    const email = this.form.controls.email.value;
    const pass = this.form.controls.password.value;
    localStorage.setItem(SUAPP_ID, uuid());
              
    this.authenticationService.authenticate(email, pass).subscribe(
      (data) => {
        // localStorage.setItem('isRememberMe', this.isRemember.toString())
        // if (this.isRemember) {
        //   sessionStorage.setItem('isRememberMe', this.isRemember.toString())
        // } else {
        //   sessionStorage.removeItem('isRememberMe')
        // }
        this.getUserDetails(email, pass);
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error Message",
          detail: "Authentication Failed",
          life: 3000,
        });
        this.isScreenLoading = false;
      }
    );
  }

  private getUserDetails(email: any, pass: any) {
    this.authenticationService.userDetails(email, pass).subscribe(
      (userData) => {
        if (userData["result"]["user"].isActive === -1) {
          return;
        } else {

          this.isLoggedIn = true;
          this.authenticationService.setAuthentication(true);
          console.log(uuid());
          localStorage.setItem(
            USER_KEY,
            JSON.stringify(userData["result"])
          );
          localStorage.setItem(
            USER_KEY,
            JSON.stringify(userData["result"])
          );
          localStorage.setItem(EXTENSION_ID, PUBLISHED_EXTENSION_KEY);
          localStorage.setItem(
            TEST_CASE_PROMPT,
            DEFAULT_TEST_CASE_PROMPT_START
          );
          localStorage.setItem(
            TEST_SCENARIO_PROMPT,
            DEFAULT_TEST_SCENARIOS_PROMPT_START
          );
          localStorage.setItem(TOKEN_LENGTH, DEFAULT_TOKEN_LENGTH);
          localStorage.setItem(AI_MODEL, DEFAULT_AI_MODEL);
          localStorage.setItem(AI_TOKEN, DEFAULT_AI_TOKEN);
          localStorage.setItem(USER_SELECTED_APPLICATION, JSON.stringify(userData["result"]["selectedApplication"]));
          if (userData["result"]["selectedApplication"]) {
            if (userData["result"]["selectedApplication"]["rcTeafEnterpriseApplication"] !== null)
              localStorage.setItem(USER_SELECTED_ENTERPRISE_APPLICATION, JSON.stringify(userData["result"]["selectedApplication"]["rcTeafEnterpriseApplication"]));
          }
          // localStorage.setItem(STEPS_PROMPT, DEFAULT_SCENARIO_STEPS_PROMPT_START);
          this.messageService.add({
            severity: "success",
            summary: "Success Message",
            detail: "Login Successful",
          });
          // if we have a redirect url then navigate to it,
          // else navigate to employees
          if (this.authenticationService.redirectUrl) {
            this.router.navigateByUrl(
              this.authenticationService.redirectUrl
            );
          } else {
            if (userData.result.selectedApplication !== null) {
              this.router.navigate(['/tests/listing']);
            } else {
              this.router.navigate(['/projects/listing']);
            }

          }
        }
        this.isScreenLoading = false;
      },
      (error) => {
        console.log(error);
        this.messageService.add({
          severity: "error",
          summary: "Error Message",
          detail: error.error.apierror.debugMessage,
          life: 3000,
        });
        this.isScreenLoading = false;
        this.authenticationService.logout(true).subscribe(
          (data) => {
            this.isLoggedIn = false;
          }
        );
      }
    );
  }

  showSignup() {
    this.showSignupDialog = true;
  }

  closeSignupModal(event: any) {
    this.showSignupDialog = event;
  }

  isPasswordVisible: boolean = false;
  showPassword(val: boolean) {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  changeRegion() {
    console.log(this.form.value);
    this.setCookie(
      this.form.value.selectedRegion,
      this.form.value.selectedRegion
    );
    if (this.form.value.selectedRegion === "europe-central") {
      window.open("http://eu.gotestpro.com", "_self");
    } else {
      window.open("http://app.gotestpro.com", "_self");
    }
  }

  setCookie(name: string, value: string) {
    let d: Date = new Date();
    d.setTime(d.getTime() + (d.getDate() + 1) * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    document.cookie = `region=${value};expires=${expires}`;
  }
}
