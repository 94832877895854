import { Organization } from "../organizations/organization";
import { OrganizationGrid } from "../organizations/organization-grid";
import { Project } from "../projects/project";
import { Role } from "./role";
import { User } from "./user";

export class ManageUser {
  //token: number;
	user!: User;
	role!: Role;
	organization!: Organization;
	applications!: Project[];
	
  }

