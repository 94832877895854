import { MenuItem } from "primeng/api";
import { Base } from "src/app/core/models/base.model";
import { Organization } from "../organizations/organization";
import { ProjectExecEnvs } from "./project-exec-env";
import { ProjectType } from "./project-type";
import { EnterpriseApplication } from "./enterprise-application";

export class Project extends Base {
	constructor(applicationId?: number,
		isActive?: string,
		applicationName?: string,
		description?: string,
		rcTeafAppExecEnvs?: ProjectExecEnvs[],
		applicationType?: ProjectType,
		isSelected?: boolean,
		jiraKey?: any,
		jiraName?: any,
		uuid?: string,
		isEnterprise?: any,
		rcTeafEnterpriseApplication?: any,
		azureProject?: string,
		azureParams?: any,
		updateEnterpriseModule?: boolean,
		page?: number) {
		super();
		this.applicationId = applicationId || 0;
		this.isActive = isActive || "";
		this.applicationName = applicationName || "";
		this.applicationDescription = description || "";
		this.rcTeafAppExecEnvs = rcTeafAppExecEnvs || new Array<ProjectExecEnvs>();
		this.rcTeafApplicationType = applicationType || new ProjectType();
		this.isSelected = isSelected || false;
		this.jiraKey = jiraKey || "";
		this.jiraName = jiraName || "";
		this.azureProject =  azureProject || '';
		this.uuid = uuid || "";
		this.isEnterprise = isEnterprise || 'false';
		this.rcTeafEnterpriseApplication = rcTeafEnterpriseApplication || null
		this.page || 0
		this.azureParams = azureParams || null
		this.updateEnterpriseModule = updateEnterpriseModule || false
	}
	applicationId!: number;

	applicationName!: string;

	isActive!: string;

	applicationDescription!: string;

	rcTeafOrganization!: Organization;

	rcTeafApplicationType!: ProjectType;

	rcTeafAppExecEnvs: ProjectExecEnvs[] = new Array<ProjectExecEnvs>();

	//  menuItems! : MenuItem[];

	scriptCount!: number;

	isSelected!: boolean;

	jiraKey?: any;
	jiraName?: any;
	azureProject?: string
	uuid!: string;
	isEnterprise!: any
	rcTeafEnterpriseApplication!: any
	page!: number
	createdDate!: any;
	modifiedDate!: any;
	azureParams!: any;
	updateEnterpriseModule!: boolean;
}
