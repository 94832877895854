import { Base } from 'src/app/core/models/base.model';
import { Project } from '../projects/project';
import { OrganizationGrid } from './organization-grid';

export class Organization extends Base {
  organizationId!: number;
  isActive!: string;
  organizationName!: string;
  rcTeafApplications!: Array<Project>;
  applitools!: string;
  trialStartDate?: string;
  trialEndDate?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
  cloudUser!: string;
  cloudPassword!: string;
  fullDate?: any;
  subscriptionFullDate?: any;
  gridId?: number;
  executionGrid!: OrganizationGrid;
  constructor(
    organizationId?: number,
    isActive?: string,
    organizationName?: string,
    applitools?: string,
    cloudPassword?: string,
    cloudUser?: string,
    subscriptionEndDate?: string,
    subscriptionStartDate?: string,
    trialStartDate?: string,
    trialEndDate?: string,
    gridId?: number,
    executionGrid?: OrganizationGrid
  ) {
    super();
    this.organizationId = organizationId || 0;
    this.isActive = isActive || '';
    this.organizationName = organizationName || '';
    this.applitools = applitools || '';
    this.cloudUser = cloudUser || '';
    this.cloudPassword = cloudPassword || '';
    this.subscriptionEndDate = subscriptionEndDate || '';
    this.subscriptionStartDate = subscriptionStartDate || '';
    this.trialEndDate = trialEndDate || '';
    this.trialStartDate = trialStartDate || '';
    this.gridId = gridId || 0;
    this.executionGrid = executionGrid || new OrganizationGrid;
  }
}
