import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/core/generic-operations/operations/responce.interface';
import { environment } from '../../../../environments/environment';
import { CrudService } from '../../../core/generic-operations/services/crud-service';
import { ManageUser } from '../manage-user';
import { User } from '../user';


@Injectable({
  providedIn:'root'
})
export class UserService extends CrudService<User, number>{

    constructor(protected http: HttpClient) {
        super(http, `${environment.api.baseUrl}/`, 'user', 'users');
      }

    checkRegistrationLinkExpiry(email: string){
      return this.http.get(`${environment.api.baseUrl}/user/check-link-expiry/`+email).pipe(
        map((o:IResponse): IResponse => ( { 
            code: o.code, 
            message: o.message,
            result: o.result 
        })));;
    }

    resendEmail(manageUser: ManageUser){
      return this.http.post(`${environment.api.baseUrl}/user/resend/`,manageUser).pipe(
        map((o:IResponse): IResponse => ( { 
            code: o.code, 
            message: o.message,
            result: o.result 
        })));;
    }

    signup(manageUser: ManageUser){
      return this.http.post(`${environment.api.publicUrl}/user/signup/`,manageUser).pipe(
        map((o:IResponse): IResponse => ( { 
            code: o.code, 
            message: o.message,
            result: o.result 
        })));;
    }
    
}