import { Base } from 'src/app/core/models/base.model'

export class ChangePassword extends Base {
  oldPassword!: string
  confirmPassword!: string
  email!: string
  password!: string
  constructor(oldPassword?: string, password?: string, confirmPassword?: string, email?: string)
  constructor(oldPassword?: string, password?: string, confirmPassword?: string, email?: string) {
    super()
    this.oldPassword = oldPassword || ''
    this.password = password || ''
    this.confirmPassword = confirmPassword || ''
    this.email = email || ''
  }
}
