import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from '@angular/core';
import { Scenario } from '../../scenario';
import { MessageService, SelectItem } from "primeng/api";
import { UnsubscribeOnDestroyAdapter } from 'src/app/core/unsubscribe-on-destroy-adapter';
import { UserStorageService } from 'src/app/core/_helpers/_services/user-storage.service';
import { MAX_UPLOAD_FILE_SIZE } from 'src/app/constants';
import { DataDrivenScenarioService } from '../../services/data-driven-scenario.service';
import { DataDrivenValues } from 'src/app/core/models/enum';

@Component({
  selector: 'app-upload-file',
  templateUrl: '../../pages/upload-file/upload-file.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFileComponent extends UnsubscribeOnDestroyAdapter implements  OnInit, OnChanges {
  cols!: any[];
  headers: any;
  dropdownValue: any;
  
  ngOnChanges(){

  }

  ngOnInit(){

  }
  
  constructor(private userStorageService: UserStorageService, 
    private messageService: MessageService, 
    private dataDrivenScenarioService: DataDrivenScenarioService){
    super();
  }
  dataDriverDropdown = DataDrivenValues;
  
  dataDriverDropdownItems!: SelectItem[];
  maxFileSize = MAX_UPLOAD_FILE_SIZE;
  customUpload: boolean = true;
  isDisableSubmit: boolean = false;
  uploadedFiles: any[] = [];
  public isScreenLoading: boolean = false;
  scenario!: Scenario;
  public uploadedFile: any = null;
  public stepsData: any[] = [];
  
  onFileSelect(event: { files: any }) {
    console.log(event);
    this.isDisableSubmit = true;
    this.uploadedFiles.length = 0;
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  onFileClear(event: any) {
    this.isDisableSubmit = false;
  }

  onUpload(event: any) {
    const formData = new FormData();
    formData.append("file", this.uploadedFiles[0]);
    const userId = this.userStorageService.getUser().user.userId;
    const applicationId =
      this.userStorageService.getUserSelectedProject().applicationId;
    this.isScreenLoading = true;
    this.dataDrivenScenarioService
      .postDataDrivenScenarioFile(
        applicationId,
        this.scenario.scenarioId,
        userId,
        formData
      )
      .subscribe(
        (res: any) => {
          setTimeout(() => {
            this.isDisableSubmit = false;
            this.isScreenLoading = false;
            this.uploadedFile = res.result;
            this.cols = new Array<any>();
            this.stepsData = JSON.parse(this.uploadedFile.stepsData);
            const elementList = this.uploadedFile.elementsList;
            this.headers = this.uploadedFile.headers.split(",");
            if (this.dropdownValue.length === 0) {
              for (let index = 0; index < this.headers.length; index++) {
                this.dropdownValue.push("value");
              }
            }
            for (const [key, value] of Object.entries(this.stepsData[0])) {
              this.cols.push({ field: key, header: key });
            }
            if (elementList !== null) {
              this.headers.forEach((key: any, value: any) => {
                if (elementList.indexOf(",") > -1) {
                  elementList.forEach((element: any) => {
                    if (element === key) {
                      this.dropdownValue[value] = DataDrivenValues.element;
                    }
                  });
                } else {
                  if (elementList === key) {
                    this.dropdownValue[value] = DataDrivenValues.element;
                  }
                }
              });
            }
          }, 500);
        },
        (error) => {
          console.log(error);

          this.messageService.add({
            severity: "error",
            summary: "error",
            detail: error.error.apierror.debugMessage,
            life: 9000,
          });
          this.isScreenLoading = false;
        }
      );
  }

  
  
}