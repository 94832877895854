import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageService, SelectItem } from "primeng/api";
import { Frequencies } from "src/app/core/models/enum";
import { environment } from "src/environments/environment";
import { ExecuteData } from "../execute-data";
import { ScheduleTest, Settings } from "../schedule-test";

@Component({
  selector: "app-settings-form",
  templateUrl: "../pages/settings-form.component.html",
  styleUrls: [],
})
export class SettingsFormComponent implements OnInit {
  hasVariable!: boolean;
  @Input() isEdit!: boolean;
  @Input() executables!: Array<ExecuteData>;
  @Input() updateTest!: Settings;
  form!: FormGroup;
  showTime: boolean = true;
  frequencies = Frequencies;
  configPopup: boolean = false;
  frequencyItems!: SelectItem[];
  @Output() formValues: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPopup: EventEmitter<boolean> = new EventEmitter<boolean>();
  minDate!: Date;
  variableInfo!: any;
  textCopied!: boolean;
  @Input() set refresh(value: boolean) {
    if (value) {
      this.initForm();
    }
  }
  // @Input() configPopup

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.updateTest) {
      this.initFrequencies();
      this.initForm();
      setTimeout(() => {
        if (changes.updateTest) {
          if (changes.updateTest.currentValue) {
            const formData = changes.updateTest.currentValue;
            const patchFormValues = {
              testName: formData.testName,
              retryCount: formData.retryCount,
              consoleLogs: formData.consoleLogs === "true" ? true : false,
              jobId: formData.jobId,
            };
            this.form.patchValue(patchFormValues);
            if (formData) {
              this.hasVariable = true;
              this.variableInfo = formData.variableInfo;
            }
          }
        }

        // this.updateFormValues();
      }, 500);
    }
  }

  ngOnInit(): void {
    this.initFrequencies();
    this.initForm();
  }

  private initFrequencies() {
    this.frequencyItems = [];
    Object.keys(this.frequencies).forEach((element) => {
      this.frequencyItems.push({ label: element, value: element });
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      testName: ["", [Validators.required, Validators.minLength(2)]],
      retryCount: [2, [Validators.minLength(2)]],
      consoleLogs: [true],
      jobId: [""],
    });
  }

  get getControl() {
    return this.form.controls;
  }

  onSubmit() {
    this.formValues.emit(this.form.value);
  }

  showConfig() {
    console.log("Will Show the popup");

    this.configPopup = true;
    this.openPopup.emit(this.configPopup);
  }

  updateFormValues() {
    if (this.isEdit) {
      this.formValues.emit(this.form.value);
    }
  }

  resetForm() {
    this.initForm();
  }

  /* To copy any Text */
  copyText(val: string) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.textCopied = true;
    // this.messageService.add({detail: "SessionId copied to clipboard", life: 5000, closable: true, severity: 'info'})
  }

  mouseEnter(div: string) {
    console.log("mouse enter : " + div);
    this.textCopied = false;
  }

  mouseLeave(div: string) {
    console.log("mouse leave :" + div);
    this.textCopied = false;
  }

  getExecutableURL(): any {
    if (this.variableInfo) {
      const executeableURL =
        `${environment.api.baseUrl}` +
        "/external/cicd/execute/job/" +
        this.variableInfo.applicationVariableId;
      return executeableURL;
    }
  }
}
