export enum RoleEnum {
	SuperAdmin = 'RC Super Admin',
	TestManager = 'Test Manager',
	TestEngineer = 'Test Engineer',
}

export enum TagTypeEnum {
	Script = 'script',
	Scenario = 'scenario',
}


export enum Frequencies {
	"Don't Repeat" = 'DONOTREPEAT',
	HOURLY = 'HOURLY',
	DAILY = 'DAILY',
	MONTHLY = 'MONTHLY',
}
export enum Platforms {
	WebApp = 'Web App',
	MobileNativeApp = 'Mobile Native App',
	WebServices = 'Web Services',
}

export enum Browsers {
	Chrome = 'Chrome',
	Firefox = 'Firefox',
	Opera = 'Opera',
	InternetExplorer = 'Internet Explorer',
}

export enum TestRunEnvironment {
	Server_Side = 'Lambda Test',
}

export enum ExecutionTypes {
	ALL = 'All',
	EXECUTENOW = 'ExecuteNow',
	SCHEDULED = 'Scheduled',
	CICD = 'CI/CD',
	PLUGIN = 'Plugin',
	DRYRUN = 'DryRun',
}

export enum ExecutionFilter {
	'7-Days' = '7-Days',
	'4-Weeks' = '4-Weeks',
	'3-Months' = '3-Months',
}

export enum Resolutions {
	R1 = '1024x768',
	R2 = '1280x720',
	R3 = '1280x960',
	R4 = '1280x1024',
	R5 = '1440x900',
	R6 = '1600x1200',
	R7 = '1920x1080',
	R8 = '2048x1536',
	R9 = '2560x1440',
}

export enum DataDrivenValues {
	value = 'value',
	element = 'element',
}

export enum RunStatus {
	QUEUED = 'Queued',
	STARTED = 'Started',
	COMPLETED = 'Completed',
	FAILED = 'Failed',
	INPROGRESS = 'Inprogress',
}

export enum APIMethodTypes {
	GET = 1,
	POST = 2,
	PUT = 3,
	PATCH = 4,
	DELETE = 5,
}

export enum Concurrency {
	PARALLEL = 'Parallel',
	SEQUENTIAL = 'Sequential',
}

export enum CUSTOM_COMMANDS {
	IF = 100012,
	ENDIF = 100014,
	ELSE = 100013,
	ENDELSE = 100015,
	FORLOOP = 100022,
	APISTEP = 100099,
}

export enum VariableDataTypes {
	String = 'String',
	Number = 'Number',
	Object = 'Object',
	List = 'List',
	Date = 'Date',
	Time = 'Time',
	DateTime = 'Date & Time',
}

export enum Assertions {
	Status = 'Status',
	Body = 'Body (JSON)',
}

export enum Statuses {
	equals = 'equals',
	doesNotEqual = 'does not equal',
	contains = 'contains',
	doestNotContain = 'does not contain',
}

export enum SOURCE {
	EXCEL = 'Excel',
	API = 'API',
	VARIABLE = 'Variable'
}