import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponse } from 'src/app/core/generic-operations/operations/responce.interface';
import { CrudService } from 'src/app/core/generic-operations/services/crud-service';
import { environment } from 'src/environments/environment';
import { TestConfiguration } from '../test-configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends CrudService<TestConfiguration, number> {
  constructor(protected http: HttpClient) {
    super(
      http,
      `${environment.api.baseUrl}/`,
      'test-configuration',
      'test-configurations'
    )
  }

  findConfigByApplicationId(id: number) {
    return this._http.get<IResponse>(`${environment.api.baseUrl}/` + 'testConfigurations/' + id);
  }

  findConfigByApplicationIdAndTestPlanId(data: {applicationId: number, tagId: number}) {
    return this._http.post<IResponse>(`${environment.api.baseUrl}/` + 'testConfigurations/planconfig', data);
  }

  mobilePlatform() {
    return this._http.get<IResponse>('https://mobile-api.lambdatest.com/mobile-automation/api/v1/list');
  }
}
