<div class="p-col-12 p-mb-6" id="script">
	<!-- <h3>Variables</h3>	 -->
	<div *ngIf="isScreenLoading">
		<p-progressSpinner></p-progressSpinner>
		<div class="progressspinner-overlay"></div>
	</div>
	<p-card>
		<app-add-variable [data]="selectedVariable" [scriptId]="scriptId" [heading]="heading"
			(closeModal)="close($event)" (outputScenario)="updateVariable($event)"></app-add-variable>
	<p-table #dt2 [responsive]="true"
	[value]="allVariables"
	[rowHover]="true" styleClass="p-datatable-striped p-datatable-responsive-demo gtp-table"
	[paginator]="true"
	[rows]="paginationSize"
	[showCurrentPageReport]="true" 
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	[rowsPerPageOptions]="[10,25,50]"
	[loading]="isGridLoading"
	[globalFilterFields]="['variableName','variableValue', 'createdDate']">
		<ng-template pTemplate="caption">
			<div class="p-d-flex">
				<h5 style="margin: 0.5rem 0 0 0;">Variables:</h5>
				<span class="p-input-icon-left p-ml-auto">
				</span>
				<!-- <button pButton type="button" (click)="showScriptDialog()" label="Add New Variable" icon="pi pi-plus-circle" Class="p-button p-ml-6"></button> -->
			</div>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Name
                    </div>
                </th>
				<th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Value 
                    </div>
                </th>
				<th >Added on </th>
				
				<th style="width: 12rem;" class="p-text-center">Actions </th>
			</tr>
			
		</ng-template>
		<ng-template pTemplate="body" let-variable>
			<tr>
				<td>{{variable.variableName}} 
					<!-- <p-tag severity="info" [rounded]="false"
					value="{{variable.variableScope}}"></p-tag> -->
				</td>
				<td>{{variable.variableValue}}</td>
				<td>{{variable.createdDate | date:"MM-dd-y h:mm"}}</td>
				
				<td class="col-no-ellipsis">
					<hero-icon
					*ngIf="!scriptId"
                        name="pencil"
                        pTooltip="Edit"
                        tooltipPosition="top"
                        hostDisplay="inlineBlock"
                        type="outline"
                        class="p-2 w-8 h-8 ml-2 cursor-pointer rounded-md text-gray-400 bg-gray-100 hover:text-blue-400"
                        (click)="editScript(variable)"
                    ></hero-icon>
					<hero-icon
					*ngIf="!scriptId"
                        name="trash"
                        pTooltip="Delete"
                        tooltipPosition="top"
                        hostDisplay="inlineBlock"
                        type="outline"
                        class="p-2 w-8 h-8 ml-2 cursor-pointer rounded-md text-gray-400 bg-gray-100 hover:text-red-400"
                        (click)="deleteScript(variable)"
                    ></hero-icon>
				</td>				
			</tr>
		</ng-template>
	</p-table>
	</p-card>
	<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
</div>