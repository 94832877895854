import { Component, OnInit } from '@angular/core'
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms'
import { AdminDetail } from '../classes/admin-detail'
import { AdminService } from '../services/admin.service'
import { Router } from '@angular/router'
import { AuthService } from '../core/_helpers/_services/auth.service'
import { UserStorageService } from '../core/_helpers/_services/user-storage.service'
import { JwtHelperService } from '@auth0/angular-jwt'
import { HttpParams, HttpResponse } from '@angular/common/http'
import { AuthenticationService } from '../core/_helpers/_services/authentication.service'
import { MessageService } from 'primeng/api'
import { USER_KEY } from '../constants'
import { MasterDataService } from '../core/common/services/master-data.service'
import { ChangePassword } from '../changepassword/changepassword'

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styles: [],
})
export class ForgotPasswordComponent implements OnInit {
  public email: string = ''
  errorMessage = ''
  roles: string[] = []
  isScreenLoading: boolean = false
  public params = new ChangePassword()
  constructor(
    private masterDataService: MasterDataService,
    private tokenStorage: UserStorageService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  reloadPage(): void {
    window.location.reload()
  }

  submitHandler() {
    this.isScreenLoading = true
    this.params.email = this.email
    this.masterDataService.forgotPassword(this.params).subscribe((res: any) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: res.message,
        life: 3000,
      })
      this.isScreenLoading = false
      // this.router.navigate(['/resetpassword'], {
      //   state: { data: { email: this.email } },
      // })
    },(err: any) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: err.error.apierror.debugMessage,
        life: 3000,
      })
      this.isScreenLoading = false
    })
  }
}
