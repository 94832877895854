import { Base } from "src/app/core/models/base.model";
import { TestConfiguration } from "../config/test-configuration";
import { Tags } from "../tests/scripts/tags";
import { ExecuteData } from "./execute-data";

export class ScheduleTest extends Base {
	testSchedularId!: number;
	cron!: string;
	date!: Date;
	description!: string;
	name!: string;
	testConfig!: TestConfiguration;
	applicationId!: number;
	frequency!: string;
	executeData!: Array<ExecuteData>;
	tagId!: number;
	tag!: Tags;
	orgId!: number;
	nextRun!: string;
	runDate!: Date;
	formattedDate!: string;
	scheduledScripts!: ScheduleScript;
	isActive!: string;
	testConfigId!:number;
	nextRunDate!:Date;
	dateLong!:number;
	resultCounts!:number;
}
export class ScheduleScript {
	scriptIds!: Array<number>;
	scriptResultIds!: number;
}

export class Settings extends Base {
	applicationId!: number;
	createdDate!: Date;
	jobId!: number;
	lastName!: string;
	modifiedBy!: number;
	modifiedDate!: Date;
	name!: string;
	orgId!: number;
	scriptIds!: Array<number>;
	scriptResultIds!:number;
	testConfig!: TestConfiguration;
	timezone!: string;
	origin!: string;
	retryCount!: number;
	consoleLogs!: string;
	url!: string;
	testName!: string;
	executeUrl!: string;
	executeData!: Array<ExecuteData>;
	userEmail!: string;
	tagNames!: string;
	rowsPerPage!: number;
	startIndex!: number;
	tagId!: number;
}