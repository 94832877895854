import { Base } from "src/app/core/models/base.model";

export class GTPVariables extends Base {
  applicationId!: number;
  applicationVariableId!: number;
  modifiedBy!: number;
  userEmail!: string;
  userLastName!: string;
  userName!: string;
  variableName!: string;
  variableScope!: string;
  variableValue!: string;
  variableDescription!: string;
  scriptId?: number;
  position!: number;
  variableCurrentValue!: string;
  startIndex!: number;
  rowsPerPage!: number;
  dataType!: string;
  objectMember?: string;
  variablePath!: string;
}
