
    <div class="p-col-12">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate >
            <div class="p-fluid">
                <div class="p-field p-grid">
                    <label for="testName" class="p-col-12 p-mb-3 p-md-3 p-mb-md-0">Job Name</label>
                    <div class="p-col-12 p-md-9">
                        <input id="testName" type="text" pInputText formControlName="testName"> 
                        <div *ngIf="getControl.testName.touched && getControl.testName.invalid" class="errorMsg">
                            <div *ngIf="getControl.testName.errors!.required">Name is required.</div>
                        </div>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="retryCount" class="p-col-12 p-mb-3 p-md-3 p-mb-md-0">Retry Count</label>
                    <div class="p-col-12 p-md-9">
                        <input id="retryCount" type="number" min="0" pInputText formControlName="retryCount"> 
                        <div *ngIf="getControl.retryCount.touched && getControl.retryCount.value > 3" class="errorMsg">
                            <div>Count should be between 0 - 3.</div>
                        </div>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="frequency" class="p-col-12 p-mb-3 p-md-3 p-mb-md-0">Console Logs</label>
                    <div class="p-col-12 p-md-9">
                        <p-inputSwitch formControlName="consoleLogs" (onChange)="updateFormValues()"></p-inputSwitch>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!isEdit; else elseTemplate">
                <div class="p-col-12">
                    <button pButton pRipple type="submit" label="Submit" [disabled]="!form.valid || form.value.retryCount > 3" class="p-ml-1 p-f-right p-ripple p-button p-component" ></button>
                    <button pButton pRipple type="button" label="Cancel" (click)="resetForm()" class="p-mr-1 p-f-right p-button-outlined p-button p-component"></button>        
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <button pButton pRipple type="submit" label="Update" [disabled]="!form.valid || form.value.retryCount > 3" class="p-ml-1 p-f-right p-ripple p-button p-component" ></button>
                <input id="jobId" type="hidden" pInputText formControlName="jobId"> 
            </ng-template>
            
            
        </form>
    </div>
    <div class="p-col-12" *ngIf="hasVariable">
        <textarea readonly (mouseenter) ="mouseEnter('div a') "  (mouseleave) ="mouseLeave('div A')" [rows]="4" [cols]="57"  pInputTextarea>{{getExecutableURL()}}</textarea>
        <button pButton pRipple type="button" *ngIf="!textCopied" icon="pi pi-copy" (click)="copyText(getExecutableURL())" class="p-button-rounded p-button-text"></button>
        <button pButton pRipple type="button" *ngIf="textCopied" icon="pi pi-check" class="p-button-rounded p-button-text"></button>
    </div>

        